import { useNavigate } from 'react-router-dom';

import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { HeaderMenuList } from 'common';
import { BuyMembershipButton } from 'common/header/BuyMembership';
import HeaderProfile from 'common/header/HeaderProfile';
import SupportInquiry from 'common/support-inquiry';
import { environmentModeMapper } from 'config';
import uiRoutes from 'constants/uiRoutes';
import { Environment } from 'enums/common';
import { useCheckTimeLogModuleIsAccessible } from 'hooks/useCheckTimeLogModuleIsAccessible';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useGetSettingMenus } from 'hooks/useGetSettingMenus';
import { selectActiveMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { ReleaseNoteButton } from './ReleaseNoteButton';
import { ReleaseNotePopUp } from './ReleaseNotePopUp';

const Header = () => {
  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isUserEmail = useCheckUserIsEmail();
  const isUserClient = useCheckUserIsClient();
  const navigate = useNavigate();
  const activeMenu = useAppSelector(selectActiveMenu);

  const settingMenus = useGetSettingMenus();

  return (
    <Box
      className={
        smBreakPoint && isUserEmail
          ? 'dashboard-header-mobile-responsive'
          : 'dashboard-header'
      }
      component={AppBar}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <HeaderMenuList />

        <Box alignItems="center" display="flex" gap={4}>
          <BuyMembershipButton />

          {activeMenu?.metadata?.getHelpLinkUrl && (
            <Button
              onClick={() =>
                window.open(`${activeMenu.metadata?.getHelpLinkUrl}`, '_blank')
              }
              size="small"
              variant="outlined"
            >
              Help Guide
            </Button>
          )}
          <ReleaseNotePopUp />
          <ReleaseNoteButton />
          {process.env.REACT_APP_ENVIRONMENT !== Environment.PROD && (
            <Box bgcolor="primary.main" color="common.white" px={2}>
              <Typography variant="caption">
                {
                  environmentModeMapper[
                    process.env.REACT_APP_ENVIRONMENT as string
                  ]
                }
              </Typography>
            </Box>
          )}

          {!!settingMenus?.length && (
            <IconButton
              aria-label="sun"
              onClick={() => navigate(uiRoutes.settings)}
              size="large"
              sx={{ color: 'gray.dark' }}
            >
              <FontAwesomeIcon icon={faGear} />
            </IconButton>
          )}

          {!isUserClient && <SupportInquiry />}

          <HeaderProfile />
        </Box>
      </Toolbar>
    </Box>
  );
};

export default Header;
