import { useNavigate } from 'react-router-dom';

import {
  faCalendar,
  faCheck,
  faCheckCircle,
  faEdit,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { EllipseMenu, EllipseMenuItem } from 'common';
import PermissionGate from 'common/PermissionGate';
import { TaskStatus } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import { IAdaptedTask } from 'interfaces/cases';
import {
  useDeleteTaskMutation,
  usePatchTaskStatusMutation,
} from 'services/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';
import { checkIfDateisInPast, momentIsToday } from 'utils/moment';

interface IProps {
  data: IAdaptedTask;
  isActive: boolean;
}

const TaskList = ({ data, isActive }: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const deleteConfirmationModal = useConfirmationModal();
  const deleteTaskMutation = useDeleteTaskMutation();
  const patchTaskStatusMutation = usePatchTaskStatusMutation();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const caseId = currentReferralCase?.caseId;

  const handleOnEditClick = (isViewOrEdit: boolean) => {
    navigate(
      `modal?type=${ModalKey.EDIT_CASE_TASK}&caseId=${currentReferralCase?.caseId}&taskId=${data.taskId}&taskView=${isViewOrEdit}`
    );
  };

  const handleDeleteTask = async () => {
    if (data.taskId) {
      const result = await deleteConfirmationModal?.openConfirmationModal({
        title: `Delete Task`,
        content: `Do you want to delete this task?`,
      });
      if (result) {
        if (!caseId && data.taskId) return;
        deleteConfirmationModal?.changeSubmittingStatus(true);
        deleteTaskMutation.mutate(
          { caseId: caseId!, taskId: data.taskId },
          {
            onSettled: () => {
              deleteConfirmationModal?.changeSubmittingStatus(false);
            },
          }
        );
      }
    }
  };

  const onMarkAsComplete = async () => {
    if (data.taskId) {
      const result = await deleteConfirmationModal?.openConfirmationModal({
        title: `Mark As Complete`,
        content: `Do you want to mark task as completed?`,
      });
      if (result) {
        if (!caseId && data.taskId) return;
        deleteConfirmationModal?.changeSubmittingStatus(true);
        patchTaskStatusMutation.mutate(
          {
            data: { status: TaskStatus.COMPLETED },
            taskId: data.taskId,
            caseId: caseId!,
          },
          {
            onSettled: () => {
              deleteConfirmationModal?.changeSubmittingStatus(false);
            },
          }
        );
      }
    }
  };
  return (
    <Box
      padding={2}
      sx={{
        borderBottom: '1px solid',
        borderColor: theme.palette.gray.lighter,
        backgroundColor: isActive ? theme.palette.gray.lighter : 'inherit',
      }}
    >
      <Box paddingX={2}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          mb={2}
          spacing={4}
        >
          <PermissionGate>
            <Grid
              item
              onClick={() => handleOnEditClick(true)}
              sx={{ cursor: 'pointer' }}
              xs={8}
            >
              <Tooltip
                placement="top"
                title={
                  <Typography
                    component="p"
                    sx={{
                      color: theme.palette.common.white,
                    }}
                    variant="body2"
                  >
                    {data?.title}
                  </Typography>
                }
              >
                <Typography
                  fontWeight="medium"
                  gutterBottom={false}
                  sx={{
                    '&:hover': {
                      color: (t) => t.palette.secondary.main,
                      '& .glow-span': {
                        color: (t) => t.palette.secondary.main,
                      },
                    },
                  }}
                  variant="body2"
                >
                  {data?.formattedTitle}
                </Typography>
              </Tooltip>
            </Grid>
          </PermissionGate>
          <Grid item xs={4}>
            <Grid alignItems="center" container justifyContent="end">
              <Grid item>
                {data.status === TaskStatus.COMPLETED && (
                  <FontAwesomeIcon
                    color={theme.palette.success.main}
                    data-cy="case-task-status-complete"
                    fontSize="1rem"
                    icon={faCheckCircle}
                    style={{ marginRight: '0.4rem' }}
                  />
                )}
              </Grid>
              <Grid item>
                {data.status !== TaskStatus.COMPLETED && (
                  <EllipseMenu>
                    <EllipseMenuItem
                      dataCy="case-task-edit"
                      iconElement={<FontAwesomeIcon icon={faEdit} size="xs" />}
                      onClick={() => handleOnEditClick(false)}
                      text="Edit"
                    />
                    <EllipseMenuItem
                      dataCy="case-task-markascomplete"
                      iconElement={<FontAwesomeIcon icon={faCheck} size="xs" />}
                      onClick={onMarkAsComplete}
                      text="Mark as Complete"
                    />
                    <EllipseMenuItem
                      dataCy="case-task-delete"
                      iconElement={<FontAwesomeIcon icon={faTrash} size="xs" />}
                      onClick={handleDeleteTask}
                      text="Delete"
                    />
                  </EllipseMenu>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <Grid alignItems="center" container sx={{ marginTop: '8px' }}>
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ color: theme.palette.gray.main }}
            />
            <Typography
              gutterBottom={false}
              sx={{
                marginLeft: 4,
                color: `${
                  checkIfDateisInPast(data?.formattedDueDate) &&
                  !momentIsToday(data?.formattedDueDate)
                    ? theme.palette.error.main
                    : theme.palette.text.secondary
                }`,
              }}
              variant="body2"
            >
              {data?.formattedDueDate}
            </Typography>
          </Grid>
          <Grid alignItems="center" container>
            {data?.formattedAssignedNames && (
              <FontAwesomeIcon
                icon={faUsers}
                style={{
                  color: theme.palette.text.secondary,
                  marginTop: '8px',
                }}
              />
            )}
            <Typography
              gutterBottom={false}
              sx={{ marginTop: 2, marginLeft: 4 }}
              variant="body2"
            >
              {data?.formattedAssignedNames}
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TaskList;
