import { useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';

import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Typography } from '@mui/material';
import { LoadingIndicator, NoRecordsFoundDisplay } from 'common';
import commonConstants from 'constants/common';
import uiRoutes from 'constants/uiRoutes';
import { AccessType, MenuResourceCodes } from 'enums/auth';
import ModalKey from 'enums/ModalKey';
import { PaymentSourceStatus } from 'enums/Payment';
import AddContactCard from 'features/settings/profile/AddContactCard';
import { useGetAccessType } from 'hooks/useGetAccessType';
import { IAdaptedPaymentSource } from 'interfaces/payment';
import { usePaymentSourceQuery } from 'services/payment';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import {
  getGroupIdOrPrimaryId,
  getGroupNameOrPrimaryName,
  getLocationSpecificTenantId,
} from 'utils/client-management';
import { checkIsAccessTypeWrite } from 'utils/common';

import { BilledToInfo } from './BilledToInfo';
import PaymentInformationCard from './PaymentInformationCard';

interface IProps {
  data: IAdaptedPaymentSource[];
  isLoading: boolean;
  noRecordsFound: boolean;
  isSetAsPrimaryDisabled: boolean;
  title?: string;
  maxHeight?: string;
  showAddPayment?: boolean;
  shouldCheckAccess?: boolean;
}

const PaymentInformation = ({
  data,
  isLoading,
  noRecordsFound,
  isSetAsPrimaryDisabled,
  title,
  maxHeight,
  showAddPayment,
  shouldCheckAccess,
}: IProps) => {
  const editClientData = useAppSelector(selectEditClientData);
  const locationSpecificTenantId = getLocationSpecificTenantId();
  const navigate = useNavigate();
  const addPaymentMethod = () => {
    const route = `${uiRoutes.profileSettings.billings}/modal?type=${ModalKey.ADD_PAYMENT_METHOD}`;
    navigate(route);
  };

  const { childResourceAccessType } = useGetAccessType({
    parentCode: MenuResourceCodes.SETTING,
    childCode: MenuResourceCodes.SETTING_VITAFY_BILLING,
  });
  const isAccessTypeWrite = checkIsAccessTypeWrite(
    childResourceAccessType as AccessType
  );

  // Fetch payment sources of client's parent if exists
  // Needed to show that the sponsor billing (parent client's billing) exists if that's the case
  const parentPaymentSourceQuery = usePaymentSourceQuery(
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      clientId: getGroupIdOrPrimaryId(editClientData),
      tenantId: locationSpecificTenantId,
    },
    {
      enabled: !!(
        (editClientData?.relationship?.groupId ||
          editClientData?.relationship?.primaryId) &&
        locationSpecificTenantId
      ),
    }
  );

  const parentPaymentSourceExists = useMemo(() => {
    if (parentPaymentSourceQuery.data) {
      const primarySourceExists = parentPaymentSourceQuery.data?.rows?.some(
        (item) => item.status === PaymentSourceStatus.PRIMARY
      );

      return primarySourceExists;
    }

    return false;
  }, [parentPaymentSourceQuery]);

  const parentClientName = getGroupNameOrPrimaryName(editClientData);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={4}
      >
        <Typography
          color="gray.darker"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          {title}
        </Typography>
        {parentPaymentSourceExists && parentClientName && (
          <BilledToInfo clientName={parentClientName} />
        )}
      </Box>
      <PerfectScrollbar>
        <Grid container spacing={3} sx={{ maxHeight }}>
          {data.map((item) => (
            <Grid item key={item.id} xs={6}>
              <PaymentInformationCard
                data={item}
                isEnabled={shouldCheckAccess ? isAccessTypeWrite : true}
                isSetAsPrimaryDisabled={isSetAsPrimaryDisabled}
              />
            </Grid>
          ))}
          {showAddPayment && (
            <Grid item xs={6}>
              <AddContactCard
                buttonAction={addPaymentMethod}
                buttonSize="small"
                buttonText="Add Payment Method"
                buttonVariant="outlined"
                cardInfo={
                  data?.length ? 'Secondary Payment' : 'Primary Payment'
                }
                icon={faCreditCard}
                iconSize="2x"
                showButton={shouldCheckAccess ? isAccessTypeWrite : true}
                sx={{ height: 145 }}
              />
            </Grid>
          )}

          {isLoading && <LoadingIndicator containerHeight="20vh" />}

          {noRecordsFound && (
            <Box mt={4} width="100%">
              <NoRecordsFoundDisplay text="No billing information found. " />
            </Box>
          )}
        </Grid>
      </PerfectScrollbar>
    </>
  );
};

PaymentInformation.defaultProps = {
  title: ' Billing Information',
  maxHeight: '25vh',
  showAddPayment: false,
  shouldCheckAccess: false,
};

export default PaymentInformation;
