import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, SxProps, Typography } from '@mui/material';

interface IProp {
  icon: IconProp;
  buttonText: string;
  cardInfo: string;
  buttonVariant: 'outlined' | 'contained';
  buttonAction: VoidFunction;
  sx?: SxProps;
  iconSize?: SizeProp;
  buttonSize?: 'medium' | 'small' | 'large';
  showButton?: boolean;
}
const AddContactCard = ({
  icon,
  buttonText,
  cardInfo,
  buttonVariant,
  buttonAction,
  sx,
  iconSize,
  buttonSize,
  showButton,
}: IProp) => (
  <Box
    alignItems="center"
    bgcolor="gray.lighter"
    borderRadius={(theme) => theme.customBorderRadius.sm}
    display="flex"
    justifyContent="center"
    sx={sx}
  >
    <Stack gap={2}>
      <Box
        color="primary.main"
        component={FontAwesomeIcon}
        icon={icon}
        size={iconSize}
      />
      <Typography textAlign="center" variant="body2">
        {cardInfo}
      </Typography>
      {showButton && (
        <Button
          onClick={buttonAction}
          size={buttonSize}
          variant={buttonVariant}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  </Box>
);
AddContactCard.defaultProps = {
  sx: undefined,
  iconSize: '2x',
  buttonSize: 'medium',
  showButton: true,
};

export default AddContactCard;
