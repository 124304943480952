import React from 'react';

import { Box, Typography } from '@mui/material';

const AgreementSentSuccess = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    height="100vh"
    justifyContent="center"
    mt={10}
    width="100%"
  >
    <Typography color="text.secondary" textAlign="center" variant="h4">
      The signed copy of your agreement has been sent to you.
    </Typography>
  </Box>
);

export default AgreementSentSuccess;
