import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import uiRoutes from 'constants/uiRoutes';
import { UserSocketEvent } from 'enums/socket';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { socket } from 'stores/socket-io';
import { setAuthToken } from 'utils/axios';
import { clearLocal, clearSession, setLocal } from 'utils/storage';

import Modal from './ui/modal/Modal';

interface Props {
  children: JSX.Element;
}

export const RootSocketHandler = ({ children }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAccessRevokedModalOpen, setIsAccessRevokedModalOpen] =
    useState(false);
  const authTenantData = useAppSelector(selectAuthTenantData);

  const logout = useCallback(() => {
    const token = '';
    queryClient.clear();
    clearLocal();
    clearSession();
    setAuthToken('');
    setLocal('jwtToken', token);
  }, [queryClient]);

  useEffect(() => {
    socket.on(UserSocketEvent.ADMIN_USER_DEACTIVATED, async (data) => {
      if (
        authTenantData?.userId === data.userId &&
        authTenantData?.tenantAssociation?.tenantId === data.tenantId
      ) {
        logout();
        setIsAccessRevokedModalOpen(true);
      }
    });

    return () => {
      socket.off(UserSocketEvent.ADMIN_USER_DEACTIVATED);
    };
  }, [
    authTenantData?.tenantAssociation?.tenantId,
    authTenantData?.userId,
    logout,
  ]);

  if (isAccessRevokedModalOpen) {
    return (
      <Modal icon="faInfoCircle" maxWidth="md" open title="Access Revoked">
        <DialogContent dividers>
          <Typography>
            Your access to view this information has been revoked. Please reload
            the page.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button
            onClick={() => {
              setIsAccessRevokedModalOpen(false);
              navigate(uiRoutes.auth.login);
              window.location.reload();
            }}
            size="medium"
            type="button"
            variant="outlined"
          >
            Reload
          </Button>
        </DialogActions>
      </Modal>
    );
  }

  return children;
};
