import { Typography } from '@mui/material';
import { GatewayType } from 'enums/Payment';
import { useAppSelector } from 'stores/hooks';
import { selectGatewayDetail } from 'stores/Payment';

import AuthorizeDotNetCardPayment from './authorize-dot-net/CardPayment';
import CardConnectCardPayment from './card-connect/CardPayment';
import { CardPayment as StripeCardPayment } from './stripe/CardPayment';

const CardPayment = () => {
  const gatewayDetail = useAppSelector(selectGatewayDetail);

  if (gatewayDetail?.gatewayType === GatewayType.AUTHORIZE_DOT_NET) {
    return <AuthorizeDotNetCardPayment />;
  }

  if (gatewayDetail?.gatewayType === GatewayType.CARD_CONNECT) {
    return <CardConnectCardPayment />;
  }

  if (gatewayDetail?.gatewayType === GatewayType.STRIPE) {
    return <StripeCardPayment />;
  }

  return (
    <Typography mt={8} variant="body1">
      Payment Gateway not found.
    </Typography>
  );
};

export default CardPayment;
