import { Box, Typography, useTheme } from '@mui/material';
import { Logo } from 'common';
import { useClientDetailQuery } from 'services/client-management';
import { useTenantDetailQuery } from 'services/tenant-management/tenant';
import { selectAuthTenantData } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { formatPhone } from 'utils/common';

import BloodGroupDOBDateDisplay from './BloodGroupDOBDateDisplay';

interface IProps {
  clientId: string;
}

const MembershipIdCardDetails = ({ clientId }: IProps) => {
  const authTenant = useAppSelector(selectAuthTenantData);
  const editClientData = useAppSelector(selectEditClientData);

  const theme = useTheme();

  const clientDetailQuery = useClientDetailQuery(
    clientId!,
    {
      enabled: !!clientId,
    },
    {
      clientEnrollmentId: editClientData?.clientEnrollmentId,
    }
  );

  const clientData = clientDetailQuery?.data;
  const tenantId = clientData?.tenantId || '';
  const themeColor = theme.palette.primary.main;

  const { data } = useTenantDetailQuery(tenantId, {
    enabled: !!tenantId,
  });

  return (
    <Box
      bgcolor="common.white"
      border={1}
      borderColor="gray.light"
      borderRadius={2}
      height="280px"
      maxWidth="500px"
      width="500px"
    >
      <Box display="flex" justifyContent="space-between" p={4}>
        <Logo
          src={authTenant?.tenantAssociation.metaData.imageUrl}
          sx={{
            height: 70,
            alignSelf: 'flex-start',
            pb: 2,
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
          textAlign="right"
        >
          <Typography
            color="text.primary"
            fontWeight="medium"
            gutterBottom={false}
            pt={2}
            variant="body2"
          >
            Membership
          </Typography>
          <Typography
            color={themeColor || theme.palette.primary.main}
            fontWeight="medium"
            gutterBottom={false}
            pt={2}
            variant="body1"
          >
            {clientData?.membership?.offeringName} Plan
          </Typography>
        </Box>
      </Box>
      <Box
        gap={4}
        padding={4}
        sx={{ backgroundColor: themeColor || theme.palette.primary.main }}
      >
        <Typography
          color={theme.palette.background.paper}
          gutterBottom={false}
          variant="body1"
        >
          {clientData?.name}
        </Typography>
        {/* <Typography
          color={theme.palette.background.paper}
          gutterBottom={false}
          variant="body2"
        >
          {clientData?.clientId?.split('-')[4]}
        </Typography> */}
      </Box>
      <Box display="flex" justifyContent="space-between" p={4}>
        <BloodGroupDOBDateDisplay data={clientData} />
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          justifyContent="space-between"
        >
          <Box alignItems="flex-start" display="flex">
            <Typography
              color="text.primary"
              gutterBottom={false}
              pl={2}
              variant="caption"
            >
              Call or email for support:
            </Typography>
          </Box>
          <Box alignItems="center" display="flex">
            <Typography
              color="text.primary"
              fontWeight="medium"
              gutterBottom={false}
              pl={2}
              variant="caption"
            >
              {data?.phone && formatPhone(data?.phone || '')}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex">
            <Typography
              color="text.primary"
              fontWeight="medium"
              gutterBottom={false}
              pl={2}
              variant="caption"
            >
              {data?.email}
            </Typography>
          </Box>
          <Box alignItems="flex-start" display="flex">
            <Typography
              color="text.primary"
              gutterBottom={false}
              pl={2}
              variant="caption"
            >
              Powered by Vitafy 360
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MembershipIdCardDetails;
