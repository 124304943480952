import { Chip } from 'common/ui/chip';
import { TaskStatus } from 'enums/case';

export const TaskStatusDisplay = ({ status }: { status: TaskStatus }) => {
  const modifiedStatus =
    status === TaskStatus.REMINDED ? TaskStatus.SUBMITTED : status;
  const statusMap = {
    [TaskStatus.COMPLETED]: 'success',
    [TaskStatus.SUBMITTED]: 'info',
    [TaskStatus.REMINDED]: 'warning',
  };

  return (
    <Chip
      className={`chip-badge chip-badge--${statusMap[modifiedStatus]}`}
      label={modifiedStatus}
    />
  );
};
