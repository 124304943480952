import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { Logo } from 'common';
import QRCode from 'common/QrCode';
import { useGetWebsiteLink } from 'hooks/useGetWebsiteLink';
import { useClientDetailQuery } from 'services/client-management';
import { useGenerateTinyUrlMutation } from 'services/tiny-url';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  clientId: string;
}

export const QRCodeDownloadView = ({ clientId }: IProps) => {
  const theme = useTheme();

  const { mutate: generateTinyUrlMutate, isLoading } =
    useGenerateTinyUrlMutation();
  const [decodedReferralLink, setDecodedReferralLink] = useState('');

  const tenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const { websiteLink } = useGetWebsiteLink();
  const primaryEmail = tenantAssociation?.email;
  const referralLink = useMemo(
    () => `${websiteLink}?referredby=${clientId}`,
    [clientId, websiteLink]
  );
  const themeColor = theme.palette.primary.main;

  const editClientData = useAppSelector(selectEditClientData);

  const clientDetailQuery = useClientDetailQuery(
    clientId!,
    {
      enabled: !!clientId,
    },
    {
      clientEnrollmentId: editClientData?.clientEnrollmentId,
    }
  );

  const clientData = clientDetailQuery?.data;

  useEffect(() => {
    if (referralLink && !decodedReferralLink && websiteLink && !isLoading) {
      generateTinyUrlMutate(
        { originalUrl: referralLink },
        {
          onSuccess(res) {
            setDecodedReferralLink(res.data.tinyUrl);
          },
        }
      );
    }
  }, [
    decodedReferralLink,
    referralLink,
    websiteLink,
    generateTinyUrlMutate,
    isLoading,
  ]);

  return (
    <Box
      bgcolor="common.white"
      border={1}
      borderColor="gray.light"
      borderRadius={2}
      height="545px"
      width="375px"
    >
      <Box>
        <Box display="flex" justifyContent="center" p={4}>
          <Logo
            src={tenantAssociation?.metaData?.imageUrl}
            sx={{
              height: 90,
              alignSelf: 'flex-start',
              pb: 1,
              maxWidth: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
        <Box
          gap={4}
          padding={4}
          sx={{
            backgroundColor: themeColor || theme.palette.primary.main,
            textAlign: 'center',
          }}
        >
          <Typography
            color={theme.palette.background.paper}
            gutterBottom={false}
            variant="h6"
          >
            {clientData?.name}
          </Typography>
        </Box>
        <Box padding={4}>
          {/* Client Details with QR code details  */}
          <Box alignItems="center" display="flex" justifyContent="center">
            {isLoading && !decodedReferralLink && (
              <Box
                alignItems="center"
                display="flex"
                height="120px"
                justifyContent="center"
              >
                <CircularProgress size={40} />
              </Box>
            )}

            {decodedReferralLink && (
              <Box>
                <Box paddingY={4} textAlign="center" width="100%">
                  <Typography
                    gutterBottom={false}
                    sx={{ textWrap: 'nowrap' }}
                    variant="body1"
                  >
                    My Personal Member Referral Link
                  </Typography>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  marginTop={2}
                >
                  <QRCode content={decodedReferralLink!} />
                </Box>
              </Box>
            )}
          </Box>
          <Divider sx={{ marginY: 4 }} />
          {/* Footer Section  */}
          <Box textAlign="center">
            <Typography variant="body2">
              Print this card and keep it with you, or save the image to your
              device for easy sharing. Email us for support at
              {primaryEmail && (
                <Typography component="p" variant="body2">
                  {primaryEmail}
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
