import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
} from '@mui/material';

interface IProps {
  id: string;
  children?: React.ReactNode;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ModalTitle = (props: IProps) => {
  const { id, children, onClose } = props;

  return (
    <MuiDialogTitle
      id={id}
      sx={{
        m: 0,
        p: 4,
        // Headings (H1 - H6) have color gray.darker. Overidding with white in this case.
        color: (theme) => `${theme.palette.primary.contrastText} !important`,
      }}
    >
      {children}
      {onClose ? (
        <MuiIconButton
          aria-label="Close Modal"
          className="modal-title-close-button"
          data-cy="close-modal"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </MuiIconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

ModalTitle.defaultProps = {
  children: null,
  onClose: undefined,
};

export default ModalTitle;
