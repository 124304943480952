import { Box, DialogContent, Typography } from '@mui/material';
import { Modal } from 'common';

import ShareDomainLink from './ShareDomainLink';

interface IProps {
  modalTitle?: string;
  label: React.ReactNode | string;
  onClose: VoidFunction;
  link: string;
}

const ShareLinkModal = ({
  onClose,
  modalTitle = 'Get Link',
  link,
  label,
}: IProps) => (
  <Modal handleClose={onClose} icon="faLink" open title={modalTitle}>
    <DialogContent>
      <Box mt={4}>
        <Typography variant="body1">{label}</Typography>
        <ShareDomainLink link={link} />
      </Box>
    </DialogContent>
  </Modal>
);

ShareLinkModal.defaultProps = {
  modalTitle: 'Get Link',
};

export default ShareLinkModal;
