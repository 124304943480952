import { FormProvider, useForm } from 'react-hook-form';

import { Alert, Box, Stack } from '@mui/material';
import {
  AgreeTerms,
  BankPayment,
  CardPayment,
  CashPayment,
  CheckPayment,
  ModalFooter,
  PaymentOptionList,
} from 'common';
import OtherPayment from 'common/payment/OtherPayment';
import { PaymentMethod } from 'enums/Payment';
import { IPaymentForm } from 'interfaces/payment';
import { useAddPaymentSourceMutation } from 'services/payment';
import { selectAuthTenantData } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { selectGatewayDetail } from 'stores/Payment';
import { formatAddPaymentSourcePayload } from 'utils/payment';

import SetAsPrimaryForm from './SetAsPrimaryForm';

const defaultValues = {
  paymentMethod: '',
  cardToken: '',
  cardExpiry: '',
  bankToken: '',
  agreeTerms: false,
  // specific to Authorize.Net
  tokenSource: '',
  cardCVC: '',
  cardNumber: '',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
};

interface IProps {
  handleCancelButtonClick: VoidFunction;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const Billing = ({ handleCancelButtonClick, setActiveTab }: IProps) => {
  const methods = useForm<IPaymentForm>({ defaultValues });
  const tenantData = useAppSelector(selectAuthTenantData);
  const gatewayDetail = useAppSelector(selectGatewayDetail);
  const gatewayType = gatewayDetail?.gatewayType;

  const { reset, watch } = methods;

  const editClientData = useAppSelector(selectEditClientData);

  const addPaymentSourceMutation = useAddPaymentSourceMutation();

  const onSubmit = (submitData: IPaymentForm) => {
    if (
      !editClientData?.clientId ||
      !tenantData?.tenantAssociation?.tenantId ||
      !gatewayType
    ) {
      return;
    }

    const payload = formatAddPaymentSourcePayload({
      paymentInfo: submitData,
      clientData: editClientData,
      tenantId: tenantData.tenantAssociation.tenantId,
      gatewayType,
    });

    addPaymentSourceMutation.mutate(
      {
        clientId: editClientData.clientId,
        data: payload,
      },
      {
        onSuccess: () => {
          reset(defaultValues);
          setActiveTab((prevState) => prevState + 1);
        },
      }
    );
  };

  const paymentMethod = watch('paymentMethod');
  const agreeTerms = watch('agreeTerms');
  const cardToken = watch('cardToken');
  const bankToken = watch('bankToken');

  const checkIsSubmitDisabled = () => {
    if (!paymentMethod || !agreeTerms) {
      return true;
    }

    if (paymentMethod === PaymentMethod.CARD && !cardToken) {
      return true;
    }

    if (paymentMethod === PaymentMethod.BANK && !bankToken) {
      return true;
    }

    return false;
  };

  return (
    <Stack mt={4} spacing={4}>
      <Box mx={4}>
        <SetAsPrimaryForm />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack mb={4} mx={4} spacing={2}>
            <PaymentOptionList />
            {paymentMethod === PaymentMethod.CARD && <CardPayment />}
            {paymentMethod === PaymentMethod.BANK && <BankPayment />}
            {paymentMethod === PaymentMethod.CASH && <CashPayment />}
            {paymentMethod === PaymentMethod.CHECK && <CheckPayment />}
            {paymentMethod === PaymentMethod.OTHERS && <OtherPayment />}
            {!!paymentMethod && <AgreeTerms paymentMethod={paymentMethod} />}
            {!paymentMethod && (
              <Alert severity="info">
                Please select a payment method above.
              </Alert>
            )}
          </Stack>
          <ModalFooter
            cancelButtonType="cancel"
            isCancelRequired={false}
            isDisabled={checkIsSubmitDisabled()}
            isLoading={addPaymentSourceMutation.isLoading}
            onCancelButtonClick={handleCancelButtonClick}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};

export default Billing;
