import { Theme } from '@mui/material/styles';
import { customScrollbar } from 'theme/GlobalStyles';

const Paper = (theme: Theme) => ({
  MuiPaper: {
    styleOverrides: {
      root: {
        // -----------------------------------------------------
        // Custom scrollbar
        ...customScrollbar(theme),

        '&.bordered': {
          border: `1px solid ${theme.palette.gray.lighter}`,
        },

        '&.no-box-shadow': {
          boxShadow: 'none',
        },

        '& .membership-card': {
          background: theme.palette.gray.lighter,
          textAlign: 'center',
          opacity: 0.9,
          borderRadius: '10px',
        },
        '& .active-membership-card': {
          textAlign: 'center',
          border: `2px solid ${theme.palette.primary.light}`,
          background: theme.palette.gray.lighter,
          position: 'relative',
          svg: {
            display: 'block !important',
            color: theme.palette.primary.light,
            position: 'absolute',
            top: '10px',
            left: '10px',
          },
        },
        '&.communication-card': {
          cursor: 'pointer',
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.gray.light}`,
          padding: theme.spacing(4),
          boxShadow: 'none',
          borderRadius: '8px !important',
          height: { base: 'fit-content', xs: 178 },
          '&:hover': {
            boxShadow: theme.customShadows.medium,
            '& .communication-card-trash': {
              display: 'block',
              position: 'absolute',
              bottom: 0,
              right: 2,
              zIndex: '1',
            },
          },
          position: 'relative',
          '& .communication-card-trash': {
            display: 'none',
          },
        },
      },
    },
  },
});

export default Paper;
