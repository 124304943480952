import React from 'react';

import { Alert, Box } from '@mui/material';

export const AddMembershipAlertView = () => (
  <Box marginBottom={4} paddingX={4}>
    <Alert severity="info" sx={{ marginBottom: '0 !important' }}>
      Billing will start on the membership start date. An upcoming billing will
      also be set for the next billing cycle according to the membership
      selected.
    </Alert>
  </Box>
);
