import { useNavigate } from 'react-router-dom';

import {
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EmptyCardImage from 'assets/images/png/emptyCard.png';
import { AddressDisplay } from 'common';
import ModalKey from 'enums/ModalKey';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import {
  clearSelectedPhysicianInCases,
  selectCurrentReferralCase,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { clearSelectedSearchPhysician } from 'stores/network';
import { formatPhone, getFullName, truncateText } from 'utils/common';

import {
  CustomIconButton,
  mapOldAddressImplementation,
  StackIconAndTypography,
} from './common';

const EmptyReferringProvider = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const isClientSponsor = useCheckUserIsClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleAddreferringPhysicianClick = () => {
    dispatch(clearSelectedPhysicianInCases());
    dispatch(clearSelectedSearchPhysician());
    navigate(
      `modal?type=${ModalKey.ADD_REFERRING_PHYSICIAN}&caseId=${currentReferralCase?.caseId}`
    );
  };

  return (
    <Paper
      className="bordered no-box-shadow"
      sx={{
        paddingBottom: 0,
        height: '268px',
        background: (t) => t.palette.common.white,
      }}
    >
      <Stack
        spacing={4}
        sx={{
          alignItems: 'center',
          height: '228px',
          padding: 6,
          textAlign: 'center',
        }}
      >
        <Avatar
          alt="empty"
          src={EmptyCardImage}
          sx={{ width: '80px', height: '80px' }}
          variant="square"
        />
        <Typography variant="body2">
          Referring provider information not available
        </Typography>
      </Stack>
      {!isClientSponsor && (
        <Box
          display="flex"
          justifyContent="center"
          onClick={handleAddreferringPhysicianClick}
          sx={{
            background: (t) => t.palette.gray.lighter,
            cursor: 'pointer',
          }}
        >
          <Typography
            gutterBottom={false}
            sx={{
              color: (t) => t.palette.secondary.main,
              padding: 2,
              textAlign: 'center',
            }}
            variant="body2"
          >
            Add Referring Physician
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

const ReferringProvider = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const data = currentReferralCase?.referral;
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const address =
    data?.facility?.address &&
    mapOldAddressImplementation(data?.facility.address);

  const adaptEmail =
    data &&
    (isSmallerThanSm
      ? truncateText(data?.facility?.email || '')
      : data?.facility?.email);

  const onEditClick = () => {
    dispatch(clearSelectedPhysicianInCases());
    dispatch(clearSelectedSearchPhysician());
    navigate(
      `modal?type=${ModalKey.EDIT_REFERRING_PHYSICIAN}&caseId=${currentReferralCase?.caseId}`
    );
  };

  if (!data || !Object.keys(data).length) {
    return <EmptyReferringProvider />;
  }
  return (
    <Paper
      sx={{
        border: `1px solid ${theme.palette.gray.lighter}`,
        padding: 4,
        height: '268px',
      }}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
      >
        <Typography color="text.secondary" gutterBottom={false} variant="body2">
          Referring Physician
        </Typography>
        <CustomIconButton onClick={onEditClick} size="small" />
      </Box>
      <Stack>
        <Typography fontWeight="medium" variant="body1">
          {getFullName(data?.physician?.firstName, data?.physician?.lastName)}
        </Typography>
        <Stack spacing={2}>
          {!!data?.facility?.name && (
            <Stack direction="row" marginBottom={4}>
              <Chip
                className="chip-badge chip-badge--info"
                label={data.facility?.name}
              />
            </Stack>
          )}
          <StackIconAndTypography
            icon={faMapMarkerAlt}
            label={(address && <AddressDisplay address={address} />) || 'N/A'}
          />
          <StackIconAndTypography
            icon={faMobileAlt}
            label={formatPhone(data?.facility?.phone || '')}
          />

          <StackIconAndTypography
            icon={faEnvelope}
            label={adaptEmail || 'N/A'}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ReferringProvider;
