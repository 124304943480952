// ----------------Important---------------------
// Strictly, maintain the keys in alphabetical order

export enum DictionaryKeys {
  ALLOW_TEXT_NOTIFICATIONS_CHECKBOX_LABEL = '_ALLOW_TEXT_NOTIFICATIONS_CHECKBOX_LABEL',
  CLIENT_REFERRAL_ACTION_ITEM = '_CLIENT_REFERRAL_LINK_ACTION_ITEM',
  CREATE_CASE_ACTION_ITEM = '_CREATE_CASE_ACTION_ITEM',
  CREATE_CASE_MODAL_TITLE = '_CREATE_CASE_MODAL_TITLE',
  FORM_CONFIGURATION_NOTE = '_FORM_CONFIGURATION_NOTE',
  GENDER = '_GENDER',
  PATIENT_TERMINOLOGY = '_PATIENT_TERMINOLOGY',
  SERVICE_CONFIGURATION_NOTE = '_SERVICE_CONFIGURATION_NOTE',
  TEXT_CONFIGURATION_NOTE = '_TEXT_CONFIGURATION_NOTE',
  VITAFY_PORTAL_WELCOME_HEADING = '_VITAFY_PORTAL_WELCOME_HEADING',
  VITAFY_PORTAL_WELCOME_SUBHEADING = '_VITAFY_PORTAL_WELCOME_SUBHEADING',
  VITAFY_PORTAL_WELCOME_DESCRIPTION = '_VITAFY_PORTAL_WELCOME_DESCRIPTION',
}
