/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { LoadingIndicator, Popover } from 'common';
import { useNoteHistoryQuery } from 'services/cases';

import NoteHistoryItem from './NoteHistoryItem';

interface IProps {
  noteId: string;
  caseId: string;
}

const NotesHistoryPopover = ({ noteId, caseId }: IProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const getNoteHistoryQuery = useNoteHistoryQuery(caseId || '', noteId, {
    enabled: !!caseId && !!noteId && !!isPopoverOpen,
  });

  const notesArray = getNoteHistoryQuery?.data;
  const lengthNotesArray = notesArray?.length || 0;

  const handleCheckPopverState = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    if (isOpen) {
      getNoteHistoryQuery.refetch();
    }
  };
  const theme = useTheme();
  return (
    <Popover
      buttonIcon={
        <Tooltip title="History">
          <span>
            <FontAwesomeIcon
              color={theme.palette.secondary.main}
              icon={faHistory}
              size="xs"
              style={{ fontSize: '0.6em' }}
            />
          </span>
        </Tooltip>
      }
      buttonSx={{
        width: '25px',
        height: '25px',
      }}
      closeOnMouseLeave={false}
      datacy="note-history"
      onPopoverStateChange={handleCheckPopverState}
    >
      <Box width="330px">
        {getNoteHistoryQuery.isLoading && (
          <LoadingIndicator containerHeight="100px" />
        )}
        {lengthNotesArray === 0 && !getNoteHistoryQuery.isLoading && (
          <Typography pt={2} variant="body2">
            No previous edit history.
          </Typography>
        )}

        <Box pt={1} textAlign="justify">
          <Typography gutterBottom={false}>
            {notesArray?.map((noteDetails, index) => (
              <Box key={index}>
                <NoteHistoryItem details={noteDetails} />
                {lengthNotesArray !== index + 1 && (
                  <Divider style={{ paddingTop: 8, marginBottom: 8 }} />
                )}
              </Box>
            ))}
          </Typography>
        </Box>
      </Box>
    </Popover>
  );
};

export default NotesHistoryPopover;
