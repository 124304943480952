import { Grid, Typography } from '@mui/material';

interface IProps {
  label: string;
  children: React.ReactNode;
  spacing?: number;
}

const AdvancedFilterFormLayout = ({ label, children, spacing }: IProps) => (
  <Grid container mb={4} spacing={spacing}>
    <Grid item sx={{ alignSelf: 'flex-end' }} xs={4}>
      <Typography fontWeight="medium" gutterBottom={false} variant="body2">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={8}>
      {children}
    </Grid>
  </Grid>
);
AdvancedFilterFormLayout.defaultProps = {
  spacing: 4,
};
export default AdvancedFilterFormLayout;
