import { Theme } from '@mui/material/styles';

export const Toolbar = (theme: Theme) => ({
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingRight: `${theme.spacing(6)} !important`,
        paddingLeft: `${theme.spacing(6)} !important`,
      },
    },
  },
});
