import apiRoutes from 'constants/apiRoutes';
import { ReferenceSubType, ReferenceType } from 'enums/common';
import { ISwitchTenant, IUserTenantsFilter } from 'interfaces/auth';
import {
  IFilter,
  IImageData,
  IPermission,
  ISavePermissionSchema,
} from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import { IProfileSchema } from 'interfaces/settings/Profile';
import {
  IAdaptedTenant,
  IAddTenantSchema,
  IEditTenantLogoResponse,
  ISlug,
  ITenant,
  ITenantContact,
  ITenantContacts,
  ITenantPartnerIFrame,
  ITenantSetting,
  ITenantTableRow,
  IUpdateTenantSettingResponse,
  IUpdateTenantSettingSchema,
  IVerifyTenantCode,
  IVerifyTenantCodeResponse,
} from 'interfaces/tenant-management/tenant';
import { IAssociation, IUserTableRow } from 'interfaces/tenant-management/user';
import { baseRequest } from 'utils/axios';

const { tenantManagement } = apiRoutes;

export const assignUser = async (
  tenantId: string,
  data: { userId: string }
): Promise<IResponse<IAssociation>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.tenant.assignUser.replace(':id', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getAssignedUsers = async (
  tenantId: string
): Promise<IResponse<IUserTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getUsers.replace(':id', tenantId),
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addTenant = async (
  data: IAddTenantSchema
): Promise<IResponse<ITenant>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: tenantManagement.tenant.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editTenant = async (
  tenantId: string,
  data: IAddTenantSchema | IProfileSchema
): Promise<IResponse<ITenant>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: tenantManagement.tenant.edit.replace(':id', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const verifyTenantCode = async (
  params: IVerifyTenantCode
): Promise<IResponse<IVerifyTenantCodeResponse>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.verifyCode,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getIframeDetails = async (): Promise<
  IResponse<ITenantPartnerIFrame>
> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.iframeDetails,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const getTenants = async (
  params: IFilter
): Promise<IResponse<IListResponse<ITenantTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantDetail = async (
  tenantId: string
): Promise<IResponse<ITenant>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getOne.replace(':id', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveTenantSlug = async (
  tenantId: string,
  data: ISlug
): Promise<IResponse<ITenant>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.tenant.slug.replace(':tenantId', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantPermissions = async (
  tenantId: string
): Promise<IResponse<IPermission[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getPermissions.replace(':id', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveTenantPermissions = async (
  tenantId: string,
  data: ISavePermissionSchema[]
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.tenant.savePermissions.replace(':id', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantUsers = async (
  tenantId: string
): Promise<IResponse<IUserTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getAllUsers.replace(':id', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const removeAssignUser = async (
  tenantId: string,
  userId: string,
  params: { referenceType: ReferenceType, referenceSubType: ReferenceSubType }
): Promise<IResponse<string>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: tenantManagement.tenant.removeAssignUser
      .replace(':id', tenantId)
      .replace(':userId', userId),
    params
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantSettings = async (
  tenantId: string
): Promise<IResponse<ITenantSetting>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getSettings.replace(':id', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveTenantSettings = async (
  tenantId: string,
  tenantSettingId: string,
  data: IUpdateTenantSettingSchema
): Promise<IResponse<IUpdateTenantSettingResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.tenant.saveSettings.replace(':id', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantContacts = async (
  tenantId: string
): Promise<IResponse<ITenantContacts>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getContacts.replace(':id', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveTenantContacts = async (
  tenantId: string,
  data: ITenantContact
): Promise<IResponse<ITenantContacts>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: tenantManagement.tenant.saveContacts.replace(':id', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const switchTenant = async (
  userId: string,
  tenantId: string,
  params?: IUserTenantsFilter
): Promise<IResponse<ISwitchTenant>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.switchTenant
      .replace(':userId', userId)
      .replace(':tenantId', tenantId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editTenantLogo = async (
  tenantId: string,
  data: IImageData
): Promise<IResponse<IEditTenantLogoResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.tenant.editLogo.replace(':tenantId', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantClientDetail = async (
  client: string
): Promise<IResponse<IAdaptedTenant>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.client
      .replace(':client', client)
      .toUpperCase(),
    params: {
      code: true,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantPartners = async (
  tenantId: string
): Promise<IResponse<ITenantTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.tenant.getPartners.replace(':id', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
