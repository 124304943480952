import {
  faCloudUpload,
  faFileClipboard,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Grid } from '@mui/material';
import { ModalFooter } from 'common';
import menuPaths from 'constants/path';
import { ClickBox } from 'features/cases/modal/files/ChooseFileUploadOptions';
import { selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { checkMenu } from 'utils/common';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ChooseFileUploadOptions = ({ setStep }: Props) => {
  const onOptionClick = (step: number) => {
    setStep(step);
  };
  const menus = useAppSelector(selectMenu);
  const isDocumentMenuAccessible = checkMenu(menuPaths.documents, menus);
  return (
    <Box>
      <Box marginBottom={10} marginTop={4} minHeight="45vh" padding={4}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <ClickBox
              icon={faCloudUpload}
              label="From Computer"
              onClick={() => onOptionClick(2)}
            />
          </Grid>
          {isDocumentMenuAccessible && (
            <Grid item xs={6}>
              <ClickBox
                icon={faFileClipboard}
                label="From Files"
                onClick={() => onOptionClick(3)}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <ClickBox
              icon={faLink}
              label="From URL"
              onClick={() => onOptionClick(4)}
            />
          </Grid>
        </Grid>
      </Box>
      <ModalFooter
        actionButtonType="button"
        cancelButtonType="back"
        isActionRequired={false}
        isDisabled={false}
        isLoading={false}
        onCancelButtonClick={() => onOptionClick(0)}
      />
    </Box>
  );
};

export default ChooseFileUploadOptions;
