import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Typography, useTheme } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { layoutConstants } from 'constants/layout';
import { IPaymentForm } from 'interfaces/payment';
import { getCardExpiryText, getCardNumberDisplayText } from 'utils/payment';

import FilledCardDetail from './FilledCardDetail';

const { STRIPE } = layoutConstants;

export const CardPayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();

  const { setValue, watch } = useFormContext<IPaymentForm>();
  const { cardToken, cardNumber, cardExpiry } = watch();

  const [inputError, setInputError] = useState('');

  const onBlur = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (!card) {
      return;
    }

    const result = await stripe.createToken(card);
    if (result.error) {
      setInputError(result.error.message || '');
    } else {
      const { card: cardDetail } = result.token;

      setValue('cardToken', result.token.id);
      setValue('cardNumber', getCardNumberDisplayText(cardDetail?.last4 || ''));
      setValue(
        'cardExpiry',
        getCardExpiryText(
          cardDetail?.exp_month?.toString() || '',
          cardDetail?.exp_year?.toString() || ''
        )
      );
    }
  };

  const clearInputError = () => {
    setInputError('');
  };

  const resetCardInformation = () => {
    setValue('cardToken', '');
    setValue('cardNumber', '');
    setValue('cardExpiry', '');
  };

  const CARD_ELEMENT_OPTIONS = useMemo(
    () =>
      ({
        iconStyle: 'solid',
        hidePostalCode: true,
        style: {
          base: {
            iconColor: theme.palette.gray.light,
            color: theme.palette.gray.darker,
            fontSize: STRIPE.inputFontSize,
            fontFamily: theme.typography.fontFamily,
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: theme.palette.gray.light,
            },
          },
          invalid: {
            color: theme.palette.error.main,
            ':focus': {
              color: theme.palette.gray.darker,
            },
          },
        },
      } as const),
    [theme]
  );

  const CARD_ELEMENT_WRAPPER_STYLE = useMemo(
    () => ({
      padding: theme.spacing(3),
      borderRadius: theme.customBorderRadius.sm,
      border: `1px solid ${theme.palette.gray.light}`,
      my: 2,
    }),
    [theme]
  );

  return (
    <Box>
      <Typography
        color="text.secondary"
        fontWeight="medium"
        gutterBottom={false}
        mt={4}
        variant="body1"
      >
        Card Information
      </Typography>

      {cardToken && cardNumber && cardExpiry ? (
        <FilledCardDetail
          cardExpiry={cardExpiry}
          cardNumber={cardNumber}
          onEditClick={resetCardInformation}
        />
      ) : (
        <>
          <Box sx={CARD_ELEMENT_WRAPPER_STYLE}>
            <CardElement
              onBlur={onBlur}
              onChange={clearInputError}
              options={CARD_ELEMENT_OPTIONS}
            />
          </Box>
          {inputError && <Alert severity="error">{inputError}</Alert>}
        </>
      )}
    </Box>
  );
};
