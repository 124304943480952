import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Typography } from '@mui/material';
import MediumSpanText from 'common/display/MediumSpanText';
import LoadingIndicator from 'common/LoadingIndicator';
import paymentConstants from 'constants/payment';
import { IPaymentForm } from 'interfaces/payment';
import { useAppSelector } from 'stores/hooks';
import { selectCardConnect } from 'stores/Payment';

import FilledBankDetail from './FilledBankDetail';

const { BANK_INPUT_PARAMETERS, ENCODED_STYLES } = paymentConstants.cardConnect;

const BankPayment = () => {
  const cardConnectInfo = useAppSelector(selectCardConnect);

  const { setValue, watch } = useFormContext<IPaymentForm>();

  const [isLoading, setIsLoading] = useState(true);
  const [isInputRequired, setIsInputRequired] = useState(false);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const bankToken = watch('bankToken');

  const clearErrorMessage = () => {
    setIsInputRequired(false);
    setIsInputInvalid(false);
    setErrorMessage('');
  };

  const resetBankInformation = () => {
    setValue('bankToken', '');
  };

  const listener = (event: any) => {
    if (typeof event.data === 'string') {
      // 1004 -> Input Required
      // 1001 -> Invalid Input
      try {
        const response = JSON.parse(event.data);

        if (response.errorCode !== '0') {
          const inputRequired = response.errorCode === '1004';
          const inputInvalid = response.errorCode === '1001';

          setIsInputRequired(inputRequired);
          setIsInputInvalid(inputInvalid);

          if (inputInvalid) {
            setErrorMessage(response.errorMessage);
          }
        } else {
          setValue('bankToken', response.token);
          clearErrorMessage();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('message', listener);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderedBankInput = (
    <>
      <Typography color="text.secondary" variant="body2">
        Enter your bank information in this format:{' '}
        <MediumSpanText>routing number/account number</MediumSpanText>.
        <br />
        For example: 123456789/1234123412341234
      </Typography>
      {isLoading && <LoadingIndicator containerHeight="5vh" />}
      <Box
        component="iframe"
        frameBorder="0"
        height={isLoading ? 0 : '70px'}
        id="tokenframe"
        name="tokenframe"
        onLoad={() => setIsLoading(false)}
        scrolling="no"
        src={`${cardConnectInfo.baseUrl}/itoke/ajax-tokenizer.html?&${BANK_INPUT_PARAMETERS}&css=${ENCODED_STYLES}`}
        title="iframe"
        width="100%"
      />

      {isInputRequired && (
        <Alert severity="error">Please fill the bank information.</Alert>
      )}

      {isInputInvalid && (
        <Alert severity="error">
          Please make sure that the bank information is correct: {errorMessage}
        </Alert>
      )}
    </>
  );

  return (
    <Box>
      <Typography
        color="text.secondary"
        fontWeight={(theme) => theme.typography.fontWeightMedium}
        mt={4}
        variant="body1"
      >
        Bank Information
      </Typography>
      {bankToken ? (
        <FilledBankDetail
          bankToken={bankToken}
          onEditClick={resetBankInformation}
        />
      ) : (
        renderedBankInput
      )}
    </Box>
  );
};

export default BankPayment;
