import { useNavigate } from 'react-router-dom';

import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AvatarDisplay, EllipseMenu, EllipseMenuItem } from 'common';
import { VipCrown } from 'common/VipCrown';
import { ClientEnrollmentStatus } from 'enums/client-management';
import ModalKey from 'enums/ModalKey';
import {
  CaseCode,
  EnrollmentCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import { useGetEnrollmentTenantSettings } from 'hooks/useGetEnrollmentTenantSettings';
import {
  IAdaptedClientGroupTableRow,
  IAdaptedClientIndividualTableRow,
} from 'interfaces/client-management';

interface IProps {
  data: IAdaptedClientIndividualTableRow | IAdaptedClientGroupTableRow;
  clientStatus: string;
}

const MemberCardDisplay = ({ data, clientStatus }: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isCustomMinutesMembershipEnabled =
    useGetEnrollmentTenantSettings(EnrollmentCode.CUSTOM_MINUTES_MEMBERSHIP) ===
    TenantSettingStatus.ENABLED;

  const createCase =
    useGetCaseTenantSettings(CaseCode.CREATECASE) ===
    TenantSettingStatus.ENABLED;

  const onCreateCaseClick = () => {
    navigate(
      `modal?type=${ModalKey.ADD_CASE}&patient=${data.clientId}&clientEnrollmentId=${data.clientEnrollmentId}&removeSearch=true`
    );
  };
  return (
    <Card>
      <CardContent>
        {/* Header */}
        <Grid container>
          <Grid item xs={!createCase ? 6 : 5.5}>
            <Box alignItems="center" display="flex">
              <AvatarDisplay alt="John Cena" />
              <Box ml={2} pr={12} width="100%">
                <Tooltip placement="top" title={data.name}>
                  <Box display="flex" gap={2}>
                    <Typography
                      color="text.secondary"
                      data-cy="client-expand-membership-name"
                      fontWeight={theme.typography.fontWeightMedium}
                      gutterBottom={false}
                      noWrap
                      variant="body2"
                    >
                      {data.name}
                    </Typography>
                    {data?.isVip ? <VipCrown /> : null}
                  </Box>
                </Tooltip>
                {data?.primary && (
                  <Tooltip placement="top" title={data.primary}>
                    <Typography
                      color="text.secondary"
                      data-cy="client-expand-membership-primary"
                      display="block"
                      gutterBottom={false}
                      noWrap
                      variant="caption"
                      width="100%"
                    >
                      {data.primary}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            alignItems="flex-end"
            display="flex"
            flexDirection="column"
            item
            xs={6}
          >
            <Tooltip
              placement="top"
              title={data.membership?.offeringName || ''}
            >
              <Typography
                color="text.secondary"
                data-cy="client-expand-membership-offeringname"
                fontWeight={theme.typography.fontWeightMedium}
                gutterBottom={false}
                noWrap
                textAlign="right"
                variant="body2"
                width="100%"
              >
                {data.membership?.offeringName}
              </Typography>
            </Tooltip>
            <Typography
              color="text.secondary"
              data-cy="client-expand-membership-benefitdate"
              gutterBottom={false}
              noWrap
              variant="caption"
            >
              Benefit: {data.benefit || '-'}
            </Typography>
            {isCustomMinutesMembershipEnabled &&
              clientStatus !== ClientEnrollmentStatus.SUBMITTED && (
                <Typography
                  color="text.secondary"
                  gutterBottom={false}
                  noWrap
                  variant="caption"
                >
                  Hours Purchased:{' '}
                  {Number(data?.membership?.hoursPurchased)?.toLocaleString() ||
                    '-'}{' '}
                  mins
                </Typography>
              )}
          </Grid>
          {createCase && (
            <Grid item xs={0.5}>
              <EllipseMenu>
                <EllipseMenuItem
                  dataCy="client-menu-quickView-createCase"
                  iconElement={
                    <FontAwesomeIcon icon={faCalendarTimes} size="xs" />
                  }
                  onClick={onCreateCaseClick}
                  text="Create Case"
                />
              </EllipseMenu>
            </Grid>
          )}
        </Grid>

        {/* <Box component={Divider} my={2} /> */}

        {/* Content */}
        {/* <Box>
          <Typography
            color="text.secondary"
            gutterBottom={false}
            noWrap
            variant="caption"
          >
            Additional Services
          </Typography>
          <Typography
            color="text.secondary"
            fontWeight={theme.typography.fontWeightMedium}
            gutterBottom={false}
            noWrap
            variant="body2"
          >
            -
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default MemberCardDisplay;
