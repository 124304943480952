import {
  faCakeCandles,
  faDroplet,
  faIdCard,
  faPerson,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography, useTheme } from '@mui/material';
import { BloodGroup } from 'enums/client-management';
import { Gender, GenderShortForm } from 'enums/common';

interface IProps {
  dob?: string;
  ssn?: string;
  gender?: string;
  bloodGroup?: BloodGroup;
}

const GenderDobLast4SSNDisplay = ({ dob, ssn, gender, bloodGroup }: IProps) => {
  const theme = useTheme();
  const { MALE, FEMALE } = Gender;
  const { M, F, O } = GenderShortForm;

  return (
    <Stack display="flex" flexDirection="row" mt={1}>
      {dob && (
        <Typography
          color={theme.palette.gray.main}
          data-cy="client-dob"
          gutterBottom={false}
          marginRight={2}
          noWrap
          variant="body2"
        >
          <FontAwesomeIcon
            color={theme.palette.gray.main}
            icon={faCakeCandles}
          />{' '}
          {dob}
        </Typography>
      )}

      {ssn && (
        <Typography
          color={theme.palette.gray.main}
          gutterBottom={false}
          marginRight={2}
          noWrap
          variant="body2"
        >
          <FontAwesomeIcon color={theme.palette.gray.main} icon={faIdCard} />{' '}
          {ssn}
        </Typography>
      )}

      {gender && (
        <Typography
          color={theme.palette.gray.main}
          gutterBottom={false}
          marginRight={2}
          noWrap
          variant="body2"
        >
          <FontAwesomeIcon color={theme.palette.gray.main} icon={faPerson} />{' '}
          {(gender === MALE && M) || (gender === FEMALE && F) || O}
        </Typography>
      )}

      {bloodGroup && (
        <Typography
          color={theme.palette.text.secondary}
          gutterBottom={false}
          noWrap
          variant="body2"
        >
          <FontAwesomeIcon
            color={theme.palette.primary.main}
            icon={faDroplet}
          />{' '}
          {bloodGroup}
        </Typography>
      )}
    </Stack>
  );
};

GenderDobLast4SSNDisplay.defaultProps = {
  dob: '',
  ssn: '',
  gender: '',
  bloodGroup: undefined,
};

export default GenderDobLast4SSNDisplay;
