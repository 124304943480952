/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDocument } from 'interfaces/documents';
import { IEmailNoteList } from 'interfaces/email-notes-files';
import { RootState } from 'stores';

interface IDecodedEmailNotesData {
  referenceType: string;
  referenceId: string;
  email: string;
  id: string;
  tenantId: string;
  name: string;
  requestedDate: string;
  clientId: string;
  taggedUser: ITaggedUser;
  fileDetails?: IDocument[];
}

interface ITaggedUser {
  name: string;
  email: string;
  id: string;
}

interface IEmailNoteState {
  emailNotesData: IDecodedEmailNotesData;
  notesLists: IEmailNoteList[];
  emailFileSteps: number;
  fileLists: IDocument[];
  draftNote: string;
}

const emailNotesData = {
  referenceType: '',
  referenceId: '',
  email: '',
  id: '',
  tenantId: '',
  name: '',
  requestedDate: '',
  clientId: '',
  taggedUser: {
    name: '',
    email: '',
    id: '',
  },
};

const initialState: IEmailNoteState = {
  // stores the client data which is currently being edited (used in Add client modal)
  emailNotesData,
  draftNote: '',
  notesLists: [],
  emailFileSteps: 0,
  fileLists: [],
};

export const slice = createSlice({
  name: 'email-notes',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setEmailNotesAndFilesData: (
      state,
      action: PayloadAction<{
        emailNotesData: IDecodedEmailNotesData;
        fileLists: IDocument[];
      }>
    ) => {
      state.emailNotesData = action.payload.emailNotesData;
      state.fileLists = action.payload.fileLists;
    },
    setEmailNotesData: (
      state,
      action: PayloadAction<IDecodedEmailNotesData>
    ) => {
      state.emailNotesData = action.payload;
    },

    setEmailNoteList: (state, action: PayloadAction<IEmailNoteList>) => {
      state.notesLists = [...state.notesLists, action.payload];
    },
    setEmailNoteLists: (state, action: PayloadAction<IEmailNoteList[]>) => {
      state.notesLists = action.payload;
    },

    setFilesTabStep: (state, action: PayloadAction<number>) => {
      state.emailFileSteps = action.payload;
    },

    setDraftNote: (state, action: PayloadAction<string>) => {
      state.draftNote = action.payload;
    },

    setEmailNoteFileList: (state, action: PayloadAction<IDocument[]>) => {
      state.fileLists = [...state.fileLists, ...action.payload];
    },
    setEmailNoteFile: (state, action: PayloadAction<IDocument[]>) => {
      state.fileLists = action.payload;
    },

    clearEmailNotesData: (state) => {
      state.emailNotesData = emailNotesData;
    },
    clearEmailFilesData: (state) => {
      state.fileLists = [];
    },

    clearEmailNotesLists: (state) => {
      state.notesLists = [];
    },
  },
});

// Actions
export const {
  setEmailNotesData,
  setEmailNotesAndFilesData,
  setFilesTabStep,
  setEmailNoteFileList,
  setEmailNoteList,
  clearEmailNotesData,
  clearEmailNotesLists,
  clearEmailFilesData,
  setEmailNoteFile,
  setEmailNoteLists,
  setDraftNote,
} = slice.actions;

// Selectors
export const selectEmailNotesData = (state: RootState) =>
  state.emailNotesFiles.emailNotesData;
export const selectEmailNoteLists = (state: RootState) =>
  state.emailNotesFiles.notesLists;
export const selectEmailNoteFileLists = (state: RootState) =>
  state.emailNotesFiles.fileLists;
export const selectEmailFilesTabStep = (state: RootState) =>
  state.emailNotesFiles.emailFileSteps;
export const selectDraftNote = (state: RootState) =>
  state.emailNotesFiles.draftNote;

// Reducer
export default slice.reducer;
