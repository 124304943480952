import { useNavigate, useSearchParams } from 'react-router-dom';

import { faAdd, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingIndicator } from 'common';
import PermissionGate from 'common/PermissionGate';
import ModalKey from 'enums/ModalKey';
import { IAdaptedTask } from 'interfaces/cases';
import { useTasksQuery } from 'services/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import TaskList from './TaskList';

const getFixedWidth = (xlBreakpoint: boolean, largeBreakpoint: boolean) => {
  if (xlBreakpoint) return '550px';
  if (largeBreakpoint) return '368px';
  return '300px';
};

const Tasks = ({ activeTaskId }: { activeTaskId: string }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const navigate = useNavigate();
  const caseId = searchParams.get('caseId');

  const xlBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));
  const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

  const fixedWidth = getFixedWidth(xlBreakpoint, largeBreakpoint);

  const handleOnClick = () => {
    navigate(
      `modal?type=${ModalKey.ADD_CASE_TASK}&caseId=${currentReferralCase?.caseId}`
    );
  };

  const addTaskButton = (
    <PermissionGate>
      <Button
        data-cy="case-addTask-button"
        fullWidth
        onClick={handleOnClick}
        size="medium"
        startIcon={<FontAwesomeIcon icon={faAdd} />}
        variant="outlined"
      >
        Add Task
      </Button>
    </PermissionGate>
  );

  const tasksQuery = useTasksQuery(caseId || '');
  const tasksRows = tasksQuery?.data?.rows || [];
  return (
    <Box>
      {!tasksQuery.isLoading && (
        <Box>
          <Box paddingX={2}>
            <Box>
              {tasksRows.length > 0 ? (
                <>
                  <Box
                    className="scrollbar"
                    height="79vh"
                    marginBottom={4}
                    overflow="hidden auto"
                  >
                    {tasksRows.map((e: IAdaptedTask) => {
                      let isActive = false;
                      if (e.taskId === activeTaskId) {
                        isActive = true;
                      }
                      return (
                        <TaskList data={e} isActive={isActive} key={e.taskId} />
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      position: 'fixed',
                      bottom: '10px',
                      right: '10px',
                      width: fixedWidth,
                    }}
                  >
                    {addTaskButton}
                  </Box>
                </>
              ) : (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  height="calc(80vh - 140px)"
                  justifyContent="center"
                  paddingY={2}
                >
                  <FontAwesomeIcon
                    icon={faListCheck}
                    size="3x"
                    style={{ color: theme.palette.primary.main }}
                  />
                  <Typography
                    color="gray.darker"
                    fontWeight="medium"
                    sx={{ marginTop: 4 }}
                    variant="body1"
                  >
                    Start Adding Task
                  </Typography>
                  <Typography sx={{ marginTop: 2 }} variant="body2">
                    No tasks found for this case.
                  </Typography>
                  {addTaskButton}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {tasksQuery.isLoading && <LoadingIndicator />}
    </Box>
  );
};

export default Tasks;
