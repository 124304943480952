import { useEffect, useMemo } from 'react';

import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import NoRecordsFoundDisplay from 'common/display/NoRecordsFoundDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import { ClientEnrollmentStatus } from 'enums/client-management';
import { Status } from 'enums/common';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import {
  selectMembershipListStatus,
  setMembershipListStatus,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import { MembershipCommonContentWrapper } from './common/MembershipCommonContentWrapper';
import { SelectMembershipStatusOptions } from './common/SelectMembershipStatusOptions';
import { ManageMembershipListDetail } from './ManageMembershipListDetail';
import { sortDataMembership } from './utils/common';

export const ManageMembershipList = ({
  data,
  isLoading,
  excludeFilters,
  excludeActions,
  includeMultipleMembershipAdditionalDetails,
}: {
  isLoading: boolean;
  data: IAdaptedClientEnrollmentMembership[];
  excludeFilters?: boolean;
  excludeActions?: boolean;
  includeMultipleMembershipAdditionalDetails?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const membershipActivity = useAppSelector(selectMembershipListStatus);

  const filteredClientEnrollmentMembershipData = useMemo(
    () =>
      !excludeFilters
        ? sortDataMembership(
            data.filter((x) => x.isActiveStatus?.includes(membershipActivity))
          )
        : sortDataMembership(data),
    [data, excludeFilters, membershipActivity]
  );

  useEffect(() => {
    if (data) {
      const isActiveDataExists = data?.filter((x) =>
        x.isActiveStatus?.includes(Status.ACTIVE)
      );
      if (!isActiveDataExists.length) {
        dispatch(setMembershipListStatus(Status.INACTIVE));
      }
    }
  }, [data, dispatch]);

  const isSubmittedMembershipIsMoreThanOne =
    filteredClientEnrollmentMembershipData.filter(
      (e) => e.status === ClientEnrollmentStatus.SUBMITTED
    ).length > 1;

  if (isLoading) return <LoadingIndicator />;

  return (
    <Box marginBottom={4}>
      {!excludeFilters && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row-reverse"
          pr={4}
        >
          <Box>
            <SelectMembershipStatusOptions
              onChange={(e) => dispatch(setMembershipListStatus(e as Status))}
              value={membershipActivity}
            />
          </Box>
        </Box>
      )}

      <MembershipCommonContentWrapper sx={{ maxHeight: '50vh' }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membership</TableCell>
                <TableCell>Fees</TableCell>
                <TableCell>Benefit Date</TableCell>
                <TableCell>Status</TableCell>
                {!excludeActions && <TableCell> </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClientEnrollmentMembershipData.map((membership) => (
                <ManageMembershipListDetail
                  data={membership}
                  excludeActions={excludeActions}
                  isSubmittedMembershipIsMoreThanOne={
                    isSubmittedMembershipIsMoreThanOne
                  }
                  key={membership.clientEnrollmentId}
                />
              ))}
            </TableBody>
          </Table>
          {filteredClientEnrollmentMembershipData.length < 1 && (
            <NoRecordsFoundDisplay />
          )}
        </TableContainer>
        {isSubmittedMembershipIsMoreThanOne && (
          <Alert
            icon={
              <Box
                color="error.dark"
                component={FontAwesomeIcon}
                icon={faWarning}
              />
            }
            severity="error"
            sx={{
              margin: 4,
              textAlign: 'left',
            }}
          >
            Multiple membership are in submitted status.
            {includeMultipleMembershipAdditionalDetails
              ? ` You can use "Manage membership" from the ellipsis icon to approve or decline memberships.`
              : ''}
          </Alert>
        )}
      </MembershipCommonContentWrapper>
    </Box>
  );
};

ManageMembershipList.defaultProps = {
  excludeFilters: false,
  excludeActions: false,
  includeMultipleMembershipAdditionalDetails: false,
};
