/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from 'react';

import {
  faBell,
  faEdit,
  faEnvelope,
  faMobileAlt,
  faTimes,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PermissionGate from 'common/PermissionGate';
import config from 'config';
import { ReferralCaseRequestedAssignUserType } from 'enums/case';
import { CaseCode, TenantSettingStatus } from 'enums/tenant-management/tenant';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import { IAdaptedTenantTableRow } from 'interfaces/tenant-management/tenant';
import { IAdaptedUserTableRow } from 'interfaces/tenant-management/user';
import {
  useReferralCaseAssignUserMutation,
  useReferralCaseChangeTenantMutation,
  useReferralCaseNotifyUserMutation,
} from 'services/cases';
import { selectAuthTenantData } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { formatPhone, getFullName, truncateText } from 'utils/common';
import { getUserDetailFromToken } from 'utils/jwt';

import AdminUserAutocomplete from '../../../common/autocomplete/AdminUserAutocomplete';
import TenantAutocomplete from '../../../common/autocomplete/TenantAutocomplete';
import {
  getRequestedEmail,
  getRequestedPhoneNumber,
  StackIconAndTypography,
} from '../common';

const AVATAR_WIDTH = '70px';
const AVATAR_HEIGHT = '70px';

interface IRequestedFromProps {
  isDisabled: boolean;
}

const RequestedTo = ({ isDisabled }: IRequestedFromProps) => {
  const theme = useTheme();
  const currentUser = getUserDetailFromToken();
  const isClientSponsor = useCheckUserIsClient();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const authTenantData = useAppSelector(selectAuthTenantData);

  const tenantId = currentReferralCase?.to?.id;
  const isRequestedToEnabled =
    useGetCaseTenantSettings(CaseCode.EDITREQUESTEDTO) ===
      TenantSettingStatus.ENABLED && !isDisabled;

  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const isAssignedToSwitchEnabled =
    authTenantData?.tenantAssociation?.tenantId === currentReferralCase?.to?.id;

  const data = currentReferralCase?.to;

  const [isAssignedTo, setAssignedTo] = useState(false);
  const [changeAssignedTo, setChangedAssignedTo] = useState(false);
  const [changeTenant, setChangeTenant] = useState(false);

  const adaptEmail =
    data &&
    (isSmallerThanSm
      ? truncateText(getRequestedEmail(data))
      : getRequestedEmail(data));

  const referralCaseAssignUserMutation = useReferralCaseAssignUserMutation();
  const referralCaseNotifyUserMutation = useReferralCaseNotifyUserMutation();
  const referralCaseChangeTenantMutation =
    useReferralCaseChangeTenantMutation();

  const handleChangeTenant = () => {
    setChangeTenant(true);
  };
  const handleCancelChangeTenant = () => {
    setChangeTenant(false);
  };
  const handleAssignedTo = () => {
    setAssignedTo(true);
  };
  const handleChangeAssignedTo = () => {
    setAssignedTo(true);
    setChangedAssignedTo(true);
  };
  const handleCancelAssignUser = () => {
    setChangedAssignedTo(false);
    setAssignedTo(false);
  };
  const handleNotify = () => {
    if (
      currentReferralCase?.to?.assignedTo?.id &&
      !referralCaseNotifyUserMutation.isLoading
    ) {
      const notifyData = {
        tenantId:
          tenantId || (authTenantData?.tenantAssociation.tenantId as string),
        id: currentReferralCase.to.assignedTo.id,
        name: currentReferralCase.to.assignedTo.name,
        email: currentReferralCase.to.assignedTo.email,
        caseLink: `${config.appBaseURL}/cases?caseId=${
          currentReferralCase!.caseId
        }&tenantId=${currentReferralCase?.to?.id}&fromEmail=true`,
      };
      referralCaseNotifyUserMutation.mutate({
        caseId: currentReferralCase!.caseId,
        data: notifyData,
      });
    }
  };
  const handleOnChangeSubmit = (user: IAdaptedUserTableRow) => {
    const submitData = {
      type: ReferralCaseRequestedAssignUserType.TO,
      id: user.userId,
      name: user.demographic.fullName,
      email: user.demographic.email,
      phone: user.demographic.unFormattedPhone,
      createdBy: getFullName(currentUser.firstName, currentUser.lastName),
    };
    referralCaseAssignUserMutation.mutate(
      {
        caseId: currentReferralCase!.caseId,
        data: submitData,
      },
      {
        onSuccess: () => {
          setChangedAssignedTo(false);
        },
      }
    );
  };
  const handleChange = (tenant: IAdaptedTenantTableRow) => {
    const payload = {
      email: tenant.email,
      id: tenant.tenantId,
      logoUrl: tenant.metaData.logoUrl,
      name: tenant.businessName,
      phone: tenant.phone,
      address: tenant.address,
    };
    referralCaseChangeTenantMutation.mutate(
      {
        caseId: currentReferralCase!.caseId,
        data: payload,
      },
      {
        onSuccess: () => {
          setChangeTenant(false);
        },
      }
    );
  };

  const renderedNoUserAssigned = (
    <Typography
      gutterBottom={false}
      sx={{
        fontStyle: 'italic',
      }}
      variant="body2"
    >
      No User Assigned
    </Typography>
  );
  return (
    <Box display="flex" width="100%">
      <Avatar
        alt="Logo"
        src={data?.logoUrl}
        sx={{
          width: isSmallerThanSm ? 40 : AVATAR_WIDTH,
          height: isSmallerThanSm ? 40 : AVATAR_HEIGHT,
          border: (t) => `1px solid ${t.palette.gray.light}`,
          img: {
            objectFit: 'contain',
          },
        }}
      />
      {/* Tenant Section */}
      <Stack pl={4} width={`calc(100% - ${AVATAR_WIDTH})`}>
        <Typography gutterBottom={false} variant="body2">
          Assigned to
        </Typography>
        {data?.name && !changeTenant ? (
          <Stack className="hover-stack" width="100%">
            <Box className="hover-stack-box">
              <Typography
                className="text-overflow-ellipses"
                color="gray.darker"
                fontWeight="medium"
                variant="body1"
              >
                {data?.name}
              </Typography>
              {isRequestedToEnabled && (
                <Box
                  className="hover-display-box"
                  sx={{
                    display: 'none',
                    paddingLeft: 2,
                    marginTop: '-14px !important',
                  }}
                >
                  <Tooltip title="Change Tenant">
                    <IconButton
                      data-cy="case-requestedTo-edit"
                      onClick={handleChangeTenant}
                      size="small"
                    >
                      <FontAwesomeIcon icon={faEdit} size="xs" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Stack>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            width="100%"
          >
            <Box paddingBottom={2} width="90%">
              <TenantAutocomplete
                handleChange={handleChange}
                inputProps={{
                  'data-cy': 'case-requestto-tenant-autocomplete',
                  disabled: isDisabled,
                }}
              />
            </Box>
            <Box
              alignItems="flex-end"
              display="flex"
              justifyContent="center"
              width="10%"
            >
              <IconButton onClick={handleCancelChangeTenant} size="small">
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: theme.palette.secondary.main }}
                />
              </IconButton>
            </Box>
          </Box>
        )}

        <Stack spacing={2}>
          {/* Assigned User Section */}
          <Stack alignItems="center" direction="row" display="flex" spacing={2}>
            <FontAwesomeIcon
              icon={faUserTie}
              style={{ color: theme.palette.primary.main }}
            />
            {isAssignedToSwitchEnabled ? (
              <>
                {data?.assignedTo?.name && !changeAssignedTo ? (
                  <Stack className="hover-stack" width="100%">
                    <Box className="hover-stack-box">
                      <Typography
                        className="text-overflow-ellipses"
                        gutterBottom={false}
                        variant="body2"
                      >
                        {data.assignedTo.name}
                      </Typography>
                      {!isClientSponsor && !isDisabled && (
                        <Box
                          className="hover-display-box"
                          sx={{
                            display: 'none',
                            paddingLeft: 1,
                          }}
                        >
                          <Tooltip title="Change User">
                            <PermissionGate>
                              <IconButton
                                onClick={handleChangeAssignedTo}
                                size="small"
                              >
                                <FontAwesomeIcon icon={faEdit} size="xs" />
                              </IconButton>
                            </PermissionGate>
                          </Tooltip>
                          <Tooltip title="Notify User">
                            <PermissionGate>
                              <IconButton
                                onClick={handleNotify}
                                size="small"
                                sx={{ marginLeft: 1 }}
                              >
                                <FontAwesomeIcon icon={faBell} size="xs" />
                              </IconButton>
                            </PermissionGate>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </Stack>
                ) : (
                  <>
                    {isAssignedTo ? (
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                      >
                        <Box width="90%">
                          {tenantId && (
                            <AdminUserAutocomplete
                              handleOnChangeSubmit={handleOnChangeSubmit}
                              inputProps={{
                                'data-cy': 'case-requestto-user-autocomplete',
                                disabled: isDisabled,
                              }}
                              tenantId={tenantId}
                            />
                          )}{' '}
                        </Box>
                        <Box
                          alignItems="flex-end"
                          display="flex"
                          justifyContent="center"
                          width="10%"
                        >
                          <IconButton
                            onClick={handleCancelAssignUser}
                            size="small"
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{ color: theme.palette.secondary.main }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        {!isClientSponsor ? (
                          <PermissionGate>
                            <Typography
                              className="text-overflow-ellipses"
                              gutterBottom={false}
                              onClick={handleAssignedTo}
                              sx={{
                                color: (t) => t.palette.secondary.main,
                                cursor: 'pointer',
                                '&:hover': {
                                  color: (t) => t.palette.secondary.dark,
                                },
                              }}
                              variant="body2"
                            >
                              Add Assigned To
                            </Typography>
                          </PermissionGate>
                        ) : (
                          renderedNoUserAssigned
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              renderedNoUserAssigned
            )}
          </Stack>

          <StackIconAndTypography
            icon={faMobileAlt}
            label={data ? formatPhone(getRequestedPhoneNumber(data)) : 'N/A'}
          />
          <StackIconAndTypography
            icon={faEnvelope}
            label={adaptEmail || 'N/A'}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default RequestedTo;
