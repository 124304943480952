import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as userAPI from 'api/tenant-management/users';
import toastMessageConstant from 'constants/toastMessage';
import { ReferenceSubType, ReferenceType } from 'enums/common';
import {
  IProfileAdminUserFilter,
  ITenantPermissionFilter,
  IUserTenantsFilter,
} from 'interfaces/auth';
import {
  IFilter,
  IProfileAdminFilter,
  IRolesFilter,
  ISavePermissionSchema,
} from 'interfaces/common';
import { IError } from 'interfaces/http';
import { IRoles } from 'interfaces/roles';
import { IAdaptedTenantTableRow } from 'interfaces/tenant-management/tenant';
import {
  IAddUserSchema,
  IProfileAdminSchema,
  IProfileAdminUsersRow,
  ISendInviteSchema,
  IUserAdvancedSearchForm,
  IUserFilter,
  IUserTableRow,
} from 'interfaces/tenant-management/user';
import { useSnackbar } from 'notistack';
import { RootState, store } from 'stores';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { combineFilters } from 'utils/misc';
import {
  adaptProfileAdminUsersList,
  adaptUser,
  adaptUserList,
  adaptUserPermission,
  adaptUserTenantList,
} from 'utils/tenant-management/users';

export const userKeys = {
  all: ['users'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
  list: (filters: IFilter & IUserAdvancedSearchForm) =>
    [...userKeys.lists(), { filters }] as const,
  details: () => [...userKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...userKeys.details(), id] as const,
  referenceClientDetail: (id: number | string) =>
    [...userKeys.details(), 'reference-client', id] as const,
  tenants: (userId: string) => [...userKeys.all, 'tenants', userId] as const,
};

export const profileAdminUsersKey = {
  all: ['profile-admin-users'] as const,
  lists: () => [...profileAdminUsersKey.all, 'list'] as const,
  list: (filters: IProfileAdminFilter) =>
    [...profileAdminUsersKey.lists(), { filters }] as const,
};

export const adminUserKeys = {
  all: ['admin-users'] as const,
  lists: () => [...adminUserKeys.all, 'list'] as const,
  list: (filters: IFilter & IUserAdvancedSearchForm) =>
    [...adminUserKeys.lists(), { filters }] as const,
  permissions: (userId: string) =>
    [...adminUserKeys.all, 'permissions', userId] as const,
};

export const userRolesKeys = {
  all: ['user-roles'] as const,
  lists: () => [...userRolesKeys.all, 'list'] as const,
  list: (clientId: string) => [...userRolesKeys.lists(), { clientId }] as const,
};

export const rolesKeys = {
  all: ['user-roles'] as const,
  lists: () => [...userRolesKeys.all, 'list'] as const,
  list: (clientId: string) => [...userRolesKeys.lists(), { clientId }] as const,
};

export const useAssignedTenantQuery = (
  userId: string,
  {
    enabled,
  }: {
    enabled: boolean;
  },
  params?: IUserTenantsFilter
) => {
  const queryInfo = useQuery(
    userKeys.tenants(userId),
    () => userAPI.getAssignedTenants(userId, params),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUsersQuery = (
  filters: IUserFilter,
  {
    fetchAdminUsers,
    enabled,
    roles,
    showDeletedAdmins
  }: {
    fetchAdminUsers?: boolean;
    enabled?: boolean;
    roles?: IRoles[];
    showDeletedAdmins?: boolean
  }
) => {
  // TenantId is in payload -> create admin user (user associated to a given tenant)
  const queryKey =
    fetchAdminUsers && !!filters.tenantId
      ? adminUserKeys.list(filters)
      : userKeys.list(filters);

  const queryInfo = useQuery(queryKey, () => userAPI.getUsers(filters), {
    select: (data) => adaptUserList(data, roles, filters.tenantId ?? '', { showDeletedAdmins: showDeletedAdmins ? !!filters.includeDeleted : false }),
    enabled,
  });

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useProfileAdminUsersQuery = (
  filters: IProfileAdminFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryKey = profileAdminUsersKey.list(filters);

  const queryInfo = useQuery(queryKey, () => userAPI.getProfileUsers(filters), {
    enabled,
    select: adaptProfileAdminUsersList,
  });

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUserRolesQuery = (
  userId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    userRolesKeys.list(userId),
    () => userAPI.getUserRoles(userId),
    { enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUserDetailQuery = (
  userId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    userKeys.detail(userId),
    () => userAPI.getUserDetail(userId),
    {
      enabled,
      select: adaptUser,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useReferenceClientUserDetailQuery = (
  clientId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    userKeys.referenceClientDetail(clientId),
    () => userAPI.getUserDetail(clientId, { associatedEntity: true }),
    {
      enabled,
      select: adaptUser,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUserTenantQuery = (
  userId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    userKeys.tenants(userId),
    () => userAPI.getUserTenants(userId),
    {
      enabled,
      select: adaptUserTenantList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUserPermissionQuery = (
  userId: string,
  params: ITenantPermissionFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    adminUserKeys.permissions(userId),
    () => userAPI.getUserPermissions(userId, params),
    {
      select: adaptUserPermission,
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const userStore = rootState.user;

  return useMutation(
    ({ data }: { data: IAddUserSchema }) => userAPI.addUser(data),
    {
      onSuccess: (res, context) => {
        const filters = combineFilters(
          { ...userStore.filters, ...userStore.sort },
          userStore.advancedSearch
        );

        // tenantId in payload => create admin user
        const queryKey = context.data.tenantId
          ? adminUserKeys.list({ ...filters, tenantId: context.data.tenantId })
          : userKeys.list(filters);

        // add the newly created user to the list
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            count: oldData.data.count + 1,
            rows: [res.data, ...oldData.data.rows],
          },
        }));

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useSetSuperAdminMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const userStore = rootState.user;

  const tenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId || '';

  return useMutation(
    ({
      data,
      userId,
    }: {
      data: { setAsSuperAdmin: boolean };
      userId: string;
    }) => userAPI.setSuperAdmin(data, userId),
    {
      onSuccess: (res, context) => {
        const filters = combineFilters(
          { ...userStore.filters, ...userStore.sort },
          userStore.advancedSearch
        );

        const queryKey = adminUserKeys.list({ ...filters, tenantId });

        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            rows: oldData.data.rows.map((i: IUserTableRow) => {
              if (i.userId === context.userId) {
                return {
                  ...i,
                  association: i.association.map((asso) => ({
                    ...asso,
                    isSuperAdmin: context.data.setAsSuperAdmin,
                  })),
                };
              }
              return i;
            }),
          },
        }));

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddProfileAdminUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const profileAdminStore = rootState.profileAdmin;
  const referenceId = rootState.auth.tenantData?.tenantAssociation.referenceId;

  return useMutation(
    ({ data }: { data: IProfileAdminSchema }) =>
      userAPI.addProfileAdminUser(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        const queryKey = profileAdminUsersKey.list({
          ...profileAdminStore.filters,
          ...profileAdminStore.sort,
          referenceId,
        });

        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            count: oldData.data.count + 1,
            rows: [res.data, ...oldData.data.rows],
          },
        }));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditProfileAdminUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const profileAdminStore = rootState.profileAdmin;
  const referenceId = rootState.auth?.tenantData?.tenantAssociation.referenceId;

  return useMutation(
    ({ userId, data }: { userId: string; data: IProfileAdminSchema }) =>
      userAPI.editProfileAdminUser(userId, data),
    {
      onSuccess: (res) => {
        const queryKey = profileAdminUsersKey.list({
          ...profileAdminStore.filters,
          ...profileAdminStore.sort,
          referenceId,
        });

        const queryData = queryClient.getQueriesData(queryKey);

        if (!queryData) return;

        queryClient.setQueriesData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            rows: oldData.data.rows.map((item: IProfileAdminUsersRow) => {
              if (item.userId === res.data.userId) {
                return res.data;
              }
              return item;
            }),
          },
        }));

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveProfileAdminMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const profileAdminStore = rootState.profileAdmin;
  const referenceId = rootState.auth?.tenantData?.tenantAssociation.referenceId;

  return useMutation(
    ({
      userId,
      tenantId,
      params,
    }: {
      userId: string;
      tenantId: string;
      params: IProfileAdminUserFilter;
    }) => userAPI.deleteProfileAdminUser(userId, tenantId, params),
    {
      onSuccess: (res, context) => {
        const queryKey = profileAdminUsersKey.list({
          ...profileAdminStore.filters,
          ...profileAdminStore.sort,
          referenceId,
        });
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            count: oldData.data.count - 1,
            rows: oldData.data.rows.filter(
              (item: IProfileAdminUsersRow) => item.userId !== context.userId
            ),
          },
        }));
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const userStore = rootState.user;

  return useMutation(
    ({ userId, data }: { userId: string; data: IAddUserSchema }) =>
      userAPI.editUser(userId, data),
    {
      onSuccess: (res, context) => {
        const filters = combineFilters(
          { ...userStore.filters, ...userStore.sort },
          userStore.advancedSearch
        );

        // tenantId in payload => update admin user
        const queryKey = context.data.tenantId
          ? adminUserKeys.list({ ...filters, tenantId: context.data.tenantId })
          : userKeys.list(filters);

        // checks if old cache data exist
        if (queryClient.getQueriesData(queryKey).length) {
          // update the list with the updated user
          queryClient.setQueryData(queryKey, (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.map((item: IUserTableRow) => {
                if (item.userId !== res.data.userId) return item;
                return res.data;
              }),
            },
          }));
        }
        // queryClient.invalidateQueries(membersKeys.lists());
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAssignTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, data }: { userId: string; data: IAdaptedTenantTableRow }) =>
      userAPI.assignTenant(userId, data),
    {
      onSuccess: (res, context) => {
        // add the newly created user to the list
        queryClient.setQueryData(
          userKeys.tenants(context.userId),
          (oldData: any) => ({
            ...oldData,
            data: [...(oldData.data ?? []), context.data],
          })
        );

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveAssignTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, userId }: { tenantId: string; userId: string }) =>
      userAPI.removeAssignTenant(tenantId, userId),
    {
      onSuccess: (res, context) => {
        // add the newly created user to the list
        queryClient.setQueryData(
          userKeys.tenants(context.userId),
          (oldData: any) => ({
            ...oldData,
            data: oldData.data.filter(
              (d: IAdaptedTenantTableRow) => d.tenantId !== res.data
            ),
          })
        );
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveAdminFromTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const rootState: RootState = store.getState();
  const userStore = rootState.user;
  return useMutation(
    ({ tenantId, userId }: { tenantId: string; userId: string }) =>
      userAPI.removeAssignTenant(tenantId, userId, {
        referenceType: ReferenceType.TENANT,
        referenceSubType: ReferenceSubType.TENANT
      }),
    {
      onSuccess: (res, context) => {
        const filters = combineFilters(
          { ...userStore.filters, ...userStore.sort },
          userStore.advancedSearch
        );

        queryClient.setQueryData(
          adminUserKeys.list({ ...filters, tenantId: context.tenantId }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.filter(
                (d: IUserTableRow) => d.userId !== context.userId
              ),
            },
          })
        );
        enqueueSnackbar(toastMessageConstant.ADMIN.DELETE, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSaveUserPermissionsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ userId, data }: { userId: string; data: ISavePermissionSchema[] }) =>
      userAPI.saveUserPermissions(userId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          adminUserKeys.permissions(context.userId),
          () => res.data
        );

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSendInviteMutate = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ userId, data }: { userId: string; data: ISendInviteSchema }) =>
      userAPI.sendInvite(userId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSendProfileAdminInviteMutate = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ userId }: { userId: string }) => userAPI.sendProfileAdminInvite(userId),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRolesQuery = (filter: IRolesFilter, enabled: boolean) => {
  const queryInfo = useQuery(
    rolesKeys.lists(),
    () => userAPI.getRoles(filter),
    {
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
