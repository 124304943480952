import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CometChat } from '@cometchat-pro/chat';
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import LoadingIndicator from 'common/LoadingIndicator';
import { layoutConstants } from 'constants/layout';
import messageConstant from 'constants/message';
import { MessageStatus } from 'enums/chat';
import { CustomIconButton } from 'features/cases/common';
import {
  IActiveTenantUser,
  IAdaptedMessage,
  IAdaptedResponseMessage,
  ICometChatMediaFile,
  ITenantUser,
} from 'interfaces/chat';
import { IResponseMessage } from 'interfaces/virtual-visit/comet-chat';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import { selectAuthTenantData } from 'stores/auth';
import {
  selectTenantId,
  setConversationId,
  setRegisterUser,
  setRemoveActiveTenant,
  setRemoveUnreadMessageUser,
  setSelectUpdateTenant,
  setSortTenantConversationList,
  setTypingStatus,
  setUnreadMessageUser,
  setUpdateTenantConversationList,
  setUpdateUserOnMsg,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import {
  getConversation,
  getCurrentTimestamp,
  groupMessagesByDate,
  pushMessageToGroup,
  updateLastMessage,
  updateMessage,
} from 'utils/chat';
import { getFullName } from 'utils/common';
import { getCurrentTime } from 'utils/moment';
import { v4 as uuidv4 } from 'uuid';

import { ChatItem } from './ChatItem';
import ChatWindowHeader from './ChatWindowHeader';
import ChatUploadDocumentsModal from './FileUploadModal';

const ChatWindow = ({ item }: { item: IActiveTenantUser }) => {
  const uid = item?.userId?.toLowerCase() ?? '';
  const timeStamp = getCurrentTime();
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);

  const typingNotification = new CometChat.TypingIndicator(
    uid,
    CometChat.RECEIVER_TYPE.USER
  );
  let typingTimeout: ReturnType<typeof setTimeout>;
  const tenantId = useAppSelector(selectTenantId);
  const authTenantData = useAppSelector(selectAuthTenantData);
  const displayName = authTenantData?.tenantAssociation?.displayName ?? '';
  const messageRef = useRef<HTMLDivElement | any>(null);
  const dispatch = useDispatch();
  const [isWindowOpen, setIsWindowOpen] = useState(true);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<IAdaptedResponseMessage | any>([]);
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);

  useEffect(() => {
    // Fetch data only once if conversationId is present and donot fetch when conversationId changes..the state is managed by fetchMessage
    if (item?.conversationId && item?.fetchMessage) {
      getConversation(
        item?.conversationId ?? '',
        tenantId ?? '',
        displayName,
        uid
      )
        .then((data: IAdaptedResponseMessage[] | any) => {
          setIsLoading(false);
          if (data?.length) {
            setMessages(groupMessagesByDate(data));
          }
        })
        .catch(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [
    displayName,
    item?.conversationId,
    item?.fetchMessage,
    tenantId,
    uid,
    isWindowOpen,
  ]);

  // Adding this disable lint rule since..the sugested methods by lint is not working..due to clear time out
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUsertyping = () => {
    clearTimeout(typingTimeout);
    CometChat.startTyping(typingNotification);
    typingTimeout = setTimeout(() => {
      CometChat.endTyping(typingNotification);
    }, 1000);
  };

  useEffect(() => {
    // Listen for the event when user starts typing
    CometChat.addMessageListener(
      item?.conversationId ?? '',
      new CometChat.MessageListener({
        onTypingStarted: (data: CometChat.TypingIndicator) => {
          const senderId = data.getSender().getUid().toLowerCase();
          dispatch(setTypingStatus({ uid: senderId, status: true }));
          setTypingStatus({ senderId, typing: true });
        },
        onTypingEnded: (data: CometChat.TypingIndicator) => {
          const senderId = data.getSender().getUid().toLowerCase();
          dispatch(setTypingStatus({ uid: senderId, status: false }));
        },
      })
    );
    document
      .getElementById('messageInput')
      ?.addEventListener('input', onUsertyping);
    return () => CometChat.removeMessageListener(item?.conversationId ?? '');
  }, [dispatch, item?.conversationId, onUsertyping, uid]);

  useEffect(() => {
    // Listen for incoming messages
    CometChatService.listenForMessage({
      onTextMessageReceived: (textMessage: CometChat.TextMessage | any) => {
        const messageText =
          CometChatService.extractTextFromMessage(textMessage);
        const senderId = textMessage?.sender?.uid.toLowerCase();

        // Set messages for receiver only
        if (senderId === uid) {
          const sender = CometChatService.extractSenderFromMessage(textMessage);
          const payload = {
            id: textMessage.id,
            receiverId: senderId,
            text: messageText,
            isSentByMe: false,
            isTextMessage: true,
            time: timeStamp ?? '',
            sender,
          };

          setMessages([...pushMessageToGroup(messages, payload)]);

          if (isWindowOpen) {
            CometChat.markAsRead(textMessage);
          }

          // Set the conversation id which is used to mark messages as read (only if the conversationId is not set)
          dispatch(
            setConversationId({
              userId: uid,
              conversationId: textMessage.conversationId,
            })
          );

          // Set unread message count if user chat window is minimized
          if (!isWindowOpen) {
            dispatch(setUnreadMessageUser(uid));
          }
        }
        updateMessage(
          senderId,
          messageText ?? '',
          textMessage.conversationId,
          textMessage.updatedAt,
          isWindowOpen
        );
      },
      onMediaReceived: (mediaMessage: ICometChatMediaFile) => {
        if (mediaMessage?.sender?.uid?.toLowerCase() === uid) {
          fetch(mediaMessage?.data?.url)
            .then((response) => response.text())
            .then((file) => {
              const mediaData = JSON.parse(file);
              const payload = {
                id: mediaMessage.id,
                receiverId: mediaMessage?.sender?.uid?.toLowerCase(),
                text: mediaData.data,
                isSentByMe: false,
                isTextMessage: false,
                time: timeStamp ?? '',
                sender: mediaMessage?.sender?.name,
                title: 'Document attached',
                type: mediaMessage?.data?.attachments[0]?.extension,
                fileName: mediaData.name,
              };
              setMessages([...pushMessageToGroup(messages, payload)]);
            });

          if (isWindowOpen) {
            CometChat.markAsRead(mediaMessage);
          }
          dispatch(
            setConversationId({
              userId: uid,
              conversationId: mediaMessage.conversationId,
            })
          );
        }
        updateMessage(
          mediaMessage?.sender?.uid,
          messageConstant.FILE_RECEIVED,
          mediaMessage.conversationId,
          mediaMessage.updatedAt,
          isWindowOpen
        );
      },
      listenerID: uid,
    });
    return () => CometChat.removeMessageListener(uid);
  }, [dispatch, isWindowOpen, messages, timeStamp, uid]);

  useEffect(() => {
    // Set the listener when receiver receives the message and mark the message as seen by receiver
    CometChat.addMessageListener(
      tenantId,
      new CometChat.MessageListener({
        onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
          const senderId = messageReceipt?.getSender()?.getUid();

          if (senderId?.toLowerCase() === uid) {
            setMessages([...updateLastMessage(messages, MessageStatus.SEEN)]);
          }
        },
      })
    );
    return () => CometChat.removeMessageListener(tenantId);
  }, [tenantId, uid, messages]);

  const addFriend = async (userId: string, friendId: string) => {
    try {
      await CometChatService.addFriend(userId, friendId);
    } catch (error) {
      throw Error();
    }
  };

  const createSendMessage = (
    message: string,
    value: ITenantUser | null,
    callBack: (value: boolean, id?: string, conversationId?: string) => void
  ) => {
    CometChatService.createUser(
      value?.userId ?? '',
      getFullName(value?.demographic?.firstName, value?.demographic?.lastName)
    ).finally(async () => {
      try {
        dispatch(setRegisterUser(value?.userId ?? ''));
        // establish a connection between two users..call this api to send and accept friend request between two users
        await addFriend(tenantId, value?.userId ?? '');
        await addFriend(value?.userId ?? '', tenantId);

        const data = await CometChatService.sendTextMessage({
          receiverType: CometChat.RECEIVER_TYPE.USER,
          guId: uid,
          receiverID: uid,
          message,
        });
        dispatch(
          setConversationId({
            userId: value?.userId ?? '',
            conversationId: data.conversationID,
          })
        );
        callBack(true, data.id, data.conversationID);
      } catch (error) {
        callBack(false);
      }
    });
  };

  const setSendStatus = (
    messagePayload: IAdaptedResponseMessage[],
    payload: IAdaptedMessage | IResponseMessage
  ) => {
    const tempMessage = messagePayload;

    tempMessage[tempMessage.length - 1].messages[
      tempMessage[tempMessage.length - 1].messages.length - 1
    ].status = payload.status;
    setMessages([...tempMessage]);
  };

  const onPressSendFile = (data: IResponseMessage) => {
    if (data?.status === MessageStatus.SENDING) {
      setUploadingFiles([...uploadingFiles, data]);
    } else {
      const filterFiles = uploadingFiles.filter(
        (value: any) => value.uid !== data?.uid
      );
      setUploadingFiles(filterFiles);
      if (data?.status === MessageStatus.SUCCESS) {
        dispatch(
          setUpdateTenantConversationList({
            userId: uid,
            conversationId: data.conversationId,
            messageType: 'File',
            text: messageConstant.FILE_SENT,
          })
        );
        dispatch(
          setUpdateUserOnMsg({
            id: uid,
            message: messageConstant.FILE_SENT,
            updatedAt: getCurrentTimestamp(),
            isSentByMe: true,
            conversationId: data.conversationId,
          })
        );

        dispatch(setSortTenantConversationList(item.userId));

        setMessages([
          ...pushMessageToGroup(messages, {
            ...data,
            id: data.id ?? '',
            text: data.text ?? '',
            isSentByMe: true,
            isTextMessage: false,
            isLastMessage: true,
            status: MessageStatus.DELEVIRED,
            receiverId: uid,
            time: data.time ?? '',
          }),
        ]);
      }
    }
  };

  const onPressSend = async () => {
    const message = messageRef?.current?.value?.trim('') ?? '';
    if (!message) return;
    const id = uuidv4();
    const payload = {
      id,
      text: message,
      isLastMessage: true,
      isSentByMe: true,
      isTextMessage: true,
      time: timeStamp?.toUpperCase() ?? '',
      sender: authTenantData?.tenantAssociation.displayName,
      status: MessageStatus.SENT,
      receiverId: uid,
    };
    const pushMessages = pushMessageToGroup(messages, payload);
    setMessages([...pushMessages]);
    messageRef.current.value = '';

    if (!item?.isRegistered || !item.isFriend) {
      createSendMessage(message, item, (value, messageId, conversationId) => {
        if (value) {
          // Update the user to tenantConversationList
          dispatch(
            setUpdateTenantConversationList({
              userId: item.userId,
              conversationId,
              text: message,
            })
          );

          dispatch(setSortTenantConversationList(item.userId));
          setSendStatus(pushMessages, {
            ...payload,
            status: MessageStatus.DELEVIRED,
            messageId,
          });
          CometChat.endTyping(typingNotification);
          dispatch(
            setUpdateUserOnMsg({
              id: uid,
              message,
              updatedAt: getCurrentTimestamp(),
              isSentByMe: true,
            })
          );
        } else {
          setSendStatus(pushMessages, {
            ...payload,
            status: MessageStatus.UNSENT,
            messageId,
          });
          CometChat.endTyping(typingNotification);
        }
      });
    } else {
      await CometChatService.sendTextMessage({
        receiverType: CometChat.RECEIVER_TYPE.USER,
        guId: uid,
        receiverID: uid,
        message,
      })
        .then((value) => {
          setSendStatus(pushMessages, {
            ...payload,
            status: MessageStatus.DELEVIRED,
            messageId: value.id,
          });
          CometChat.endTyping(typingNotification);
          dispatch(
            setUpdateUserOnMsg({
              id: uid,
              message: value.text,
              updatedAt: getCurrentTimestamp(),
              isSentByMe: true,
              conversationId: value.conversationID,
            })
          );
          dispatch(setSortTenantConversationList(item.userId));
        })
        .catch(() => {
          setSendStatus(pushMessages, {
            ...payload,
            status: MessageStatus.UNSENT,
          });
          CometChat.endTyping(typingNotification);
        });
    }
  };

  return (
    <>
      {openFileModal && (
        <ChatUploadDocumentsModal
          callBack={onPressSendFile}
          eventId={uid}
          handleClose={() => setOpenFileModal(false)}
          item={item}
        />
      )}
      <Box
        bgcolor={theme.palette.common.white}
        boxShadow="0px 0px 6px #00000029"
        display="flex"
        flexDirection="column"
        height={layoutConstants.CHAT.chatWindowHeight}
        justifyContent="space-between"
        mx={2}
        position="relative"
        sx={{
          transition: 'top 0.3s ease',
        }}
        top={isWindowOpen ? 0 : '90.5%'}
        width={isWindowOpen ? layoutConstants.CHAT.chatWindowWidth : 'auto'}
      >
        <ChatWindowHeader
          isWindowOpen={isWindowOpen}
          item={item}
          onPressHeader={() => {
            setIsWindowOpen(!isWindowOpen);
            if (item?.unreadMessagesCount) {
              dispatch(setRemoveUnreadMessageUser(uid));
            }
          }}
          onPressIcon={() => {
            CometChat.removeMessageListener(uid);
            dispatch(setRemoveActiveTenant(uid));

            // If user is not friend and has closed the window before sending message update as not friend
            // This will ensure the user will appear in search user when sending new message
            if (!messages.length && !item.isFriend) {
              dispatch(
                setSelectUpdateTenant({
                  userId: item.userId?.toLowerCase(),
                  isFriend: false,
                })
              );
            }
          }}
        />
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Stack height={380} mr={2} overflow="auto" pb={2} px={2}>
              <Box mt={4}>
                {messages?.length > 0 &&
                  messages?.map(
                    (value: IAdaptedResponseMessage, index: number) => (
                      <Box key={`${value.date}`}>
                        <Divider>
                          <Typography variant="caption">
                            {value.date}
                          </Typography>
                        </Divider>
                        {value.messages.map(
                          (message: IAdaptedMessage, messageIndex: number) => (
                            <ChatItem
                              isLast={
                                messages.length === index + 1 &&
                                value.messages.length === messageIndex + 1
                              }
                              item={message}
                              key={message.id}
                              scrollRef={scrollRef}
                              user={item}
                            />
                          )
                        )}
                      </Box>
                    )
                  )}
              </Box>
            </Stack>
            {uploadingFiles.length > 0 &&
              uploadingFiles.map((index: number) => (
                <Stack key={`${index}`} px={2}>
                  <Typography alignSelf="start">Sending File...</Typography>
                  <Divider sx={{ marginY: theme.spacing(2) }} />
                </Stack>
              ))}
            {item?.typingStatus?.status && (
              <Typography
                alignSelf="start"
                gutterBottom={false}
                px={2}
                variant="caption"
              >
                {item?.demographic?.firstName} is typing..
              </Typography>
            )}
            <Divider />
            <TextField
              id="messageInput"
              InputProps={{
                disableUnderline: true,
              }}
              inputRef={messageRef}
              multiline
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  onPressSend();
                }
              }}
              placeholder="Type a messages"
              rows={2}
              sx={{ paddingTop: 2, px: 2 }}
              variant="standard"
            />
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={4}
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              pb={2}
              px={2}
            >
              <CustomIconButton
                icon={faPaperclip}
                iconColor={theme.palette.gray.dark}
                onClick={() => setOpenFileModal(true)}
              />
              <IconButton
                onClick={onPressSend}
                sx={{ backgroundColor: theme.palette.primary.main }}
              >
                <FontAwesomeIcon
                  color={theme.palette.common.white}
                  icon={faPaperPlane}
                />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default memo(ChatWindow);
