import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { faClose, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FormMaskedPhoneInput, FormTextfield, Popover } from 'common';
import FormSupportInquiryPriorityInput from 'common/form/FormSupportInquiryPriority';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import {
  IExtraSupportInquiryFields,
  ISupportInquiryForm,
} from 'interfaces/support-inquiry';
import { AddSupportInquirySchema } from 'schema/support-inquiry';
import { useSaveSupportInquiryMutation } from 'services/support-inquiry';
import { selectAuthTenantAssociation, selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getFullName } from 'utils/common';
import { pxToRem } from 'utils/mui';
import { formatSupportInquirySavePayload } from 'utils/support-inquiry';

const defaultValues = {
  name: '',
  email: '',
  phone: '',
  priority: 'Low',
  description: '',
};

const SupportInquiry = (): JSX.Element => {
  const methods = useForm<ISupportInquiryForm>({
    resolver: yupResolver(AddSupportInquirySchema),
    defaultValues,
    mode: 'onBlur',
  });
  const { reset, getValues } = methods;

  const theme = useTheme();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const authClientData = useAppSelector(selectAuthTenantData);
  const location = useLocation();

  const addUseSaveSupportInquiryMutation = useSaveSupportInquiryMutation();

  const isUserTenant = useCheckUserIsTenantAdmin();

  const extraParams: IExtraSupportInquiryFields = {
    tenant: authTenantAssociation?.displayName ?? '',
    pageSource: location.pathname + location.search ?? '',
    userId: authClientData?.userId ?? '',
    ...(!isUserTenant && { tenantId: authTenantAssociation?.tenantId }),
  };

  useEffect(() => {
    if (!authClientData) return;
    const getUserDetails = () => ({
      name: getFullName(
        authClientData?.firstName,
        authClientData?.lastName,
        authClientData?.middleName
      ),
      email: authClientData?.email,
      phone: authClientData?.phone,
    });

    reset({
      ...getValues(),
      ...getUserDetails(),
    });

    // reset the form when the user closes the form
    if (!isPopoverOpen) {
      reset({
        ...defaultValues,
        ...getUserDetails(),
      });
    }
  }, [authClientData, methods, getValues, reset, isPopoverOpen]);

  const closeSupportInquiry = () => {
    setIsPopoverOpen(false);
  };

  const onSubmit = (data: ISupportInquiryForm) => {
    const payload = formatSupportInquirySavePayload(data, extraParams);

    addUseSaveSupportInquiryMutation.mutate(
      { data: payload },
      {
        onSuccess: () => {
          closeSupportInquiry();
        },
      }
    );
  };

  return (
    <Box
      sx={{
        zIndex: '5555',
      }}
    >
      <Popover
        buttonIcon={
          <FontAwesomeIcon
            color={theme.palette.secondary.contrastText}
            icon={faQuestion}
            style={{ fontSize: '1rem' }}
          />
        }
        buttonSx={{
          height: pxToRem(24),
          width: pxToRem(24),
          backgroundColor: theme.palette.gray.dark,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
        closeHandler={closeSupportInquiry}
        datacy="support-inquiry"
        isIconButton
        open={isPopoverOpen}
        setOpen={setIsPopoverOpen}
        sx={{
          p: 0,
          mx: 4,
          minWidth: '400px',
          borderRadius: '2px',
          marginTop: '16px',
        }}
      >
        <Box>
          {/* Header Section Begins */}
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            paddingY={2}
            sx={{
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Box textAlign="center" width="100%">
              <Typography gutterBottom={false}>
                Vitafy Health Support
              </Typography>
            </Box>
            <Box paddingRight={2}>
              <IconButton onClick={closeSupportInquiry}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Box>
          </Box>
          {/* Header Section Ends */}

          {/* Form Section Begins */}
          <Box marginTop={4}>
            <FormProvider {...methods}>
              <Box paddingX={4}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Grid item paddingBottom={4}>
                    <FormTextfield
                      label="Your full name"
                      name="name"
                      required
                    />
                  </Grid>
                  <Grid item paddingBottom={4}>
                    <FormTextfield
                      label="Your contact email"
                      name="email"
                      required
                    />
                  </Grid>
                  <Grid item paddingBottom={4}>
                    <FormMaskedPhoneInput label="Your contact number (optional)" />
                  </Grid>
                  <Grid item paddingBottom={4}>
                    <FormSupportInquiryPriorityInput />
                  </Grid>
                  <Grid item paddingBottom={4}>
                    <FormTextfield
                      label="Description *"
                      multiline
                      name="description"
                      rows={4}
                    />
                  </Grid>
                  <LoadingButton
                    color="primary"
                    loading={addUseSaveSupportInquiryMutation.isLoading}
                    size="small"
                    sx={{
                      marginBottom: '16px',
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Send
                  </LoadingButton>
                </form>
              </Box>
            </FormProvider>
          </Box>
          {/* Form Section Ends */}
        </Box>
      </Popover>
    </Box>
  );
};

export default SupportInquiry;
