import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  FormAddressInput,
  FormCheckbox,
  FormMaskedPhoneInput,
  FormTextfield,
} from 'common';
import FormPhoneCountryInput from 'common/form/FormPhoneCountryInput';
import FormReferringAgentAutocomplete from 'common/form/FormReferringAgentAutocomplete';
import FormSwitch from 'common/form/FormSwitch';
import FormTagInput from 'common/form/FormTagInput';
import { DictionaryKeys } from 'constants/languageDictionary';
import {
  MiscellaneousCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useGetMiscellaneousTenantSettings } from 'hooks/useGetMiscellaneousTenantSettings';
import { useTextFromLanguageDictionary } from 'hooks/useTextFromLanguageDictionary';
import { useGetAddClientSchema } from 'schema/client-management';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { checkEnrollmentAffiliateVisibleTenantSetting } from 'utils/tenantSetting';

interface IProps {
  allDisabled?: boolean;
  showReferringAgent?: boolean;
  isSponsorIdEnabled?: boolean;
}

const GroupForm = ({
  allDisabled,
  showReferringAgent,
  isSponsorIdEnabled,
}: IProps): JSX.Element => {
  const tenantSettings = useAppSelector(selectTenantSettings);
  const { watch, setValue } = useFormContext();
  const phoneValue = watch('phone');

  const isPhoneValid =
    useGetAddClientSchema().fields.phone.isValidSync(phoneValue);
  const isInternationalAddressEnabled =
    useGetMiscellaneousTenantSettings(
      MiscellaneousCode.INTERNATIONAL_ADDRESS
    ) === TenantSettingStatus.ENABLED;

  const isAffiliateEnabled =
    checkEnrollmentAffiliateVisibleTenantSetting(tenantSettings);

  const [allowTextNotificationsLabel] = useTextFromLanguageDictionary({
    keys: [DictionaryKeys.ALLOW_TEXT_NOTIFICATIONS_CHECKBOX_LABEL],
  });

  useEffect(() => {
    if (!isPhoneValid) {
      setValue('textEnabled', false);
    }
  }, [isPhoneValid, setValue]);

  const isUserAgent = useCheckUserIsAgent();
  const isUserClient = useCheckUserIsClient();

  const hideAddNoteField = isUserAgent || isUserClient;
  const checkEnabledSettings = useCheckFormFieldSettings().enabled;
  const checkReqiredSettings = useCheckFormFieldSettings().required;
  const isVipSettingsEnabled =
    useGetMiscellaneousTenantSettings(MiscellaneousCode.VIP_CLIENT) ===
    TenantSettingStatus.ENABLED;

  const isEmailRequiredFields = checkReqiredSettings.email;

  const isTextNotificationEnabled = () => {
    if (!checkEnabledSettings.phone) return false;
    return checkEnabledSettings.textNotification;
  };

  return (
    <>
      {/* TODO: We are hardcoding below condition .. Later it will be based according to Tenant Settings */}
      {showReferringAgent &&
        isAffiliateEnabled &&
        checkEnabledSettings.referredBy && (
          <Grid mb={4}>
            <FormReferringAgentAutocomplete
              disabled={allDisabled || isUserAgent || isUserClient}
              inputProps={{ 'data-cy': 'client-group-autocomplete' }}
              isConfigurableThroughSetting
              label="Referred by"
              name="referredBy"
            />
          </Grid>
        )}
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Company Information
      </Typography>
      <Grid container mb={4}>
        <Grid item xs={12}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'company-name-input' }}
            label="Company Name"
            name="businessName"
            required
          />
        </Grid>
      </Grid>
      <Grid container mb={4} spacing={4}>
        <Grid item xs={6}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'contact-first-name-input' }}
            label="Contact First Name"
            name="firstName"
            required
          />
          {isVipSettingsEnabled && <FormCheckbox label="VIP" name="isVip" />}
        </Grid>
        <Grid item xs={6}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'contact-last-name-input' }}
            label="Contact Last Name"
            name="lastName"
            required
          />
        </Grid>
      </Grid>

      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Contact Information
      </Typography>
      <Grid container spacing={4}>
        {checkEnabledSettings.email && (
          <Grid item xs={6}>
            <FormTextfield
              disabled={allDisabled}
              inputProps={{ 'data-cy': 'contact-email-input' }}
              label="Email"
              name="email"
              required={isEmailRequiredFields}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Stack justifyContent="flex-end">
            {isInternationalAddressEnabled && checkEnabledSettings.phone && (
              <FormPhoneCountryInput
                disabled={allDisabled}
                isConfigurableThroughSetting
              />
            )}
            {!isInternationalAddressEnabled && checkEnabledSettings.phone && (
              <FormMaskedPhoneInput
                disabled={allDisabled}
                isConfigurableThroughSetting
              />
            )}

            {isTextNotificationEnabled() && (
              <Box display="flex" justifyContent="flex-start" mt={1}>
                <FormSwitch
                  color="primary"
                  data-cy="text-enabled"
                  disabled={!phoneValue || !isPhoneValid || allDisabled}
                  label={allowTextNotificationsLabel}
                  name="textEnabled"
                />
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container mb={4} spacing={4}>
        {isInternationalAddressEnabled && checkEnabledSettings.homePhone && (
          <Grid item pt={1} xs={6}>
            <FormPhoneCountryInput
              cCode="hCode"
              data-cy="case-phone-country-custom-input"
              disabled={allDisabled}
              ext="homeExt"
              isRequired={checkReqiredSettings.homePhone}
              label="Home Phone"
              name="home"
              resetAddress={false}
            />
          </Grid>
        )}

        {!isInternationalAddressEnabled && checkEnabledSettings.homePhone && (
          <Grid item xs={6}>
            <FormMaskedPhoneInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'case-phone-country-input',
              }}
              isRequired={checkReqiredSettings.homePhone}
              label="Home Phone"
              name="home"
            />
          </Grid>
        )}

        {isInternationalAddressEnabled && checkEnabledSettings.workPhone && (
          <Grid item pt={2} xs={6}>
            <FormPhoneCountryInput
              cCode="wCode"
              data-cy="case-phone-country-custom-input"
              disabled={allDisabled}
              ext="workExt"
              isRequired={checkReqiredSettings.workPhone}
              label="Work Phone"
              name="work"
              resetAddress={false}
            />
          </Grid>
        )}

        {!isInternationalAddressEnabled && checkEnabledSettings.workPhone && (
          <Grid item xs={6}>
            <FormMaskedPhoneInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'case-phone-country-input',
              }}
              isRequired={checkReqiredSettings.workPhone}
              label="Work Phone"
              name="work"
            />
          </Grid>
        )}
      </Grid>
      {checkEnabledSettings.address && (
        <Grid mt={-4}>
          <FormAddressInput
            allDisabled={allDisabled}
            isConfigurableThroughSetting
          />
        </Grid>
      )}
      <Grid item mt={4} xs={12}>
        <FormTagInput
          disabled={allDisabled}
          label="Add Tags"
          name="tags"
          showHelperText
          size="small"
        />
      </Grid>
      {!hideAddNoteField && (
        <Grid mt={8}>
          <FormTextfield
            disabled={allDisabled}
            label="Add Note"
            multiline
            name="note"
            rows={4}
          />
        </Grid>
      )}

      {isSponsorIdEnabled && (
        <Grid container>
          <Grid mb={8} mt={8} xs={6}>
            <FormTextfield
              disabled={allDisabled || isUserAgent || isUserClient}
              label="Member Id"
              name="memberId"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

GroupForm.defaultProps = {
  allDisabled: false,
  showReferringAgent: false,
  isSponsorIdEnabled: false,
};

export default GroupForm;
