import { Box, Typography, useTheme } from '@mui/material';

const FormBanner = ({
  title,
  description,
}: {
  title: string | JSX.Element;
  description?: string | JSX.Element;
}) => {
  const theme = useTheme();
  return (
    <Box p={4} sx={{ backgroundColor: theme.palette.gray.lighter }}>
      <Typography gutterBottom={false} variant="body1">
        {title}
      </Typography>

      {!!description && (
        <Typography gutterBottom={false} mt={2} padding={0} variant="body2">
          {description}
        </Typography>
      )}
    </Box>
  );
};

FormBanner.defaultProps = {
  description: '',
};

export default FormBanner;
