import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Typography } from '@mui/material';
import { AuthLayout, FormTextfield } from 'common';
import uiRoutes from 'constants/uiRoutes';
import { IForgetUsernameForm } from 'interfaces/auth';
import { ForgetUsernameSchema } from 'schema/auth';
import { useForgetUsernameMutation } from 'services/auth';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
};

export const ForgetUsername = (): JSX.Element => {
  const methods = useForm<IForgetUsernameForm>({
    resolver: yupResolver(ForgetUsernameSchema),
    defaultValues,
  });

  const navigate = useNavigate();
  const forgetUsernameMutation = useForgetUsernameMutation();
  const handleClose = () => {
    navigate(-1);
  };

  const onSubmit = (body: IForgetUsernameForm) => {
    forgetUsernameMutation.mutate(body, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <AuthLayout>
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container>
              <Box mt={4}>
                <Typography variant="h3">Forgot Username</Typography>
                <Typography>
                  Please provide your personal information to know your username
                </Typography>
              </Box>
              <Box>
                <Box mt={4}>
                  <FormTextfield label="First Name" name="firstName" required />
                </Box>
                <Box mt={4}>
                  <FormTextfield label="Last Name" name="lastName" required />
                </Box>
                <Box mt={4}>
                  <FormTextfield label="Email" name="email" required />
                </Box>
              </Box>
              <Box mt={4}>
                <LoadingButton
                  color="primary"
                  fullWidth
                  loading={false}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Send Username
                </LoadingButton>
              </Box>
              <Box mt={4} textAlign="center">
                <Button onClick={() => navigate(`/${uiRoutes.auth.login}`)}>
                  Back to login
                </Button>
              </Box>
            </Container>
          </form>
        </FormProvider>
      </Box>
    </AuthLayout>
  );
};
