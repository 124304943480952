export enum LookUpCategory {
  EVENT = 'EVENT',
  INTERVAL = 'INTERVAL',
  NETWORK_OFFERING_STATUS = 'NETWORK_OFFERING_STATUS',
  NETWORK_OFFERING_VISIBILITY = 'NETWORK_OFFERING_VISIBILITY',
  VERSION_CONTROL = 'VERSION_CONTROL',
  COMMUNICATION_MEDIUM = 'COMMUNICATION_MEDIUM',
  SETTING_MENU_CATEGORY = 'SETTING_MENU_CATEGORY',
  REPORT = 'REPORT',
  RICH_TEXT_EDITOR_VARIABLES = 'RICH_TEXT_EDITOR_VARIABLES',
  RELEASE_NOTE = 'RELEASE_NOTE',
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
}

export enum LookUpCode {
  POST_ENROLLMENT_CHECKOUT_EDITOR_VARIABLES = 'POST_ENROLLMENT_CHECKOUT_EDITOR_VARIABLES',
  PRE_ENROLLMENT_CHECKOUT_EDITOR_VARIABLES = 'PRE_ENROLLMENT_CHECKOUT_EDITOR_VARIABLES',
  VITAFY_PORTAL = 'VITAFY_PORTAL',
}

export enum LooKUpVariableType {
  BUTTON = 'button',
  LINK = 'link',
  TEXT = 'text',
}
