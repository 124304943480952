import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as tenantAPI from 'api/tenant-management/tenant';
import { ReferenceSubType, ReferenceType } from 'enums/common';
import { IUserTenantsFilter } from 'interfaces/auth';
import { IFilter, IImageData, ISavePermissionSchema } from 'interfaces/common';
import { IError } from 'interfaces/http';
import { IProfileSchema } from 'interfaces/settings/Profile';
import {
  IAddTenantSchema,
  ISlug,
  ITenantContact,
  ITenantTableRow,
  IUpdateTenantSettingSchema,
} from 'interfaces/tenant-management/tenant';
import { IUserTableRow } from 'interfaces/tenant-management/user';
import { useSnackbar } from 'notistack';
import { RootState, store } from 'stores';
import {
  adaptTenant,
  adaptTenantContacts,
  adaptTenantList,
  adaptTenantPartners,
  adaptTenantSettingList,
  adaptTenantUserList,
} from 'utils/tenant-management/tenant';

export const tenantKeys = {
  all: ['tenants'] as const,
  lists: () => [...tenantKeys.all, 'list'] as const,
  list: (filters: IFilter) => [...tenantKeys.lists(), { filters }] as const,
  details: () => [...tenantKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...tenantKeys.details(), id] as const,
  users: (tenantId: string) => [...tenantKeys.all, 'users', tenantId] as const,
  clientDetail: (client: string) =>
    [...tenantKeys.all, 'client', client] as const,
  iframeDetails: () => [...tenantKeys.all, 'iframe'],
  partners: (tenantId: string) =>
    [...tenantKeys.all, 'partners', tenantId] as const,
};

const tenantPermissionKeys = {
  all: ['tenant-permissions'] as const,
  lists: () => [...tenantPermissionKeys.all, 'list'] as const,
  list: (tenantId: string) =>
    [...tenantPermissionKeys.lists(), { tenantId }] as const,
};

const tenantSettingKeys = {
  all: ['tenant-settings'] as const,
  lists: () => [...tenantSettingKeys.all, 'list'] as const,
  settings: () => [...tenantSettingKeys.all, 'settings'] as const,
};

const tenantContactKeys = {
  all: ['tenant-contacts'] as const,
  lists: () => [...tenantContactKeys.all, 'list'] as const,
};

export const useTenantIframeDetailQuery = () => {
  const queryInfo = useQuery(tenantKeys.iframeDetails(), () =>
    tenantAPI.getIframeDetails()
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
export const useTenantQuery = (filters: IFilter) => {
  const queryInfo = useQuery(
    tenantKeys.list(filters),
    () => tenantAPI.getTenants(filters),
    {
      select: adaptTenantList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAssignedUserQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantKeys.users(tenantId),
    () => tenantAPI.getAssignedUsers(tenantId),
    { enabled }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantDetailQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantKeys.detail(tenantId),
    () => tenantAPI.getTenantDetail(tenantId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantSlugMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, tenantId }: { data: ISlug; tenantId: string }) =>
      tenantAPI.saveTenantSlug(tenantId, data),
    {
      onSuccess: (res) => {
        const rootState: RootState = store.getState();
        const activeTenantId = rootState.auth.activeTenant?.tenantId;

        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        if (activeTenantId) {
          queryClient.invalidateQueries(tenantKeys.detail(activeTenantId));
        }
      },
    }
  );
};

export const useTenantPermissionQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantPermissionKeys.list(tenantId),
    () => tenantAPI.getTenantPermissions(tenantId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantUserQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantKeys.users(tenantId),
    () => tenantAPI.getTenantUsers(tenantId),
    {
      enabled,
      select: adaptTenantUserList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantSettingQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantSettingKeys.lists(),
    () => tenantAPI.getTenantSettings(tenantId),
    {
      enabled,
      select: adaptTenantSettingList,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
// Reason for doing so it that we want to store the map (or object) of tenant setting instead of array so that we can reference efficiently when required.
export const useTenantSettingForStoreQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantSettingKeys.settings(),
    () => tenantAPI.getTenantSettings(tenantId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantContactQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantContactKeys.lists(),
    () => tenantAPI.getTenantContacts(tenantId),
    {
      enabled,
      select: adaptTenantContacts,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAssignUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, data }: { tenantId: string; data: { userId: string } }) =>
      tenantAPI.assignUser(tenantId, data),
    {
      onSuccess: (res, context) => {
        // add the newly created user to the list
        queryClient.setQueryData(
          tenantKeys.users(context.tenantId),
          (oldData: any) => ({
            ...oldData,
            data: [...(oldData.data ?? []), context.data],
          })
        );
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const tenantStore = rootState.tenant;

  return useMutation(
    ({ data }: { data: IAddTenantSchema }) => tenantAPI.addTenant(data),
    {
      onSuccess: (res) => {
        // add the newly created tenant to the list
        queryClient.setQueryData(
          tenantKeys.list({ ...tenantStore.filters, ...tenantStore.sort }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              count: oldData.data.count + 1,
              rows: [res.data, ...oldData.data.rows],
            },
          })
        );

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveAssignUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, userId }: { tenantId: string; userId: string }) =>
      tenantAPI.removeAssignUser(tenantId, userId, { referenceType: ReferenceType.TENANT, referenceSubType: ReferenceSubType.TENANT }),
    {
      onSuccess: (res, context) => {
        // add the newly created user to the list
        queryClient.setQueryData(
          tenantKeys.users(context.tenantId),
          (oldData: any) => ({
            ...oldData,
            data: oldData.data.filter(
              (d: IUserTableRow) => d.userId !== res.data
            ),
          })
        );
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const tenantStore = rootState.tenant;

  return useMutation(
    ({
      tenantId,
      data,
    }: {
      tenantId: string;
      data: IAddTenantSchema | IProfileSchema;
    }) => tenantAPI.editTenant(tenantId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        const queryKey = tenantKeys.list({
          ...tenantStore.filters,
          ...tenantStore.sort,
        });

        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) {
          return;
        }

        // update the list with the updated tenant
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            rows: oldData.data.rows.map((item: ITenantTableRow) => {
              if (item.tenantId !== res.data.tenantId) return item;
              return res.data;
            }),
          },
        }));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSaveTenantPermissionsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ tenantId, data }: { tenantId: string; data: ISavePermissionSchema[] }) =>
      tenantAPI.saveTenantPermissions(tenantId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSaveTenantSettingsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      tenantId,
      tenantSettingId,
      data,
    }: {
      tenantId: string;
      tenantSettingId: string;
      data: IUpdateTenantSettingSchema;
    }) => tenantAPI.saveTenantSettings(tenantId, tenantSettingId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(tenantSettingKeys.lists());
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSaveTenantContactsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, data }: { tenantId: string; data: ITenantContact }) =>
      tenantAPI.saveTenantContacts(tenantId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(tenantContactKeys.lists());
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSwitchTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({
      userId,
      tenantId,
      params,
    }: {
      userId: string;
      tenantId: string;
      params?: IUserTenantsFilter;
    }) => tenantAPI.switchTenant(userId, tenantId, params),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditTenantLogoMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, tenantId }: { data: IImageData; tenantId: string }) =>
      tenantAPI.editTenantLogo(tenantId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        const queryKey = tenantKeys.detail(res.data.tenantId);
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            metaData: {
              ...oldData.data.metaData,
              imageUrl: res.data.metaData.imageUrl,
            },
          },
        }));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useTenantClientDetailQuery = (
  client: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantKeys.clientDetail(client),
    () => tenantAPI.getTenantClientDetail(client),
    {
      enabled,
      select: adaptTenant,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantPartnersQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantKeys.partners(tenantId),
    () => tenantAPI.getTenantPartners(tenantId),
    {
      enabled,
      select: adaptTenantPartners,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
