import { Outlet, useSearchParams } from 'react-router-dom';

import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import VitafyLogo from 'assets/images/svg/vitafy-logo.svg';
import 'assets/scss/_auth-layout.scss';
import CopyrightFooter from 'common/CopyrightFooter';
import ListDisplay from 'common/display/ListDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import Logo from 'common/Logo';
import commonConstants from 'constants/common';
import { DictionaryKeys } from 'constants/languageDictionary';
import { LookUpCategory } from 'enums/lookups';
import { useTextFromLanguageDictionary } from 'hooks/useTextFromLanguageDictionary';
import { useLookUpQuery } from 'services/lookups';
import { useTenantClientDetailQuery } from 'services/tenant-management/tenant';
import { pxToRem } from 'utils/mui';

const AuthLayout = ({ children }: { children?: React.ReactNode }) => {
  const [searchParams] = useSearchParams();

  const client = searchParams.get('client') ?? '';
  const { data, isLoading } = useTenantClientDetailQuery(client, {
    enabled: !!client,
  });

  const [heading, subHeading, description] = useTextFromLanguageDictionary({
    keys: [
      DictionaryKeys.VITAFY_PORTAL_WELCOME_HEADING,
      DictionaryKeys.VITAFY_PORTAL_WELCOME_SUBHEADING,
      DictionaryKeys.VITAFY_PORTAL_WELCOME_DESCRIPTION,
    ],
  });

  const networkOfferingStatusQuery = useLookUpQuery(
    {
      category: LookUpCategory.VERSION_CONTROL,
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
    },
    { enabled: true }
  );

  const version =
    (networkOfferingStatusQuery.data &&
      networkOfferingStatusQuery?.data[0]?.description) ||
    '';

  if (isLoading) {
    return <LoadingIndicator containerHeight="100vh" />;
  }

  return (
    <Box className="heroku-img">
      <Container className="box-flexContainer ">
        <Logo
          fallbackImg={VitafyLogo}
          src={data?.metaData?.logoUrl}
          sx={{
            maxWidth: '100%',
            height: pxToRem(100),
            alignSelf: 'center',
            pb: 8,
            display: { xs: 'block', md: 'none' },
            objectFit: 'contain',
          }}
        />
        <Paper
          sx={{
            maxWidth: '750px',
            mb: 10,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{
              width: { xs: 'auto', md: '750px' },
            }}
          >
            <Grid
              item
              paddingX={6}
              paddingY={8}
              sx={{
                backgroundColor: (theme) => theme.palette.gray.lighter,
                display: { xs: 'none', md: 'block' },
              }}
              xs={6}
            >
              <Stack
                display="flex"
                height="100%"
                justifyContent="center"
                spacing={4}
              >
                <Logo
                  fallbackImg={VitafyLogo}
                  src={data?.metaData?.logoUrl}
                  sx={{
                    height: pxToRem(60),
                    alignSelf: 'flex-start',
                    pb: 2,
                    maxWidth: '100%',
                    objectFit: 'contain',
                  }}
                />
                <Typography variant="h4" width={pxToRem(200)}>
                  {data
                    ? data?.displayName || data?.businessName || ''
                    : heading}
                </Typography>
                <Typography variant="body1">
                  {data ? data?.metaData?.tagLine || '' : subHeading}
                </Typography>
                <Divider />
                {data ? (
                  (
                    <ListDisplay
                      list={[
                        {
                          icon: <FontAwesomeIcon icon={faPhone} />,
                          text: data.phone ? data.formattedPhone : '',
                          typographyProps: {
                            variant: 'body2',
                          },
                        },
                        {
                          icon: <FontAwesomeIcon icon={faEnvelope} />,
                          text: data?.email ? data.email : '',
                          typographyProps: {
                            variant: 'body2',
                          },
                        },
                      ]}
                    />
                  ) || null
                ) : (
                  <Typography variant="body2">{description}</Typography>
                )}

                <Box
                  alignItems="flex-end"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                >
                  <Typography variant="body1">Version {version}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={6} paddingY={8} xs={12}>
              {children ?? <Outlet />}
            </Grid>
          </Grid>
        </Paper>
        <Box className="auth-bottom-content">
          <CopyrightFooter />
        </Box>
      </Container>
    </Box>
  );
};

AuthLayout.defaultProps = {
  children: null,
};

export default AuthLayout;
