import { useNavigate } from 'react-router-dom';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import EmptyCardImage from 'assets/images/png/emptyCard.png';
import PermissionGate from 'common/PermissionGate';
import { ReferralCaseServiceType } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { IReferralCaseProcedure, IReferralCaseService } from 'interfaces/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { truncateText } from 'utils/common';

import { CustomIconButton } from './common';

const EmptyServiceInformation = () => {
  const isClientSponsor = useCheckUserIsClient();
  const isUserGroup = useCheckUserIsClientGroup();
  const isUserEmail = useCheckUserIsEmail();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const navigate = useNavigate();
  const onAddClick = () => {
    navigate(
      `modal?type=${ModalKey.ADD_CASE_SERVICE}&caseId=${currentReferralCase?.caseId}`
    );
  };
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      marginTop={4}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: 'center', padding: 2, textAlign: 'center' }}
      >
        <Avatar
          alt="empty"
          src={EmptyCardImage}
          sx={{ width: '60px', height: '60px' }}
          variant="square"
        />
        <Typography variant="body2">Services not available</Typography>
      </Stack>
      {(!isClientSponsor || isUserGroup || isUserEmail) && (
        <PermissionGate>
          <Button
            onClick={onAddClick}
            size="medium"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            variant="text"
          >
            Add Service
          </Button>
        </PermissionGate>
      )}
    </Box>
  );
};

const ServiceInformation = () => {
  const isClientSponsor = useCheckUserIsClient();
  const isUserGroup = useCheckUserIsClientGroup();
  const isUserEmail = useCheckUserIsEmail();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  // TODO : Need to change
  const dataAll = currentReferralCase?.service as IReferralCaseService[];
  const data = dataAll?.length
    ? (dataAll as IReferralCaseService[])
    : undefined;
  const navigate = useNavigate();
  const onEditClick = () => {
    navigate(
      `modal?type=${ModalKey.EDIT_CASE_SERVICE}&caseId=${currentReferralCase?.caseId}`
    );
  };
  const procedure = currentReferralCase?.service?.length
    ? (currentReferralCase?.service[0] as IReferralCaseProcedure)
    : undefined;
  const isProcedureService =
    procedure?.type === ReferralCaseServiceType.MEDICAL;
  return (
    <Box>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Service Information
      </Typography>
      <Paper
        className="bordered no-box-shadow"
        sx={{
          padding: 4,
          height: '134px',
        }}
      >
        {data ? (
          <>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Typography>
                {isProcedureService ? 'Procedure Name' : 'Service Name'}
              </Typography>
              {(!isClientSponsor || isUserGroup || isUserEmail) && (
                <PermissionGate>
                  <CustomIconButton
                    datacy="case-editService"
                    onClick={onEditClick}
                    size="small"
                  />
                </PermissionGate>
              )}
            </Box>
            {!isProcedureService ? (
              <Box
                className="scrollbar"
                data-cy="case-service-information-scrollbar"
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: 'calc(100% - 40px)',
                }}
              >
                {data.map((service) => (
                  <Chip
                    data-cy={`case-service-information-${service?.name}`}
                    key={service.id}
                    label={
                      <Tooltip placement="top" title={service?.name}>
                        <Typography gutterBottom={false} variant="body2">
                          <Typography
                            component="span"
                            fontWeight={(theme) =>
                              theme.typography.fontWeightMedium
                            }
                            gutterBottom={false}
                            variant="body2"
                          >
                            {service?.name && truncateText(service?.name, 80)}{' '}
                            {service?.code ? `(${service.code})` : ''}
                          </Typography>
                        </Typography>
                      </Tooltip>
                    }
                    sx={{
                      marginRight: 2,
                      marginBottom: 2,
                    }}
                  />
                ))}
              </Box>
            ) : (
              <Tooltip placement="top" title={procedure?.name}>
                <Typography
                  color="gray.darker"
                  fontWeight="medium"
                  variant="body1"
                >
                  {procedure?.name && truncateText(procedure?.name, 80)}{' '}
                </Typography>
              </Tooltip>
            )}

            {isProcedureService && (
              <Chip
                className="chip-badge chip-badge--info"
                label={procedure.primaryCode}
              />
            )}
          </>
        ) : (
          <EmptyServiceInformation />
        )}
      </Paper>
    </Box>
  );
};

export default ServiceInformation;
