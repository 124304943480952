import { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import NoRecordsFoundDisplay from 'common/display/NoRecordsFoundDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import { ManageMembershipVisibilityOptions } from 'common/manage-membership/common/ManageMembershipVisibilityOptions';
import { useGetCurrentSelectedClientAndMembershipDetails } from 'common/manage-membership/hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionType } from 'common/manage-membership/hooks/useGetManageMembershipActionType';
import { ManageMembershipStatus } from 'enums/client-management/manage-membership';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import {
  selectManageMembershipSelectedMembership,
  selectManageMembershipStatus,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getCurrentDate } from 'utils/moment';

import { OptionsListItem } from '../../common/OptionsListItem';
import { useGetTenantOfferings } from '../../hooks/useGetTenantOfferings';
import { usePreSelectOffering } from './usePreSelectOffering';

export const IndividualMembershipList = () => {
  const { isLoading, data } = useGetTenantOfferings();

  const dispatch = useAppDispatch();

  const manageMembershipStatus = useAppSelector(selectManageMembershipStatus);
  const { membership } = useGetCurrentSelectedClientAndMembershipDetails();
  const { details } = useAppSelector(selectManageMembershipSelectedMembership);
  const isGroupMemberOrDependent = !!details?.primaryId || !!details?.groupId;
  const { isEditMembership } = useGetManageMembershipActionType();

  const handleSelectedMemberOption = (
    item: IAdaptedTenantMembershipOfferingTableRow
  ) => {
    const originallySelectedOfferings = details?.offerings.id;
    const isSelectedOfferingSameAsOriginallySelectedOffering =
      originallySelectedOfferings === item?.tenantOfferingId;

    let startDate = getCurrentDate();

    if (
      isSelectedOfferingSameAsOriginallySelectedOffering &&
      details?.startDate
    ) {
      startDate = details.startDate;
    }

    if (
      !isSelectedOfferingSameAsOriginallySelectedOffering &&
      isGroupMemberOrDependent &&
      item?.startDate
    ) {
      startDate = item.startDate;
    }

    dispatch(
      setManageMembershipSelectedOfferings({
        selectedOffering: item,
        startDate,
      })
    );
  };

  const getRemoveVisibilityRestriction = () => {
    if (membership?.primaryId || membership?.groupId) return true;

    return false;
  };

  const removeVisibilityRestriction = getRemoveVisibilityRestriction();

  const modifiedData = useMemo(() => {
    let filteredData = [];
    if (removeVisibilityRestriction) return data;
    if (manageMembershipStatus === ManageMembershipStatus.PRIVATE) {
      filteredData = data.filter(
        (e) => e.visibility === ManageMembershipStatus.PRIVATE
      );
    } else {
      filteredData = data.filter(
        (e) => e.visibility === ManageMembershipStatus.PUBLIC
      );
    }

    // return filterMembershipByEnrollment(filteredData, clientMemberships || []);
    return filteredData;
  }, [data, manageMembershipStatus, removeVisibilityRestriction]);

  usePreSelectOffering({ data });

  useEffect(() => {
    if (!data.length && isEditMembership) {
      dispatch(
        setManageMembershipSelectedOfferings({
          selectedOffering: null,
        })
      );
    }
  }, [data.length, dispatch, isEditMembership]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <Box>
      {!removeVisibilityRestriction && (
        <Box paddingBottom={4} paddingTop={0} paddingX={4}>
          <ManageMembershipVisibilityOptions />
        </Box>
      )}
      {!isLoading && modifiedData.length > 0 && (
        <Box
          className="scrollbar"
          marginLeft={4}
          marginRight={1}
          maxHeight="25vh"
          sx={{ overflowY: 'auto' }}
        >
          {modifiedData.map((item) => (
            <Box marginRight={2}>
              <OptionsListItem
                handleSelectedMemberOption={handleSelectedMemberOption}
                item={item}
              />
            </Box>
          ))}
        </Box>
      )}
      {!isLoading && modifiedData.length === 0 && (
        <Box>
          <NoRecordsFoundDisplay />
        </Box>
      )}
    </Box>
  );
};
