import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, FormControl, Grid, Typography } from '@mui/material';
import { RadioBoxButton } from 'common/ui/RadioBoxButton';
import { SupportInquiryPriority } from 'enums/support-inquiry';
import { PriorityType } from 'interfaces/common';
import { getInputLabel } from 'utils/misc';

const PRIORITY_MAP = {
  Low: {
    value: SupportInquiryPriority.LOW,
    label: SupportInquiryPriority.LOW,
  },
  Medium: {
    value: SupportInquiryPriority.MEDIUM,
    label: SupportInquiryPriority.MEDIUM,
  },
  High: {
    value: SupportInquiryPriority.HIGH,
    label: SupportInquiryPriority.HIGH,
  },
};

interface IPriorityTypeRadioButtonProps {
  type: PriorityType;
  value: PriorityType;
  onChange: (value: PriorityType) => void;
  isDisabled?: boolean;
}

const PriorityRadioButton = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: IPriorityTypeRadioButtonProps, ref) => {
    const { type, value, onChange, isDisabled = false } = props;

    return (
      <RadioBoxButton
        isActive={value === type}
        isDisabled={isDisabled}
        label={PRIORITY_MAP[type].label}
        onClick={() => onChange(type)}
      />
    );
  }
);

PriorityRadioButton.defaultProps = {
  isDisabled: false,
};

interface IProps {
  isRequired?: boolean;
  isDisabled?: boolean;
}

const FormSupportInquiryPriorityInput = ({
  isRequired = false,
  isDisabled = false,
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography
        color={errors.priority ? 'error' : undefined}
        gutterBottom={false}
        sx={{ display: 'block', marginTop: '-4px', mb: 1 }}
        variant="caption"
      >
        {getInputLabel('Priority', isRequired)}
      </Typography>

      <FormControl sx={{ width: '100%' }} variant="outlined">
        <Controller
          control={control}
          name="priority"
          render={({ field }) => (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PriorityRadioButton
                  isDisabled={isDisabled}
                  type={SupportInquiryPriority.LOW}
                  {...field}
                />
              </Grid>
              <Grid item xs={4}>
                <PriorityRadioButton
                  isDisabled={isDisabled}
                  type={SupportInquiryPriority.MEDIUM}
                  {...field}
                />
              </Grid>
              <Grid item xs={4}>
                <PriorityRadioButton
                  isDisabled={isDisabled}
                  type={SupportInquiryPriority.HIGH}
                  {...field}
                />
              </Grid>
            </Grid>
          )}
        />
      </FormControl>
    </Box>
  );
};

FormSupportInquiryPriorityInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
};

export default FormSupportInquiryPriorityInput;
