import { useState } from 'react';

import {
  faBell,
  faEdit,
  faEnvelope,
  faMobileAlt,
  faTimes,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import GroupAdminUserAutocomplete from 'common/autocomplete/GroupAdminUserAutocomplete';
import PermissionGate from 'common/PermissionGate';
import config from 'config';
import { ReferralCaseRequestedAssignUserType } from 'enums/case';
import { CaseCode, TenantSettingStatus } from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import { useCheckUserIsClientIndividualOrDependentOrGroupMember } from 'hooks/useCheckUserIsClientIndividualOrDependentOrGroupMember';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import {
  IAdaptedUserTableRow,
  IProfileAdminUsersRow,
} from 'interfaces/tenant-management/user';
import {
  useReferralCaseAssignUserMutation,
  useReferralCaseNotifyUserMutation,
} from 'services/cases';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { formatPhone, getFullName, truncateText } from 'utils/common';
import { getUserDetailFromToken } from 'utils/jwt';

import AdminUserAutocomplete from '../../../common/autocomplete/AdminUserAutocomplete';
import {
  getRequestedEmail,
  getRequestedPhoneNumber,
  StackIconAndTypography,
} from '../common';

const AVATAR_WIDTH = '70px';
const AVATAR_HEIGHT = '70px';

interface IRequestedFromProps {
  isDisabled: boolean;
}

const RequestedFrom = ({ isDisabled }: IRequestedFromProps) => {
  const theme = useTheme();
  const currentUser = getUserDetailFromToken();
  const isClientSponsor =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const tenantData = useAppSelector(selectAuthTenantAssociation);
  const data = currentReferralCase?.from;
  const isFromTypeClient =
    currentReferralCase?.from?.type === UserType.INDIVIDUAL;
  const isFromTypeGroup = currentReferralCase?.from?.type === UserType.GROUP;
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const tenantId = currentReferralCase?.from?.id;

  const [changeAssignedTo, setChangedAssignedTo] = useState(false);

  const referralCaseAssignUserMutation = useReferralCaseAssignUserMutation();
  const referralCaseNotifyUserMutation = useReferralCaseNotifyUserMutation();

  const adaptEmail =
    data &&
    (isSmallerThanSm
      ? truncateText(getRequestedEmail(data))
      : getRequestedEmail(data));

  const isGroupCasesEnabled =
    useGetCaseTenantSettings(CaseCode.GROUP_CASES) ===
    TenantSettingStatus.ENABLED;

  const handleChangeAssignedTo = () => {
    setChangedAssignedTo(true);
  };

  const handleCancelAssignUser = () => {
    setChangedAssignedTo(false);
  };

  const handleNotify = () => {
    if (
      currentReferralCase?.from?.assignedTo?.id &&
      !referralCaseNotifyUserMutation.isLoading
    ) {
      const notifyData = {
        tenantId: tenantId || (tenantData?.tenantId as string),
        id: currentReferralCase.from.assignedTo.id,
        name: currentReferralCase.from.assignedTo.name,
        email: currentReferralCase.from.assignedTo.email,
        caseLink: `${config.appBaseURL}/cases?caseId=${
          currentReferralCase!.caseId
        }&tenantId=${currentReferralCase?.from?.id}&fromEmail=true`,
      };
      referralCaseNotifyUserMutation.mutate({
        caseId: currentReferralCase!.caseId,
        data: notifyData,
      });
    }
  };

  const handleOnChangeSubmit = (
    user: IAdaptedUserTableRow | IProfileAdminUsersRow
  ) => {
    const isUserTable = (
      u: IAdaptedUserTableRow | IProfileAdminUsersRow
    ): u is IAdaptedUserTableRow =>
      (u as IAdaptedUserTableRow).demographic.fullName !== undefined;

    const submitData = {
      type: ReferralCaseRequestedAssignUserType.FROM,
      id: user.userId,
      name: isUserTable(user)
        ? user.demographic.fullName
        : getFullName(
            user.demographic.firstName,
            user.demographic.lastName,
            user.demographic.middleName
          ),
      email: user.demographic.email,
      phone: isUserTable(user)
        ? user.demographic.unFormattedPhone
        : user.demographic.phone,
      createdBy: getFullName(currentUser.firstName, currentUser.lastName),
    };
    referralCaseAssignUserMutation.mutate(
      {
        caseId: currentReferralCase!.caseId,
        data: submitData,
      },
      {
        onSuccess: () => {
          setChangedAssignedTo(false);
        },
      }
    );
  };

  const showHoverBox = () => {
    if (isDisabled) {
      return false;
    }

    if (isClientSponsor || (!isGroupCasesEnabled && isFromTypeGroup)) {
      return false;
    }
    return true;
  };
  const hoverStackBox = (
    <Stack className="hover-stack" width="100%">
      <Box className="hover-stack-box">
        <Typography
          className="text-overflow-ellipses"
          gutterBottom={false}
          variant="body2"
        >
          {data?.assignedTo?.name}
        </Typography>
        {showHoverBox() && (
          <Box
            className="hover-display-box"
            sx={{
              display: 'none',
              paddingLeft: 2,
            }}
          >
            <Tooltip title="Change User">
              <Box>
                <PermissionGate>
                  <IconButton
                    data-cy="case-requestedFrom-changeButton"
                    onClick={handleChangeAssignedTo}
                    size="small"
                  >
                    <FontAwesomeIcon icon={faEdit} size="xs" />
                  </IconButton>
                </PermissionGate>
              </Box>
            </Tooltip>
            <Tooltip title="Notify User">
              <Box>
                <PermissionGate>
                  <IconButton
                    data-cy="case-requestedFrom-notifyUser"
                    onClick={handleNotify}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    <FontAwesomeIcon icon={faBell} size="xs" />
                  </IconButton>
                </PermissionGate>
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Stack>
  );

  const showGroupAdminUserAutoComplete = () => {
    if (isGroupCasesEnabled && isFromTypeGroup) return true;
    return false;
  };

  const autoCompleteBox = (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      width="100%"
    >
      <Box width="90%">
        {!showGroupAdminUserAutoComplete() && tenantId && (
          <AdminUserAutocomplete
            handleOnChangeSubmit={handleOnChangeSubmit}
            inputProps={{
              'data-cy': 'case-requestFrom-Individual-autocomplete',
            }}
            tenantId={tenantId}
          />
        )}
        {showGroupAdminUserAutoComplete() && (
          <GroupAdminUserAutocomplete
            clientId={tenantId}
            handleOnChangeSubmit={handleOnChangeSubmit}
            inputProps={{
              'data-cy': 'case-requestFrom-group-autocomplete',
            }}
          />
        )}
      </Box>
      <Box
        alignItems="flex-end"
        display="flex"
        justifyContent="center"
        width="10%"
      >
        <IconButton onClick={handleCancelAssignUser} size="small">
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: theme.palette.secondary.main }}
          />
        </IconButton>
      </Box>
    </Box>
  );
  const renderAssignedTo = () => {
    if (data?.assignedTo?.name && !changeAssignedTo) {
      return hoverStackBox;
    }
    if (!isFromTypeClient) {
      return autoCompleteBox;
    }
    return '';
  };
  return (
    <Box display="flex">
      <Avatar
        alt="Logo"
        src={data?.logoUrl}
        sx={{
          width: isSmallerThanSm ? 40 : AVATAR_WIDTH,
          height: isSmallerThanSm ? 40 : AVATAR_HEIGHT,
          border: (t) => `1px solid ${t.palette.gray.light}`,
          img: {
            objectFit: 'contain',
          },
        }}
      />

      <Stack display="flex" pl={4} width={`calc(100% - ${AVATAR_WIDTH})`}>
        <Typography gutterBottom={false} variant="body2">
          Created by
        </Typography>
        <Typography color="gray.darker" fontWeight="medium" variant="body1">
          {data?.name}
        </Typography>

        <Stack spacing={2}>
          <Stack alignItems="center" direction="row" display="flex" spacing={2}>
            {!isFromTypeClient && (
              <FontAwesomeIcon
                icon={faUserTie}
                style={{ color: theme.palette.primary.main }}
              />
            )}

            {renderAssignedTo()}
          </Stack>

          <StackIconAndTypography
            icon={faMobileAlt}
            label={data ? formatPhone(getRequestedPhoneNumber(data)) : 'N/A'}
          />
          <StackIconAndTypography
            icon={faEnvelope}
            label={adaptEmail || 'N/A'}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default RequestedFrom;
