import { useDispatch } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';
import { useScreenSize } from 'hooks/useScreenSize';
import { ITenantUser } from 'interfaces/chat';
import {
  selectActiveTenantList,
  setActiveTenantList,
  setCloseWindow,
  setRemoveUnreadMessage,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import { getMaximumThresholdWidth } from 'utils/chat';
import { getFullName } from 'utils/common';

import TenantAvatar from './Avatar';

const ChatListItem = ({ item }: { item: ITenantUser }) => {
  const dispatch = useDispatch();
  const activeTenantUser = useAppSelector(selectActiveTenantList);
  const { width } = useScreenSize();
  const isMobile = window.innerWidth <= 768;

  return (
    <Box
      key={item.userId}
      p={1}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        key={item.userId}
        my={2}
        onClick={() => {
          if (isMobile) {
            dispatch(setCloseWindow(true));
          }
          dispatch(setActiveTenantList(item));

          if (item?.unreadMessagesCount) {
            dispatch(setRemoveUnreadMessage(item.userId));
          }
          // When the multiple chat windows exceedds the size of viewport than automatically remove the first user
          getMaximumThresholdWidth(
            activeTenantUser.length,
            width,
            isMobile ? 200 : 0
          );
        }}
        width="100%"
      >
        <Box display="flex" flexDirection="row" width="100%">
          <Box mr={2}>
            <TenantAvatar item={item} />
          </Box>
          <Box display="flex" flex={1} flexDirection="column" m={0} p={0}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" m={0} p={0}>
                <Typography
                  fontWeight="medium"
                  gutterBottom={false}
                  variant="caption"
                >
                  {`${getFullName(
                    item.demographic?.firstName,
                    item.demographic?.lastName
                  )}` ?? ''}
                </Typography>
              </Box>

              {item.lastMessage?.sentAt && (
                <Typography gutterBottom={false} variant="caption">
                  {item.lastMessage.sentAt}
                </Typography>
              )}
            </Box>
            {item.isFriend ? (
              <Box m={0} p={0} width={200}>
                {item.lastMessage?.text && (
                  <Typography
                    fontWeight={
                      item?.unreadMessagesCount && !item.lastMessage?.isSentByMe
                        ? 'medium'
                        : 'normal'
                    }
                    gutterBottom={false}
                    noWrap
                    overflow="hidden"
                    textOverflow="ellipsis"
                    variant="body2"
                  >
                    {item.lastMessage?.isSentByMe && 'You:'}{' '}
                    {item.lastMessage.text}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box m={0} p={0} width={220}>
                <Typography
                  gutterBottom={false}
                  noWrap
                  overflow="hidden"
                  textOverflow="ellipsis"
                  variant="body2"
                >
                  {item.demographic?.email ?? ''}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default ChatListItem;
