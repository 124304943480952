import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { Popover } from 'common';

const ExcelExport = ({
  onExportCurrentListAsExcelClick,
  onExportAsExcelClick,
  onExportAllClick,
  exportAllButton,
}: {
  onExportCurrentListAsExcelClick?: VoidFunction;
  onExportAsExcelClick?: VoidFunction;
  onExportAllClick?: VoidFunction;
  exportAllButton?: boolean;
}) =>
  exportAllButton ? (
    <Button
      onClick={onExportAllClick}
      startIcon={<FontAwesomeIcon icon={faFileExport} />}
      variant="outlined"
    >
      Export
    </Button>
  ) : (
    <Popover
      buttonIcon={<FontAwesomeIcon icon={faFileExport} />}
      buttonText="Export"
      isIconButton={false}
      sx={{ p: 0 }}
    >
      <List className="export-menu-list">
        {onExportCurrentListAsExcelClick && (
          <ListItem disablePadding onClick={onExportCurrentListAsExcelClick}>
            <ListItemButton>
              <ListItemIcon>
                <FontAwesomeIcon icon={faFileExport} />
              </ListItemIcon>
              <Typography gutterBottom={false} variant="body2">
                Export Current List
              </Typography>
            </ListItemButton>
          </ListItem>
        )}
        {onExportAsExcelClick && (
          <ListItem disablePadding onClick={onExportAsExcelClick}>
            <ListItemButton>
              <ListItemIcon>
                <FontAwesomeIcon icon={faFileExport} />
              </ListItemIcon>
              <Typography gutterBottom={false} variant="body2">
                Export List
              </Typography>
            </ListItemButton>
          </ListItem>
        )}
        {onExportAllClick && (
          <ListItem disablePadding onClick={onExportAllClick}>
            <ListItemButton>
              <ListItemIcon>
                <FontAwesomeIcon icon={faFileExport} />
              </ListItemIcon>
              <Typography gutterBottom={false} variant="body2">
                Export All (10,000)
              </Typography>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Popover>
  );

ExcelExport.defaultProps = {
  onExportCurrentListAsExcelClick: undefined,
  onExportAsExcelClick: undefined,
  onExportAllClick: undefined,
  exportAllButton: false,
};

export default ExcelExport;
