export enum EmailType {
  TAG_NOTE = 'TAG_NOTE',
  SHARE_CASE = 'SHARE_CASE',
  SHARE_CASE_FILE = 'SHARE_CASE_FILE',
  NOTIFY_TASK_REMINDER = 'NOTIFY_TASK_REMINDER',
}

export enum EmailUserType {
  PATIENT = 'PATIENT',
  ADMIN = 'ADMIN',
  THIRD_PARTY = 'THIRD_PARTY',
  TENANT = 'TENANT',
  AGENT = 'AGENT',
}

export enum Module {
  CASE = 'CASE',
  TASK = 'TASK',
}
