import { Controller, useFormContext } from 'react-hook-form';

import { Grid, TextField } from '@mui/material';
import { CountryCodes } from 'enums/country';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { IAddress } from 'interfaces/common';
import { checkIfObjHasAllEmptyOrNullValues } from 'utils/common';
import { getInputLabel } from 'utils/misc';

import FormGoogleAutocompleteInput from './FormGoogleAutocompleteInput';
import FormTextField from './FormTextField';

interface IAddressForm {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  lat: string | number;
  lng: string | number;
  countryCode?: string;
}

interface IProps {
  isRequired?: boolean;
  allDisabled?: boolean;
  isConfigurableThroughSetting?: boolean;
}

const AddressInput = ({
  isRequired = false,
  allDisabled = false,
  isConfigurableThroughSetting,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<IAddressForm>();

  const onPopulateFields = (addr: IAddress) => {
    if (checkIfObjHasAllEmptyOrNullValues(addr)) return;
    setValue('addressLine1', addr.addressLine1?.trim());
    setValue('addressLine2', addr.addressLine2?.trim());
    setValue('city', addr.city);
    setValue('state', addr.state);
    setValue('zip', addr.zip);
    setValue('lat', addr.lat);
    setValue('lng', addr.lng);
  };

  const countryCode = watch('countryCode');

  const adaptCountryCode = !countryCode ? CountryCodes.USA : countryCode;
  const isCountrySelectedUS = adaptCountryCode === CountryCodes.USA;

  const isCountrySelectedUSorCanada =
    isCountrySelectedUS || adaptCountryCode === CountryCodes.CANADA;
  const checkRequiredField = useCheckFormFieldSettings().required;
  const isFieldRequired = isConfigurableThroughSetting
    ? checkRequiredField?.address
    : isRequired;

  return (
    <>
      <Grid container mb={4} spacing={4}>
        <Grid item mt={4} xs={6}>
          {isCountrySelectedUSorCanada ? (
            <FormGoogleAutocompleteInput
              countryCode={adaptCountryCode || ''}
              inputProps={{ 'data-cy': 'address-line-1' }}
              isDisabled={allDisabled}
              isRequired={isFieldRequired}
              label={
                isCountrySelectedUS
                  ? 'Address Line 1'
                  : 'Street address/ P.O box'
              }
              onPopulateFields={onPopulateFields}
            />
          ) : (
            <Controller
              control={control}
              name="addressLine1"
              render={({ field }) => (
                <TextField
                  inputProps={{
                    'data-cy': 'address-line-1',
                  }}
                  {...field}
                  disabled={allDisabled}
                  error={!!errors.addressLine2}
                  fullWidth
                  label={
                    isCountrySelectedUS
                      ? `Address Line 1 ${isFieldRequired ? ' *' : ''}`
                      : `Street address/ P.O box ${isFieldRequired ? '*' : ''}`
                  }
                  size="small"
                  variant="standard"
                />
              )}
            />
          )}
        </Grid>
        <Grid item mt={2} xs={6}>
          <Controller
            control={control}
            name="addressLine2"
            render={({ field }) => (
              <TextField
                inputProps={{
                  'data-cy': 'address-line-2',
                }}
                {...field}
                disabled={allDisabled}
                error={!!errors.addressLine2}
                fullWidth
                label={
                  isCountrySelectedUS
                    ? `Address Line 2 ${isRequired ? ' *' : ''}`
                    : `Building/ Unit  ${isRequired ? ' *' : ''} `
                }
                size="small"
                variant="standard"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextField
                inputProps={{
                  'data-cy': 'city',
                }}
                {...field}
                disabled={allDisabled}
                error={!!errors.city}
                fullWidth
                label={getInputLabel('City', isFieldRequired)}
                size="small"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <TextField
                inputProps={{
                  'data-cy': 'state',
                }}
                {...field}
                className="auto-min-width"
                disabled={allDisabled}
                error={!!errors.state}
                fullWidth
                label={getInputLabel(
                  isCountrySelectedUS ? 'State' : 'State/ Province/ Region',
                  isFieldRequired
                )}
                size="small"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          {/* <FormMaskedZipInput
            className="auto-min-width"
            disabled={allDisabled}
            isRequired={isRequired}
          /> */}
          <FormTextField
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'zip',
            }}
            label={getInputLabel(
              isCountrySelectedUS ? 'Zip' : 'Postal Code/ PinCode',
              isFieldRequired
            )}
            name="zip"
          />
        </Grid>
      </Grid>
    </>
  );
};

AddressInput.defaultProps = {
  isRequired: false,
  allDisabled: false,
  isConfigurableThroughSetting: false,
};

export default AddressInput;
