import { useNavigate } from 'react-router-dom';

import { faFileText } from '@fortawesome/free-regular-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography, useTheme } from '@mui/material';
import PermissionGate from 'common/PermissionGate';
import ModalKey from 'enums/ModalKey';
import {
  selectCurrentReferralCase,
  selectCurrentReferralCaseDocuments,
} from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import FileList from './FileList';

const Files = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const currentReferralCaseDocuments = useAppSelector(
    selectCurrentReferralCaseDocuments
  );

  const handleOnClick = () => {
    navigate(
      `modal?type=${ModalKey.UPLOAD_CASE_FILE}&caseId=${currentReferralCase?.caseId}&patientId=${currentReferralCase?.patient?.id}`
    );
  };

  const uploadButton = (
    <Box padding={4}>
      <PermissionGate>
        <Button
          data-cy="case-upload-files"
          fullWidth
          onClick={handleOnClick}
          size="medium"
          startIcon={<FontAwesomeIcon icon={faUpload} />}
          variant="outlined"
        >
          Upload Files
        </Button>
      </PermissionGate>
    </Box>
  );

  return (
    <Box>
      <Box className="scrollbar" height="calc(100vh - 200px)" overflow="auto">
        <Box paddingTop={2} paddingX={4}>
          {currentReferralCaseDocuments?.length ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {currentReferralCaseDocuments?.map((e: any) => (
                <FileList data={e} key={e.documentId} />
              ))}
            </>
          ) : (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              height="50vh"
              justifyContent="center"
              paddingY={4}
            >
              <FontAwesomeIcon
                icon={faFileText}
                size="5x"
                style={{ color: theme.palette.primary.main }}
              />

              <Typography sx={{ marginTop: 4 }} variant="h5">
                Upload files
              </Typography>
              <Typography sx={{ marginTop: 4 }} variant="body2">
                You can upload Files for your case
              </Typography>
              <Box width="100%">{uploadButton}</Box>
            </Box>
          )}
        </Box>
      </Box>
      {currentReferralCaseDocuments?.length ? (
        <Box
          sx={{
            position: 'absolute',
            bottom: '-78px',
          }}
          width="100%"
        >
          {uploadButton}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default Files;
