import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Typography } from '@mui/material';

interface IProps {
  containerHeight?: string;
  size?: string;
  icon?: IconDefinition;
  iconContainerSize?: number;
  label?: string | JSX.Element;
}

const LoadingIndicator = ({
  containerHeight,
  size,
  icon,
  iconContainerSize,
  label,
}: IProps) => {
  if (icon) {
    return (
      <Box
        sx={{
          width: '100%',
          height: containerHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box m={2} position="relative">
          <Box
            sx={{
              backgroundColor: 'gray.lighter',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: iconContainerSize,
              height: iconContainerSize,
              borderRadius: '50%',
            }}
          >
            <Box
              color="primary.main"
              component={FontAwesomeIcon}
              height="50%"
              icon={icon}
              width="50%"
            />
          </Box>
          <CircularProgress
            size={iconContainerSize}
            sx={{
              color: (theme) => theme.palette.gray.main,
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            thickness={2}
          />
        </Box>
        {label && (
          <Typography mt={6} textAlign="center" variant="body2">
            {label}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: containerHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        size={size}
        sx={{
          color: (theme) => theme.palette.gray.main,
        }}
      />
      {label && (
        <Typography mt={6} textAlign="center" variant="body2">
          {label}
        </Typography>
      )}
    </Box>
  );
};

LoadingIndicator.defaultProps = {
  containerHeight: '20rem',
  size: '2rem',
  icon: undefined,
  iconContainerSize: 100,
  label: '',
};

export default LoadingIndicator;
