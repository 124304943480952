import commonConstants from 'constants/common';
import { Status } from 'enums/common';
import { LookUpCategory, LookUpCode } from 'enums/lookups';
import MaintenancePage from 'features/misc/maintenance';
import { useLookUpQuery } from 'services/lookups';
import { checkIsMaintenanceModeBypassCodeEntered } from 'utils/misc';

import LoadingIndicator from './LoadingIndicator';

interface Props {
  children: React.ReactNode;
}

export const MaintenanceModeWrapper = ({ children }: Props) => {
  const isMaintenanceModeBypassCodeEntered =
    checkIsMaintenanceModeBypassCodeEntered();
  const { data, isLoading } = useLookUpQuery(
    {
      category: LookUpCategory.MAINTENANCE_MODE,
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
    },
    { enabled: true }
  );

  const maintenanceMode = data?.find(
    (item) => item.code === LookUpCode.VITAFY_PORTAL
  )?.status;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (
    maintenanceMode === Status.ACTIVE.toUpperCase() &&
    !isMaintenanceModeBypassCodeEntered
  ) {
    return <MaintenancePage />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
