import { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import DatePicker from 'common/DatePicker';
import { useSetValidateDateForMembership } from 'common/manage-membership/hooks/useSetValidateDateForMembership';
import config from 'config';
import {
  selectManageMembershipInvalidSelectedDate,
  selectManageMembershipSelectedMembership,
  setManageMembershipInvalidSelectedDate,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { formatDateView, getMomentDate, isValidDate } from 'utils/moment';

export const ChooseStartDate = () => {
  const dispatch = useAppDispatch();

  const { startDate, selectedOffering } = useAppSelector(
    selectManageMembershipSelectedMembership
  );

  const invalidSelectedDate = useAppSelector(
    selectManageMembershipInvalidSelectedDate
  );

  const handleSelectedMembership = (date: string) => {
    if (!date) {
      dispatch(setManageMembershipInvalidSelectedDate(true));
      dispatch(setManageMembershipSelectedOfferings({ startDate: '' }));
    } else {
      dispatch(setManageMembershipInvalidSelectedDate(!isValidDate(date)));
      const formattedDate = formatDateView(date as string) || '';
      dispatch(
        setManageMembershipSelectedOfferings({ startDate: formattedDate })
      );
    }
  };

  useSetValidateDateForMembership();

  const uniqueKeyToRerenderDatepicker = `${
    selectedOffering?.tenantOfferingId
  }-${startDate || 'Invalid date'}`;

  /**
   * Reset the datepicker error when the datepicker re-renders
   * i.e. when either selectedOffering or startDate changes.
   */
  useEffect(() => {
    dispatch(setManageMembershipInvalidSelectedDate(false));
  }, [uniqueKeyToRerenderDatepicker, dispatch]);

  return (
    <Box>
      <Grid
        alignItems="center"
        container
        display="flex"
        justifyContent="space-between"
        padding={4}
        paddingBottom={4}
        paddingTop={1}
        spacing={4}
      >
        <Grid item>
          <Typography fontWeight="medium" variant="body2">
            When would you like to start membership?
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            <DatePicker
              handleChange={(date) => handleSelectedMembership(date as string)}
              inputFormat={config.dateViewFormat}
              inputProps={{
                variant: 'standard',
                'data-cy': 'client-modifyMembership-start-membership-date',
                InputLabelProps: {
                  shrink: true,
                },
                autoFocus: true,
                error: !!invalidSelectedDate,
              }}
              inputStyle={{ width: '240px', marginLeft: 2 }}
              // Datepicker component should be re-rendered when either selectedOffering or startDate changes
              key={uniqueKeyToRerenderDatepicker}
              label="Start Date"
              minDate={
                selectedOffering?.startDate
                  ? getMomentDate(selectedOffering?.startDate)
                  : undefined
              }
              onError={(e) => {
                if (e) {
                  dispatch(setManageMembershipInvalidSelectedDate(true));
                } else {
                  dispatch(setManageMembershipInvalidSelectedDate(false));
                }
              }}
              value={startDate || ''}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
