import { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import ClientAutocomplete from 'common/autocomplete/ClientAutocomplete';
import LoadingIndicator from 'common/LoadingIndicator';
import { useCheckGroupMembership } from 'common/manage-membership/hooks/useCheckGroupMembership';
import { useEffectChangeMembershipDetailsForGroup } from 'common/manage-membership/hooks/useEffectChangeMembershipDetailsForGroup';
import { useGetCurrentSelectedClientAndMembershipDetails } from 'common/manage-membership/hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionType } from 'common/manage-membership/hooks/useGetManageMembershipActionType';
import UserType from 'enums/User';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IAdaptedClient } from 'interfaces/client-management';
import { useClientDetailQuery } from 'services/client-management';
import { selectAuthTenantData } from 'stores/auth';
import {
  resetManageMembershipSelectedOfferings,
  selectManageMembershipTypeGroupDetail,
  setManageMembershipTypeGroupDetail,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import { GroupMembershipList } from './GroupMembershipList';

export const GroupMembership = () => {
  const dispatch = useAppDispatch();

  const tenantData = useAppSelector(selectAuthTenantData);

  const loggedInUserClientId = tenantData?.tenantAssociation.referenceId;
  const isUserClient = useCheckUserIsClient();

  const { isEditMembership } = useGetManageMembershipActionType();

  const { isClientTypeGroup, clientData, loggedInUserAsGroup } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const selectedGroupDetail = useAppSelector(
    selectManageMembershipTypeGroupDetail
  );

  const { groupMembership } = useCheckGroupMembership();

  const handleSelectClient = (e: unknown) => {
    if (e === null) {
      dispatch(resetManageMembershipSelectedOfferings());
    }
    dispatch(setManageMembershipTypeGroupDetail(e as IAdaptedClient | null));
  };

  const clientId = useMemo(() => {
    if (!isUserClient) return '';
    if (loggedInUserAsGroup) return loggedInUserClientId;
    return groupMembership?.groupId;
  }, [
    groupMembership?.groupId,
    loggedInUserAsGroup,
    loggedInUserClientId,
    isUserClient,
  ]);

  const { data: groupDetail, isLoading } = useClientDetailQuery(clientId!, {
    enabled: !!clientId,
  });

  useEffect(() => {
    if (groupDetail) {
      dispatch(setManageMembershipTypeGroupDetail(groupDetail));
    }
  }, [dispatch, groupDetail]);

  useEffect(() => {
    if (isClientTypeGroup) {
      dispatch(setManageMembershipTypeGroupDetail(clientData));
    }
  }, [clientData, dispatch, isClientTypeGroup]);

  // Clears out the selected offering if its not of category of group offering
  useEffectChangeMembershipDetailsForGroup();

  const isGroupAutocompleteDisabled = useMemo(() => {
    if (!isUserClient) return isEditMembership;
    return (
      isEditMembership || loggedInUserAsGroup || !!groupMembership?.groupId
    );
  }, [groupMembership, isEditMembership, isUserClient, loggedInUserAsGroup]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <Box paddingBottom={4} paddingTop={0} paddingX={4}>
      {!isClientTypeGroup && (
        <ClientAutocomplete
          autoFocusEnabled
          clientId={selectedGroupDetail?.clientId}
          disabled={isGroupAutocompleteDisabled}
          eligibleForEnrollment
          inputProps={{
            'data-cy': 'select-group-input',
            label: `Select Group`,
            placeholder: '',
            size: 'small',
          }}
          isAutoSelectHandler
          onSelectHandler={handleSelectClient}
          textFieldVariant="standard"
          userType={UserType.GROUP}
        />
      )}

      <GroupMembershipList />
    </Box>
  );
};
