import {
  faArrowsRotate,
  faEdit,
  faWarning,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import EllipseMenu from 'common/ui/menu/EllipseMenu';
import EllipseMenuItem from 'common/ui/menu/EllipseMenuItem';
import {
  ChangeMembershipActionType,
  ClientEnrollmentStatus,
} from 'enums/client-management';
import { ManageMembershipActionType } from 'enums/client-management/manage-membership';
import { useCheckPermissionToModifyMembership } from 'hooks/useCheckPermissionToModifyMembership';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import {
  setManageMembershipActionType,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch } from 'stores/hooks';
import { capitalizeFirstLetter } from 'utils/common';
import { checkIfDateisInFuture, getCurrentDate } from 'utils/moment';

import { EditableBenefitStartDate } from './editable-date/EditableBenefitStartDate';
import { useGetCancelOrCompleteAction } from './hooks/useGetCancelOrCompleteAction';

export const ManageMembershipListDetail = ({
  data,
  excludeActions,
  isSubmittedMembershipIsMoreThanOne,
}: {
  data: IAdaptedClientEnrollmentMembership;
  excludeActions?: boolean;
  isSubmittedMembershipIsMoreThanOne: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isUserClient = useCheckUserIsClient();
  const isModifyMembershpAllowed = useCheckPermissionToModifyMembership();
  const isUserClientGroup = useCheckUserIsClientGroup();
  const { isCompleteMembershipEnabled } = useGetCancelOrCompleteAction();

  const isStatusSubmitted = data.status === ClientEnrollmentStatus.SUBMITTED;

  const action = data.actions;

  const isStatusChangeOnCancelled =
    (data.status === ClientEnrollmentStatus.CANCELLED ||
      data.status === ClientEnrollmentStatus.COMPLETED) &&
    action.includes(ChangeMembershipActionType.CHANGE);

  const isBenefitStartDateInFuture = checkIfDateisInFuture(
    data.startDate || ''
  );

  const showSubmittedWarningSign =
    isSubmittedMembershipIsMoreThanOne && isStatusSubmitted;
  function checkEmptyActions() {
    if (isUserClient && !isUserClientGroup) {
      return true;
    }

    const hasSingleChangeAction =
      action.length === 1 && action.includes(ChangeMembershipActionType.CHANGE);
    const noActionsAndNotSubmitted = !action.length && !isStatusSubmitted;
    const singleChangeOnCancelled =
      hasSingleChangeAction && !isStatusChangeOnCancelled;

    return noActionsAndNotSubmitted || singleChangeOnCancelled;
  }

  const onApprovePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(setManageMembershipSelectedOfferings({ details: item }));
    dispatch(setManageMembershipActionType(ManageMembershipActionType.APPROVE));
  };

  const onEditClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(
      setManageMembershipSelectedOfferings({
        details: item,
        startDate: item.startDate || getCurrentDate(),
      })
    );

    dispatch(
      setManageMembershipActionType(ManageMembershipActionType.EDIT_MEMBERSHIP)
    );
  };

  const onChangePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    if (item.endDate) {
      dispatch(
        setManageMembershipSelectedOfferings({
          details: item,
          startDate: item.startDate,
          endDate: item.endDate,
        })
      );
    } else {
      dispatch(
        setManageMembershipSelectedOfferings({
          details: item,
          startDate: item.startDate,
        })
      );
    }
    dispatch(setManageMembershipActionType(ManageMembershipActionType.CHANGE));
  };

  const onCompletePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(
      setManageMembershipSelectedOfferings({
        details: item,
        startDate: item.startDate,
      })
    );
    dispatch(
      setManageMembershipActionType(ManageMembershipActionType.COMPLETE)
    );
  };

  const onCancelPlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(
      setManageMembershipSelectedOfferings({
        details: item,
        startDate: item.startDate,
      })
    );
    dispatch(setManageMembershipActionType(ManageMembershipActionType.CANCEL));
  };

  const onDeclinePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(setManageMembershipSelectedOfferings({ details: item }));
    dispatch(setManageMembershipActionType(ManageMembershipActionType.DECLINE));
  };

  const isOfferingSelected = !!data.offerings.id;

  const totalCost =
    data.formattedTotalCost === '$0.00' ? '-' : data.formattedTotalCost;

  return (
    <TableRow key={data.clientEnrollmentId} sx={{ cursor: 'default' }}>
      <TableCell className="vertical-align-top" width={240}>
        <Box minWidth={220}>
          <Typography
            data-cy="client-manageMembership-offering-name"
            fontWeight="medium"
            variant="body2"
          >
            {data.offerings.name || '-'}
          </Typography>
          <Typography
            color="gray.dark"
            data-cy="client-manageMembership-offering-relationship"
            gutterBottom={false}
            variant="caption"
          >
            {data?.relationship ? `${data?.relationship as string} of ` : ''}
            {data.groupName || data.primaryName || data.client.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className="vertical-align-top" width={130}>
        <Box minWidth={130}>
          <Typography fontWeight="medium" variant="body2">
            {totalCost}
          </Typography>
          <Typography
            color="gray.dark"
            data-cy="client-manageMembership-billing-type"
            gutterBottom={false}
            variant="caption"
          >
            {`${capitalizeFirstLetter(data.formattedBillingType)} ${
              data.formattedBillingInterval
            }`}
            {+data.offerings.registrationFee > 0 && (
              <>
                <br />
                Registration Fee: {data.formattedRegistrationFee}
              </>
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className="vertical-align-top">
        {}
        <EditableBenefitStartDate
          data={data}
          excludeActions={
            excludeActions || !isModifyMembershpAllowed || !isOfferingSelected
          }
        />
      </TableCell>
      <TableCell className="vertical-align-top">
        <Box display="flex" minWidth={86}>
          <Typography
            data-cy="client-manageMembership-status"
            fontWeight="medium"
            gutterBottom={false}
            variant="body2"
          >
            {data.status}
          </Typography>
          {showSubmittedWarningSign && (
            <Box
              color="error.dark"
              component={FontAwesomeIcon}
              icon={faWarning}
              pl={1}
            />
          )}
        </Box>
      </TableCell>
      {!excludeActions && (
        <TableCell>
          <Stack
            direction="row"
            display="flex"
            justifyContent="end"
            spacing={2}
          >
            {(!isModifyMembershpAllowed || !action.length) && (
              <Box data-cy="client-modifyMembership-empty" />
            )}
            {isModifyMembershpAllowed &&
              isOfferingSelected &&
              action.includes(ChangeMembershipActionType.APPROVE) && (
                <Button
                  data-cy="client-modifyMembership-approve"
                  onClick={() => onApprovePlanClick(data)}
                  size="small"
                  variant="outlined"
                  // startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                >
                  Approve
                </Button>
              )}

            {!checkEmptyActions() && (
              <EllipseMenu>
                {isStatusSubmitted && (
                  <EllipseMenuItem
                    dataCy="client-modifyMembership-edit"
                    iconElement={<FontAwesomeIcon icon={faEdit} size="xs" />}
                    onClick={() => onEditClick(data)}
                    text="Change"
                  />
                )}

                {isModifyMembershpAllowed && isStatusChangeOnCancelled && (
                  <EllipseMenuItem
                    dataCy="client-modifyMembership-resume"
                    iconElement={
                      <FontAwesomeIcon icon={faArrowsRotate} size="xs" />
                    }
                    onClick={() => onChangePlanClick(data)}
                    text="Resume"
                  />
                )}
                {isModifyMembershpAllowed &&
                  action.includes(ChangeMembershipActionType.DECLINE) && (
                    <EllipseMenuItem
                      data-cy="client-modifyMembership-decline"
                      iconElement={<FontAwesomeIcon icon={faXmark} size="xs" />}
                      onClick={() => onDeclinePlanClick(data)}
                      text="Decline"
                    />
                  )}
                {isModifyMembershpAllowed &&
                  (action.includes(ChangeMembershipActionType.CANCEL) ||
                    action.includes(ChangeMembershipActionType.COMPLETE)) && (
                    <EllipseMenuItem
                      data-cy={`client-modifyMembership-${
                        isCompleteMembershipEnabled
                          ? ChangeMembershipActionType.COMPLETE
                          : ChangeMembershipActionType.CANCEL
                      }`}
                      iconElement={<FontAwesomeIcon icon={faXmark} size="xs" />}
                      onClick={() =>
                        isBenefitStartDateInFuture
                          ? onCancelPlanClick(data)
                          : onCompletePlanClick(data)
                      }
                      text={
                        isCompleteMembershipEnabled
                          ? ChangeMembershipActionType.COMPLETE
                          : ChangeMembershipActionType.CANCEL
                      }
                    />
                    // <Button
                    //   data-cy={`client-modifyMembership-${
                    //     isCompleteMembershipEnabled
                    //       ? ChangeMembershipActionType.COMPLETE
                    //       : ChangeMembershipActionType.CANCEL
                    //   }`}
                    //   onClick={() =>
                    //     isBenefitStartDateInFuture
                    //       ? onCancelPlanClick(data)
                    //       : onCompletePlanClick(data)
                    //   }
                    //   size="small"
                    //   startIcon={<FontAwesomeIcon icon={faXmark} />}
                    // >
                    //   {isCompleteMembershipEnabled
                    //     ? ChangeMembershipActionType.COMPLETE
                    //     : ChangeMembershipActionType.CANCEL}
                    // </Button>
                  )}
              </EllipseMenu>
            )}
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
};

ManageMembershipListDetail.defaultProps = {
  excludeActions: false,
};
