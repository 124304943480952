import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import {
  checkCasePermission as checkCasePermissionAPI,
  getCase,
} from 'api/cases';
import LoadingIndicator from 'common/LoadingIndicator';
import { sessionStorageKey } from 'constants/localStorage';
import { ReferenceType } from 'enums/common';
import { useGetParamsForTenantSwitch } from 'hooks/useGetParamsForTenantSwitch';
import { useGetTenantAssociationOfLoggedInUser } from 'hooks/useGetTenantAssociationOfLoggedInUser';
import { useSnackbar } from 'notistack';
import { useDecodeOTP } from 'services/otp';
import { useSwitchTenantMutation } from 'services/tenant-management/tenant';
import { useAppDispatch } from 'stores/hooks';
import { setAuthState } from 'utils/auth';
import { clearLocal, clearSession, setSession } from 'utils/storage';

interface IOTPLoginRedirectWrapperProps {
  redirectPath: string;
  children: JSX.Element;
  hasMenuAccess: boolean;
  caseId?: string | null;
}

/**
 * Checks if the user is already logged in before showing OTP verification screen.
 *
 * If the user is tenant admin and has access to the tenant, no need to show OTP verification screen, just redirect
 * to the required route.
 *
 * if not, show the OTP verification screen.
 *
 *
 */
export const PreOTPAuthenticateWrapper = ({
  redirectPath,
  hasMenuAccess,
  children,
  caseId,
}: IOTPLoginRedirectWrapperProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const codeLink = searchParams.get('link');

  const { mutate: switchTenantMutate, isError: isTenantSwitchError } =
    useSwitchTenantMutation();
  const { getParamsForTenantSwitch } = useGetParamsForTenantSwitch();
  const getParamsForTenantSwitchRef = useRef(getParamsForTenantSwitch);

  const { mutate: decodeOTPMutate, isError: isOTPDecodeError } = useDecodeOTP();
  const { isLoggedIn, authUser, activeAssociation, associations } =
    useGetTenantAssociationOfLoggedInUser();

  const [loginNotFeasible, setLoginNotFeasible] = useState(false);
  const [casePermission, setCasePermission] = useState({
    hasPermission: false,
    permissionLoading: true,
  });

  const switchTenant = useCallback(
    ({
      newTenantId,
      userId,
      redirectRoute,
    }: {
      newTenantId: string;
      userId: string;
      redirectRoute: string;
    }) => {
      const mutationParams = getParamsForTenantSwitchRef.current({
        newTenantId,
      });
      switchTenantMutate(
        {
          userId,
          tenantId: newTenantId,
          params: mutationParams,
        },
        {
          onSuccess: (response) => {
            if (response.success) {
              clearLocal();
              clearSession();

              setAuthState({
                token: response.data.token,
                permissions: response.data.permissions,
                dispatch,
              });

              navigate(redirectRoute);
              window.location.reload();
            }
          },
        }
      );
    },
    [switchTenantMutate, dispatch, navigate]
  );

  const checkCasePermission = useCallback(
    async ({
      decodedData,
      caseIdLocal,
    }: {
      decodedData: any;
      caseIdLocal: string;
    }) => {
      const isReceiverTenantAdmin =
        decodedData.data?.userReference?.referenceType === ReferenceType.TENANT;
      const isReceiverClient =
        decodedData.data?.userReference?.referenceType ===
          ReferenceType.CLIENT &&
        // referenceId should not contain email, else => third party or client without user login
        // TODO: Need to differentiate by type rather than this method.
        !decodedData.data?.userReference?.referenceId?.includes('@');
      const isReceiverThirdParty = !isReceiverTenantAdmin && !isReceiverClient;
      const tenantId = isReceiverTenantAdmin
        ? decodedData.data?.userReference?.referenceId
        : decodedData.data?.tenantId;

      let checkPermissionResponse = {
        isReceiverTenantAdmin,
        isReceiverClient,
        isReceiverThirdParty,
        tenantId,
        hasPermission: false,
        permissionLoading: true,
        isCaseAccessNotRequired: false,
      };

      /**
       * DO NOT need to check if one has permission to view case or not
       * when one is trying to view the shared case.
       *
       * Condition: x.emailType === 'SHARED_CASE' && x.type === 'TAG_NOTE' is used because,
       * we don't have flag to distinguish 'FILE_SHARED' email (doing it in opposite way, need to refactor)
       *
       */
      const TYPES_THAT_NEED_CASE_ACCESS = ['SHARED_CASE', 'TAG_NOTE'];

      const isCaseAccessNotRequired = !TYPES_THAT_NEED_CASE_ACCESS.includes(
        decodedData.data.type
      );

      if (isReceiverClient || isCaseAccessNotRequired) {
        return {
          ...checkPermissionResponse,
          hasPermission: true,
          permissionLoading: false,
          isCaseAccessNotRequired,
        };
      }

      const params: any = {
        type: isReceiverTenantAdmin ? 'tenant' : 'thirdparty',
        tenantId: isReceiverTenantAdmin ? tenantId : undefined,
        email: isReceiverThirdParty
          ? encodeURIComponent(decodedData.data.email)
          : undefined,
      };

      try {
        const response = await checkCasePermissionAPI({
          caseId: caseIdLocal,
          params,
        });
        checkPermissionResponse = {
          ...checkPermissionResponse,
          hasPermission: response.data,
          permissionLoading: false,
        };
      } catch (err: any) {
        checkPermissionResponse = {
          ...checkPermissionResponse,
          hasPermission: false,
          permissionLoading: false,
        };
      }

      return checkPermissionResponse;
    },
    []
  );

  /**
   * This side effect is triggered when there is a login session present and
   * the user tries to open the link (in the email)
   *
   */
  useEffect(() => {
    if (
      codeLink &&
      isLoggedIn &&
      activeAssociation &&
      associations?.length &&
      caseId &&
      hasMenuAccess
    ) {
      decodeOTPMutate(codeLink!, {
        onSuccess: async (x) => {
          setSession(sessionStorageKey.decodedDataForOTP, x.data);

          const isLoggedInUserTenantAdmin =
            authUser?.tenantAssociation?.referenceType === ReferenceType.TENANT;

          const {
            isReceiverTenantAdmin,
            isReceiverClient,
            isReceiverThirdParty,
            tenantId,
            hasPermission,
            permissionLoading,
            isCaseAccessNotRequired,
          } = await checkCasePermission({
            decodedData: x,
            caseIdLocal: caseId,
          });

          /**
           * If the logged in user is not tenant admin (i.e the user is patient or third party),
           * and he does have access to view the case, show 'no access screen' and no need to proceed further.
           */
          if (!hasPermission && !isLoggedInUserTenantAdmin) {
            setCasePermission((prevState) => ({
              ...prevState,
              hasPermission,
              permissionLoading,
            }));
            return;
          }

          const response = await getCase(caseId);
          const caseAccess = response.data.access || [];

          /**
           * If the logged in user is a tenant admin, we need to check
           * 1. If any one of the tenants that the user is associated to has access to view the case,
           * 2. If not -> show 'no access screen' and no need to proceed further.
           */
          if (isLoggedInUserTenantAdmin) {
            const association = associations?.find(
              (item) => item.tenantId === tenantId
            );

            const hasAccess = association
              ? caseAccess?.some(
                  (item) =>
                    item.referenceType === ReferenceType.TENANT &&
                    item.referenceId === association?.tenantId &&
                    !item.revoked
                )
              : false;

            if (!hasAccess) {
              setCasePermission((prevState) => ({
                ...prevState,
                hasPermission: isCaseAccessNotRequired ? hasPermission : false,
                permissionLoading,
              }));
              return;
            }
          } else if (isReceiverTenantAdmin && !isLoggedInUserTenantAdmin) {
            /**
             * If the email is sent to tenant admin and if the logged in user is not a tenant admin,
             * (i.e. the loggedin user is client or third party), show 'no access' screen and no need to proceed further
             */
            setCasePermission((prevState) => ({
              ...prevState,
              hasPermission: false,
              permissionLoading,
            }));
            return;
          }

          /**
           * if above conditions for permission passed, update the state and proceed further.
           */
          setCasePermission((prevState) => ({
            ...prevState,
            hasPermission: true,
            permissionLoading: false,
          }));

          // Always show OTP screen for third party
          if (isReceiverThirdParty && !isLoggedInUserTenantAdmin) {
            setLoginNotFeasible(true);
            return;
          }

          // For client -> check if the currently logged in user
          // is in the fact the same client user, else -> show OTP screen
          if (isReceiverClient && !isLoggedInUserTenantAdmin) {
            // need to make the encrypted data in the link query param consistent.
            const isSameLoggedInClient =
              x.data?.userId === authUser?.userId ||
              x.data?.userId === authUser?.tenantAssociation?.referenceId ||
              x.data?.userReference?.referenceId ===
                authUser?.tenantAssociation?.referenceId;

            if (!isSameLoggedInClient || !x.data?.tenantId) {
              setLoginNotFeasible(true);
              return;
            }
          }

          /**
           * If we are viewing case for tenant admin (indicated by the presence of caseId),
           * we need to check whether the tenant have access to the case.
           */
          if (caseId && (isReceiverTenantAdmin || isLoggedInUserTenantAdmin)) {
            const doesTenantHaveAccessToCase = caseAccess?.some(
              (item) =>
                item.referenceType === ReferenceType.TENANT &&
                item.referenceId === tenantId &&
                !item.revoked
            );

            if (!doesTenantHaveAccessToCase) {
              setLoginNotFeasible(true);
              return;
            }
          }

          if (activeAssociation.tenantId === tenantId) {
            navigate(redirectPath);
            window.location.reload();
          } else if (
            associations?.some((item) => item.tenantId === tenantId) &&
            !!authUser?.userId
          ) {
            switchTenant({
              newTenantId: tenantId,
              userId: authUser.userId,
              redirectRoute: redirectPath,
            });
          } else {
            setLoginNotFeasible(true);
          }
        },
      });
    }
  }, [
    codeLink,
    decodeOTPMutate,
    isLoggedIn,
    associations,
    activeAssociation,
    authUser,
    navigate,
    switchTenant,
    redirectPath,
    hasMenuAccess,
    caseId,
    enqueueSnackbar,
    checkCasePermission,
  ]);

  /**
   * This side effect is triggered when there is NOT a login session present and
   * the user tries to open the link (in the email)
   *
   */
  useEffect(() => {
    if (!isLoggedIn && codeLink && caseId) {
      decodeOTPMutate(codeLink!, {
        onSuccess: async (x: any) => {
          setSession(sessionStorageKey.decodedDataForOTP, x.data);
          const { hasPermission, permissionLoading } =
            await checkCasePermission({
              decodedData: x,
              caseIdLocal: caseId,
            });

          setCasePermission((prevState) => ({
            ...prevState,
            hasPermission,
            permissionLoading,
          }));
        },
      });
    }
  }, [caseId, codeLink, isLoggedIn, decodeOTPMutate, checkCasePermission]);

  /**
   * Store all the query params in session storage except 'link' (link -> encrypted code)
   *
   * Usage:
   * 1. Disable case search if coming through email link for third party (checks query param: 'shareCase=true')
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    Array.from(params.entries()).forEach(([key, value]) => {
      if (key !== 'link') {
        setSession(key, value);
      }
    });
  }, []);

  if (!casePermission.hasPermission && !casePermission.permissionLoading) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" p={8}>
        <FontAwesomeIcon color="red" icon={faCircleXmark} size="2x" />
        <Box alignItems="center" display="flex" flexDirection="column" mt={6}>
          <Typography fontWeight="bold" variant="h6">
            Unauthorized Access
          </Typography>
          <Typography>
            You do not have access to view this information.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (casePermission.permissionLoading) {
    return <LoadingIndicator />;
  }

  if (
    !isLoggedIn ||
    loginNotFeasible ||
    isTenantSwitchError ||
    isOTPDecodeError ||
    !hasMenuAccess ||
    !casePermission.permissionLoading
  ) {
    return children;
  }

  return <LoadingIndicator />;
};

PreOTPAuthenticateWrapper.defaultProps = {
  caseId: undefined,
};
