import { useFormContext } from 'react-hook-form';

import { Box, Grid, Typography } from '@mui/material';
import { RadioBoxButton } from 'common/ui/RadioBoxButton';
import { DictionaryKeys } from 'constants/languageDictionary';
import { ClientType } from 'enums/client-management';
import { TenantOfferingCategory } from 'enums/settings/tenant-offerings';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import { useGetEnrollmentTenantSettings } from 'hooks/useGetEnrollmentTenantSettings';
import { useTenantTerminology } from 'hooks/useTenantTerminology';

import { useResetReferredByOnClientTypeChange } from './useResetReferredByOnClientTypeChange';

interface IProps {
  isDisabled: boolean;
}

const SelectClientType = ({ isDisabled }: IProps): JSX.Element => {
  const { watch, setValue } = useFormContext();

  const clientType = watch('clientType');

  const handleClick = (newOption: ClientType) => {
    setValue('clientType', newOption);
  };

  const enrollmentTenantSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.CLIENT_ENROLLMENT_TYPE
  ) as string[];

  const isEnrollmentTypeIndividual = enrollmentTenantSetting?.includes(
    TenantOfferingCategory.INDIVIDUAL
  );

  const isEnrollmentTypeGroup = enrollmentTenantSetting?.includes(
    TenantOfferingCategory.GROUP
  );
  const isEnrollmentTypeBoth =
    isEnrollmentTypeIndividual && isEnrollmentTypeGroup;

  useResetReferredByOnClientTypeChange();

  const terminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ]);

  return (
    <Box mb={4}>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Select {terminology?._PATIENT_TERMINOLOGY} Type
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={isEnrollmentTypeBoth ? 3 : 6}>
          {isEnrollmentTypeIndividual && (
            <RadioBoxButton
              data-cy="add-client-primary"
              isActive={clientType === ClientType.PRIMARY}
              isDisabled={!!isDisabled}
              label={ClientType.PRIMARY}
              onClick={() => handleClick(ClientType.PRIMARY)}
            />
          )}
        </Grid>
        <Grid item xs={isEnrollmentTypeBoth ? 3 : 6}>
          {isEnrollmentTypeIndividual && (
            <RadioBoxButton
              data-cy="add-client-dependent"
              isActive={clientType === ClientType.DEPENDENT}
              isDisabled={!!isDisabled}
              label={ClientType.DEPENDENT}
              onClick={() => handleClick(ClientType.DEPENDENT)}
            />
          )}
        </Grid>
        <Grid item xs={isEnrollmentTypeBoth ? 3 : 6}>
          {isEnrollmentTypeGroup && (
            <RadioBoxButton
              data-cy="add-client-group"
              isActive={clientType === ClientType.GROUP}
              isDisabled={!!isDisabled}
              label={ClientType.GROUP}
              onClick={() => handleClick(ClientType.GROUP)}
            />
          )}
        </Grid>
        <Grid item xs={isEnrollmentTypeBoth ? 3 : 6}>
          {isEnrollmentTypeGroup && (
            <RadioBoxButton
              data-cy="add-client-group-member"
              isActive={clientType === ClientType.GROUP_MEMBER}
              isDisabled={!!isDisabled}
              label={ClientType.GROUP_MEMBER}
              onClick={() => handleClick(ClientType.GROUP_MEMBER)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectClientType;
