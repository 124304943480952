import { Box, SxProps, Typography } from '@mui/material';

interface Props {
  boxSx?: SxProps;
  title: string;
  label: string;
  extraDetails?: JSX.Element;
  padding?: number;
}

const LabelBoxCard = ({
  boxSx,
  title,
  label,
  extraDetails,
  padding,
}: Props) => (
  <Box
    padding={padding}
    sx={{ background: (t) => t.palette.gray.lighter, ...boxSx }}
  >
    <Typography
      color="gray.darker"
      fontWeight="medium"
      gutterBottom={false}
      variant="body1"
    >
      {title}
    </Typography>
    <Typography color="text.secondary" variant="body2">
      {label}
    </Typography>
    {extraDetails && extraDetails}
  </Box>
);

LabelBoxCard.defaultProps = {
  boxSx: undefined,
  extraDetails: undefined,
  padding: 4,
};
export default LabelBoxCard;
