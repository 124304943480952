import { useMemo } from 'react';

import commonConstants from 'constants/common';
import { OfferingType } from 'enums/client-management';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { useTenantOfferingQuery } from 'services/tenant-management/tenant/tenantMembershipOffering';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckFetchingOfferingsEnabled } from './useCheckFetchingOfferingsEnabled';
import {
  getImmediateParentId,
  useGetCurrentSelectedClientAndMembershipDetails,
} from './useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionType } from './useGetManageMembershipActionType';

export const useGetTenantOfferings = () => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);
  const { clientData, membership } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const authTenantId = useMemo(() => {
    if (membership) {
      return membership.tenantId;
    }
    if (clientData) {
      return clientData.tenantId;
    }
    return authTenantAssociationData?.tenantId;
  }, [authTenantAssociationData?.tenantId, clientData, membership]);

  const { isIndividualOfferingEnabled } = useCheckFetchingOfferingsEnabled();

  const parentId = getImmediateParentId(membership);
  const clientId = clientData?.clientId;

  const { isAddNewMembership } = useGetManageMembershipActionType();

  const { data, isLoading } = useTenantOfferingQuery(
    authTenantId!,
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      type: OfferingType.MEMBERSHIP,
      recommended: true,
      ...(isAddNewMembership || !parentId
        ? {
            clientId: clientId!,
          }
        : {
            parentId,
            clientId: clientId!,
          }),
    },
    {
      enabled:
        !!authTenantId &&
        (!!clientId || !!parentId) &&
        isIndividualOfferingEnabled,
    }
  );

  return {
    data: (data?.rows || []) as IAdaptedTenantMembershipOfferingTableRow[],
    isLoading,
  };
};
