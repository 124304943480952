import { useRef } from 'react';

import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';

type ShareLinkProps = {
  link: string;
  hideCopyBtn?: boolean;
} & TextFieldProps;

const ShareLink = ({
  link,
  hideCopyBtn,
  ...textFieldProps
}: ShareLinkProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(link);
      enqueueSnackbar('Copied to clipboard!', {
        variant: 'success',
      });
    }
  };

  return (
    <Box alignItems="flex-end" display="flex" gap={2}>
      <TextField
        className="filled-variant"
        fullWidth
        id="share-link-input-id"
        InputProps={{
          readOnly: true,
        }}
        inputRef={inputRef}
        size="small"
        value={link}
        {...textFieldProps}
      />
      {!hideCopyBtn && (
        <Box>
          <Tooltip arrow placement="top" title="Copy to clipboard">
            <IconButton
              aria-label="Copy to clipboard"
              onClick={onCopyClick}
              type="button"
            >
              <FontAwesomeIcon icon={faCopy} size="sm" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

ShareLink.defaultProps = {
  hideCopyBtn: false,
};

export default ShareLink;
