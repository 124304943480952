import { Theme } from '@mui/material/styles';
import { pxToRem } from 'utils/mui';

const IconButton = (theme: Theme) => ({
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: theme.palette.gray.lighter,
        },
        '&:focus': {
          boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: theme.palette.gray.light,
        },
        '&:disabled': {
          opacity: 0.5,
        },

        // Variants-----------------------------
        '&.shadowed': {
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
          boxShadow: theme.customShadows.low,
        },
        '&.modal-title-close-button': {
          // This is not the styleguide and should strictly be used in the modal title icon button only.
          position: 'absolute',
          right: 6,
          top: 9,
          '&:hover, &:active': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        '&.snackbar-close-button': {
          '&:hover, &:active': {
            color: theme.palette.gray.main,
          },
        },
        //-----------------------------

        // TODO: These three variants namely 'filled-secondary', 'badge-button' and 'search-bar' have been depreciated
        // and should no longer be used.
        '&.filled-secondary': {
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          boxShadow: theme.customShadows.low,
          '&:hover': {
            boxShadow: theme.customShadows.high,
          },
        },
        '&.badge-button': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightMedium,
          borderRadius: '20px',
          padding: '3px 6px',
          fontSize: pxToRem(10),
        },
        '&.search-bar': {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 2,
          padding: '14px',
          svg: {
            color: theme.palette.secondary.contrastText,
          },
        },
        //----------------------------------------------------------
      },
      sizeLarge: {
        padding: theme.spacing(3),
        svg: {
          width: theme.spacing(6),
          height: theme.spacing(6),
        },
      },
      sizeMedium: {
        padding: theme.spacing(3),
        svg: {
          width: theme.spacing(4),
          height: theme.spacing(4),
        },
      },
      sizeSmall: {
        padding: theme.spacing(1.5),
        svg: {
          width: theme.spacing(3.5),
          height: theme.spacing(3.5),
        },
      },
    },
  },
});

export default IconButton;
