import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { CopyText } from 'common/CopyText';
import QRCode from 'common/QrCode';
import { DictionaryKeys } from 'constants/languageDictionary';
import { useGetWebsiteLink } from 'hooks/useGetWebsiteLink';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import { useGenerateTinyUrlMutation } from 'services/tiny-url';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  clientId: string;
}

const QRCodeDisplayCard = ({ clientId }: IProps) => {
  const theme = useTheme();

  const { mutate: generateTinyUrlMutate, isLoading } =
    useGenerateTinyUrlMutation();
  const [decodedReferralLink, setDecodedReferralLink] = useState('');

  const tenantAssocation = useAppSelector(selectAuthTenantAssociation);
  const { websiteLink } = useGetWebsiteLink();
  const primaryEmail = tenantAssocation?.email;
  const referralLink = useMemo(
    () => `${websiteLink}?referredby=${clientId}`,
    [clientId, websiteLink]
  );
  useEffect(() => {
    if (referralLink && !decodedReferralLink && websiteLink && !isLoading) {
      generateTinyUrlMutate(
        { originalUrl: referralLink },
        {
          onSuccess(res) {
            setDecodedReferralLink(res.data.tinyUrl);
          },
        }
      );
    }
  }, [
    decodedReferralLink,
    referralLink,
    websiteLink,
    generateTinyUrlMutate,
    isLoading,
  ]);

  const terminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ]);

  return (
    <Box
      border={1}
      borderColor={theme.palette.gray.light}
      borderRadius={2}
      height="280px"
      width="500px"
    >
      <Box p={4}>
        {/* Client Details with QR code details  */}
        <Box alignItems="center" display="flex" justifyContent="center">
          {isLoading && !decodedReferralLink && (
            <Box
              alignItems="center"
              display="flex"
              height="120px"
              justifyContent="center"
            >
              <CircularProgress size={40} />
            </Box>
          )}
          {decodedReferralLink && (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <QRCode content={decodedReferralLink!} />
              <Box marginTop={-4}>
                <CopyText
                  label={`Copy my ${terminology?._PATIENT_TERMINOLOGY.toLowerCase()} referral link`}
                  text={decodedReferralLink!}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Divider sx={{ marginY: 2 }} />
        {/* Footer Section  */}
        <Box textAlign="center">
          <Typography variant="body2">
            Print this card and keep it with you, or save the image to your
            device for easy sharing. Email us for support at <br />
            {primaryEmail && (
              <Typography component="span" variant="body2">
                {primaryEmail}
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QRCodeDisplayCard;
