import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import commonConstants from 'constants/common';

const { RADIUS_ITEM_LIMITS } = commonConstants;

interface IProps {
  data: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

const RadiusLimitSelect = ({ data, onChange, disabled }: IProps) => (
  <Box alignItems="center" display="inline-flex">
    <Box component="label" mr={2}>
      {/* <Typography
        color="text.secondary"
        gutterBottom={false}
        id="page-limit-select-label"
        variant="body1"
      >
        Radius
      </Typography> */}
    </Box>
    <FormControl
      className="select-text-variant"
      size="small"
      sx={{ '& .MuiInputBase-root': { width: '120px !important' } }}
    >
      <Select
        disabled={disabled}
        id="page-limit-select"
        labelId="page-limit-select-label"
        onChange={(e) => onChange(+e.target.value)}
        value={data}
      >
        {RADIUS_ITEM_LIMITS.map((item) => (
          <MenuItem key={item} value={item}>
            <Typography gutterBottom={false} variant="body2">
              {item} mi.
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

export default RadiusLimitSelect;
