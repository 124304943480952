import { useEffect, useState } from 'react';

import { Box, DialogContent } from '@mui/material';
import { LoadingIndicator, ModalFooter } from 'common';
import EmptySearchList from 'common/EmptySearchList';
import LabelBoxCard from 'common/LabelBoxCard';
import SearchBar from 'common/ui/inputs/SearchBar';
import commonConstants from 'constants/common';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { IDocument } from 'interfaces/documents';
import {
  useAttachDocumentMutation,
  useDocumentListQuery,
} from 'services/documents';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';

import FileList from '../../../features/cases/modal/files/FileList';

interface Props {
  files: IDocument[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const FromDocuments = ({ files, setStep }: Props) => {
  const [data, setData] = useState<IDocument[]>([]);
  const [isFileAttachedAlready, setIsFileAttachedAlready] = useState(false);

  const editClientData = useAppSelector(
    selectEditClientData
  ) as IAdaptedClientGroup & IAdaptedClientIndividual;

  const isSelected = data.some((e) => e.isSelected);

  const tenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId;

  const [filters, setFilters] = useState(commonConstants.DEFAULT_TABLE_FILTER);

  const attachDocumentMutation = useAttachDocumentMutation();
  const documentListQuery = useDocumentListQuery(
    {
      ...filters,
      associationId: tenantId!,
      tenantId: tenantId!,
      listView: true,
    },
    {
      enabled: !!tenantId,
    }
  );

  const onKeywordChange = (keyword: string) => {
    setFilters((prev) => ({
      ...prev,
      keyword,
    }));
  };

  useEffect(() => {
    if (documentListQuery?.data) {
      const documentList =
        (files &&
          documentListQuery?.data?.rows?.filter(
            (obj1: IDocument) =>
              !files.find(
                (obj2: IDocument) => obj1.documentId === obj2.documentId
              )
          )) ??
        [];
      setData(documentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentListQuery?.data, files]);

  const handleSelectedFile = (file: IDocument, checked: boolean) => {
    const selected = data.map((e) => {
      if (e.documentId === file.documentId)
        return {
          ...e,
          isSelected: checked,
        };
      return e;
    });
    setData(selected);
  };

  const handleClose = () => {
    setStep(1);
  };

  const handleAttach = () => {
    if (editClientData) {
      const documents = data.filter((e) => e.isSelected);

      const payload = documents.map((e) => ({
        documentId: e.documentId,
        associationId: editClientData.clientId!,
        type: commonConstants.CLIENT,
      }));
      attachDocumentMutation.mutate(
        {
          data: payload,
          documents,
          associationId: editClientData.clientId!,
        },
        {
          onSuccess() {
            setStep(0);
            setIsFileAttachedAlready(true);
          },
        }
      );
    }
  };

  return (
    <Box>
      <LabelBoxCard label="Below are the list of files" title="Upload files" />

      <Box minHeight="40vh" padding={4}>
        <Box mb={4}>
          <SearchBar fullWidth onSearchKeywordChange={onKeywordChange} />
        </Box>
        <DialogContent
          className="modal-content modal-content--fixed-height scrollbar"
          sx={{ maxHeight: '40vh !important', padding: '0px 8px 0px 0px' }}
        >
          <Box>
            {documentListQuery.isLoading && data ? (
              <Box minHeight="40vh">
                <LoadingIndicator />;
              </Box>
            ) : (
              <Box>
                {data.length ? (
                  data.map((e) => (
                    <FileList
                      handleSelectedFile={handleSelectedFile}
                      info={e}
                      key={e.documentId}
                    />
                  ))
                ) : (
                  <Box minHeight="40vh">
                    {isFileAttachedAlready ? (
                      <EmptySearchList message="No files to attach" />
                    ) : (
                      <EmptySearchList message="There are currently no files" />
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
      </Box>
      <ModalFooter
        actionButtonLabel="Attach to Files"
        actionButtonType="button"
        cancelButtonType="back"
        isDisabled={!isSelected}
        isLoading={attachDocumentMutation.isLoading}
        onActionButtonClick={handleAttach}
        onCancelButtonClick={handleClose}
      />
    </Box>
  );
};

export default FromDocuments;
