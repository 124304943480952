import { useFormContext } from 'react-hook-form';

import { Grid } from '@mui/material';
import { FormClientAutocomplete, FormTextfield } from 'common';
import FormReferringAgentAutocomplete from 'common/form/FormReferringAgentAutocomplete';
import UserType from 'enums/User';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IAdaptedClientTableRow } from 'interfaces/client-management';
import { IReferredBy } from 'interfaces/client-management/enrollment';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { checkEnrollmentAffiliateVisibleTenantSetting } from 'utils/tenantSetting';

import IndividualForm from './IndividualForm';

interface IProps {
  allDisabled?: boolean;
  isSponsorIdEnabled?: boolean;
}

const PrimaryForm = ({
  allDisabled,
  isSponsorIdEnabled,
}: IProps): JSX.Element => {
  const { setValue, watch } = useFormContext();

  const tenantSettings = useAppSelector(selectTenantSettings);

  const isUserAgent = useCheckUserIsAgent();
  const isUserClient = useCheckUserIsClient();
  const isClientAffiliateEnabled = useCheckFormFieldSettings().enabled;
  const checkEnabledSettings = useCheckFormFieldSettings().enabled;

  const isAffiliateEnabled =
    checkEnrollmentAffiliateVisibleTenantSetting(tenantSettings);

  const watchedReferredBy: IReferredBy | null = watch('referredBy');
  const isReferredByDisabled = isUserAgent || isUserClient;
  return (
    <>
      {/* TODO: We are hardcoding below condition .. Later it will be based according to Tenant Settings */}
      {isAffiliateEnabled && (
        <>
          <Grid mb={4}>
            {isClientAffiliateEnabled.clientAffiliate &&
              checkEnabledSettings.referredBy && (
                <FormClientAutocomplete
                  disabled={isReferredByDisabled}
                  inputProps={{
                    'data-cy': 'clients-referred-by',
                  }}
                  isConfigurableThroughSetting
                  label="Referred by"
                  name="referredBy"
                  placeholder="Type name or email to search"
                  setFieldValue={(client: IAdaptedClientTableRow | null) =>
                    setValue('referredBy', {
                      id: client?.clientId || '',
                      name: client?.name || '',
                    })
                  }
                  userType={UserType.INDIVIDUAL}
                  watchedFieldValue={watchedReferredBy?.id || ''}
                />
              )}
            {!isClientAffiliateEnabled.clientAffiliate &&
              checkEnabledSettings.referredBy && (
                <FormReferringAgentAutocomplete
                  disabled={isReferredByDisabled}
                  inputProps={{
                    'data-cy': 'clients-referred-by',
                  }}
                  isConfigurableThroughSetting
                  label="Referred by"
                  name="referredBy"
                />
              )}
          </Grid>{' '}
        </>
      )}

      <IndividualForm />
      {isSponsorIdEnabled && (
        <Grid container>
          <Grid mb={8} mt={8} xs={6}>
            <FormTextfield
              disabled={allDisabled || isUserAgent || isUserClient}
              inputProps={{
                'data-cy': 'clients-member-id',
              }}
              label="Member ID"
              name="memberId"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
PrimaryForm.defaultProps = {
  allDisabled: false,
  isSponsorIdEnabled: false,
};
export default PrimaryForm;
