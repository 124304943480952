import { useMemo } from 'react';

import commonConstants from 'constants/common';
import { OfferingType } from 'enums/client-management';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { useTenantOfferingQuery } from 'services/tenant-management/tenant/tenantMembershipOffering';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectManageMembershipTypeGroupDetail } from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';

import { useGetCurrentSelectedClientAndMembershipDetails } from './useGetCurrentSelectedClientAndMembershipDetails';

export const useGetTenantOfferingsOfGroup = () => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const {
    clientId,
    clientEnrollmentId: clientDetailEnrollmentId,
    isClientTypeGroup,
    membership,
    selectedGroupDetails,
  } = useGetCurrentSelectedClientAndMembershipDetails();

  const authTenantId = useMemo(() => {
    if (membership) {
      return membership.tenantId;
    }
    if (selectedGroupDetails) {
      return selectedGroupDetails.tenantId;
    }
    return authTenantAssociationData?.tenantId;
  }, [authTenantAssociationData?.tenantId, selectedGroupDetails, membership]);

  const groupData = useAppSelector(selectManageMembershipTypeGroupDetail);

  const clientEnrollmentId =
    groupData?.clientEnrollmentId || clientDetailEnrollmentId;
  const primaryId =
    membership?.primaryId || (groupData?.clientId as string) || '';

  const groupId =
    membership?.primaryId && membership.groupId
      ? membership.groupId
      : undefined;

  const { data, isLoading } = useTenantOfferingQuery(
    authTenantId!,
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      type: OfferingType.MEMBERSHIP,
      recommended: true,
      ...(isClientTypeGroup
        ? {
            clientId: clientId!,
          }
        : {
            groupId,
            parentId: primaryId,
            clientId: clientId!,
          }),
    },
    {
      enabled:
        !!authTenantId && !!clientEnrollmentId && (!!clientId || !!primaryId),
    }
  );

  return {
    data: (data?.rows || []) as IAdaptedTenantMembershipOfferingTableRow[],
    isLoading,
  };
};
