import { useState } from 'react';

import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, InputAdornment } from '@mui/material';
import { FormTextfield } from 'common';

interface IProps {
  name: string;
  label: string;
  required: boolean;
  showHelperText?: boolean;
  showIcon?: boolean;
}

const FormPasswordInput = ({
  name,
  label,
  required,
  showHelperText,
  showIcon,
}: IProps): JSX.Element => {
  const [togglePassword, setTogglePassword] = useState<boolean>(false);

  return (
    <Box alignItems="flex-start" display="flex" flexDirection="row">
      {showIcon && (
        <Box marginRight={4} marginTop={4}>
          <FontAwesomeIcon icon={faLock} />
        </Box>
      )}

      <FormTextfield
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => setTogglePassword(!togglePassword)}
              position="start"
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={togglePassword ? faEye : faEyeSlash} />
            </InputAdornment>
          ),
        }}
        label={label}
        name={name}
        required={required}
        showHelperText={showHelperText}
        type={togglePassword ? 'text' : 'password'}
      />
    </Box>
  );
};

FormPasswordInput.defaultProps = {
  showIcon: true,
  showHelperText: false,
};

export default FormPasswordInput;
