import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';
import themeVars from 'assets/scss/_theme-vars.module.scss';

const BORDER = `1px solid ${themeVars.grayLight}`;

const Table = (theme: Theme): Components<Theme> => ({
  MuiTableContainer: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableRow-head': {
          borderTop: BORDER,
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        '& .MuiTableRow-root': {
          transition: 'all 0.375s ease-out',
          '&:hover': {
            background: theme.palette.gray.lighter,
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.row': {
          '&--with-collapsible-row': {
            '& .MuiTableCell-root': {
              borderBottom: 'none',
            },
          },

          '&--collabsible': {
            backgroundColor: theme.palette.common.white,
            '& .MuiTableCell-root': {
              borderBottom: BORDER,
            },
          },

          '&--collabsible-without-bottom-border': {
            backgroundColor: theme.palette.gray.lighter,
            '& .MuiTableCell-root': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        paddingTop: '10px',
        paddingBottom: '10px',
        ...theme.typography.body2,
        '& .MuiTypography-root': {
          svg: {
            color: theme.palette.gray.main,
          },
        },

        '&.expand-collapse-column': {
          width: theme.spacing(12),
          verticalAlign: 'top',
          paddingTop: '14px',

          '& .MuiIconButton-root': {
            color: theme.palette.gray.main,
            '&:hover': {
              color: theme.palette.gray.dark,
            },
          },
        },
      },
      body: {
        ...theme.typography.body2,
        color: theme.palette.gray.dark,
        // whiteSpace: 'break-spaces',
        // textOverflow: 'ellipsis',
        // overflow: 'hidden',
        // '& .MuiTypography-root': {
        //   whiteSpace: 'break-spaces',
        //   textOverflow: 'ellipsis',
        //   overflow: 'hidden',
        // },
      },
    },
  },
});

export default Table;
