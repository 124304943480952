import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Box, Divider, Typography } from '@mui/material';
import { getTasksDetail } from 'api/cases';
import { LoadingIndicator } from 'common';
import { ITask } from 'interfaces/cases';
import { useUserDetailQuery } from 'services/tenant-management/users';

import { TaskDetail } from './TaskDetail';

export const TasklistView = () => {
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('taskId');
  const userId = searchParams.get('userId');
  const relatedTaskIds = searchParams.get('relatedTaskIds');

  const [tasksData, setTasksData] = useState<ITask[]>([]);
  const [dataLoading, setDataLoading] = useState(false);

  const { data: userData, isLoading: isUserDetailLoading } = useUserDetailQuery(
    userId as string,
    {
      enabled: !!userId,
    }
  );

  useEffect(() => {
    setDataLoading(true);
    if (taskId) {
      const relatedTaskIdsArray: string[] = relatedTaskIds
        ? relatedTaskIds?.split(',')
        : [];
      const getTasks = async () => {
        const tasks = await Promise.all(
          [taskId, ...relatedTaskIdsArray].map((id) => getTasksDetail(id))
        );
        setTasksData([...tasks.map((e) => e.data)]);
        setDataLoading(false);
      };
      getTasks();
    }
  }, [taskId, relatedTaskIds]);

  return (
    <Box padding={6}>
      {dataLoading || isUserDetailLoading ? (
        <LoadingIndicator containerHeight="100px" />
      ) : (
        <>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h2">
              Hi{' '}
              {`${userData?.demographic.firstName} ${userData?.demographic.lastName}`}
            </Typography>
            <Typography my={4}>
              Below are the task(s) that are assigned to you
            </Typography>
          </Box>
          <Box>
            {tasksData.map((task, index) => {
              const className = task.taskId === taskId ? 'primary.lighter' : '';
              return (
                <Box sx={{ bgcolor: className }}>
                  {index === 0 ? (
                    <Divider sx={{ bgcolor: (t) => t.palette.gray.light }} />
                  ) : (
                    ''
                  )}
                  <TaskDetail key={task.taskId} task={task} />
                  <Divider sx={{ bgcolor: (t) => t.palette.gray.light }} />
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
