import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useLocation } from 'react-router-dom';

import * as casesAPI from 'api/cases';
import { DictionaryKeys } from 'constants/languageDictionary';
import toastMessageConstant from 'constants/toastMessage';
import uiRoutes from 'constants/uiRoutes';
import {
  ReferralCaseRequestedAssignUserType,
  ReferralCaseServiceType,
  ReferralCaseStatus,
  SelectionOptions,
} from 'enums/case';
import { AffiliateType } from 'enums/client-management';
import { ReferenceType } from 'enums/common';
import { DocumentVisibility } from 'enums/documents';
import ModalKey from 'enums/ModalKey';
import { useUpdateCaseList } from 'features/case-layout-new/hooks/mutation-queries/useUpdateCaseList';
import useCombinedFilter from 'features/case-layout-new/hooks/useCombinedFilter';
import { useServiceMode } from 'features/case-layout-new/hooks/useServiceMode';
import { getCaseFilteredAdvanceSearch } from 'features/cases';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useCheckUserIsClientIndividualOrDependentOrGroupMember } from 'hooks/useCheckUserIsClientIndividualOrDependentOrGroupMember';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import {
  IAddReferralCaseClientPayload,
  IAddReferralCasePayload,
  ICaseGeneralInformationPayload,
  ICloneCaseOptions,
  IDonorPatientInformation,
  IFetchHistoryFilter,
  IReferralCaseFilter,
  IReferralCaseList,
  IReferralCaseNoteSchema,
  IReferralCaseNoteVisibilitySchema,
  IReferralCaseNotifyUserSchema,
  IReferralCasePatient,
  IReferralCasePatientInformationSchema,
  IReferralCaseProviderOption,
  IReferralCaseProviderOptionPayload,
  IReferralCaseRequestedAssignedUserSchema,
  IReferralCaseRequestedChangeTenantSchema,
  IReferralCaseService,
  IReferralCaseShareWithEmailSchema,
  // eslint-disable-next-line sort-imports
  IReferralCasesProcedureInformationSchema,
  IReferralCasesServiceInformationSchema,
  IReferralCaseStatus,
  IReferralCaseTimelog,
  IReferralCaseTimelogSchema,
  IReferralFile,
  IReferralNote,
  IReferringPhysicianFormSchema,
  IStateAverage,
  ITaskSchema,
} from 'interfaces/cases';
import { IFilter, INameType } from 'interfaces/common';
import { IFileSchema } from 'interfaces/documents';
import { IError } from 'interfaces/http';
import { useSnackbar } from 'notistack';
import { documentCasesKeys } from 'services/documents';
import { RootState, store } from 'stores';
import {
  adaptCaseGroupSelect,
  adaptCaseTimelog,
  adaptReferralCase,
  adaptReferralCaseList,
  adaptTaskList,
} from 'utils/cases';
import { getFullName } from 'utils/common';
import { getTenantFromToken } from 'utils/jwt';
import { getTextFromLangDict } from 'utils/languageDictionary';
import { combineFilters } from 'utils/misc';
import { getSession } from 'utils/storage';

export const referralCasesKeys = {
  all: ['referral-case'] as const,
  lists: () => [...referralCasesKeys.all, 'list'] as const,
  list: (filters: IReferralCaseFilter) =>
    [...referralCasesKeys.lists(), { filters }] as const,
  details: () => [...referralCasesKeys.all, 'detail'] as const,
  detail: (id: number | string) =>
    [...referralCasesKeys.details(), id] as const,
  timelog: (id: number | string) =>
    [...referralCasesKeys.details(), id, 'timelog'] as const,
  tasks: (id: number | string) =>
    [...referralCasesKeys.details(), id, 'tasks'] as const,
  task: (id: number | string) =>
    [...referralCasesKeys.details(), id, 'task'] as const,
  groupSelect: (clientId: string) =>
    [...referralCasesKeys.all, clientId, 'group-select'] as const,
  fileHistory: (filters: { caseId: string; fileId: string }) =>
    [...referralCasesKeys.all, filters, 'file-history'] as const,
  configs: () => [...referralCasesKeys.all, 'configs'] as const,
  notes: (caseId: string) =>
    [...referralCasesKeys.all, 'notes', caseId] as const,
  latestPatientInfo: (pId: string) =>
    [...referralCasesKeys.all, 'latestPatientInfo', pId] as const,
  taggableUsers: (caseId: string, filters: { keyword?: string }) =>
    [
      ...referralCasesKeys.detail(caseId),
      'taggable-users',
      { filters },
    ] as const,
  associatedPatients: (filters: { keyword: string }) =>
    [...referralCasesKeys.all, 'associated-patients', { filters }] as const,
  associatedGroups: (filters: { keyword: string }) =>
    [...referralCasesKeys.all, 'associated-groups', { filters }] as const,
  associatedServices: (filters: { keyword: string }) =>
    [...referralCasesKeys.all, 'associated-services', { filters }] as const,
  associatedAssignees: (filters: { keyword: string }) =>
    [...referralCasesKeys.all, 'associated-assignees', { filters }] as const,
  associatedRequestees: (filters: { keyword: string }) =>
    [...referralCasesKeys.all, 'associated-requestees', { filters }] as const,
};

export const noteHistoryKeys = {
  all: ['note-history'] as const,
  lists: () => [...noteHistoryKeys.all, 'list'] as const,
  list: (filters: IFilter) =>
    [...noteHistoryKeys.lists(), { filters }] as const,
  details: () => [...noteHistoryKeys.all, 'detail'] as const,
  detail: (caseId: string, noteId: string) =>
    [...noteHistoryKeys.details(), caseId, noteId] as const,
};

export const useReferralCaseListQuery = (
  filters: IReferralCaseFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.list(filters),
    () => casesAPI.getCases(filters),
    {
      enabled,
      select: adaptReferralCaseList,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useReferralCaseListInfiniteQuery = (
  filters: IReferralCaseFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useInfiniteQuery(
    referralCasesKeys.list(filters),
    ({ pageParam = 0 }) => casesAPI.getCasesInfinite(filters, pageParam),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled,
      // getNextPageParam: (lastPage: any, pages: any) => {
      //   console.log({ lastPage, pages }, lastPage.nextCursor);
      //   return lastPage.nextCursor;
      // },
      // select: adaptReferralCaseList,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data,
  };
};

export const useReferralCaseQuery = (
  id: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.detail(id),
    () => casesAPI.getCase(id),
    {
      enabled,
      select: adaptReferralCase,
      cacheTime: 0,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useReferralCaseTimelogQuery = (
  id: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.timelog(id),
    () => casesAPI.getTimelog(id),
    {
      enabled,
      select: adaptCaseTimelog,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddReferralCaseMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { combinedFilters } = useCombinedFilter();
  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({
      data,
    }: {
      data: IAddReferralCasePayload | IAddReferralCaseClientPayload;
      includeDependentCases?: boolean;
    }) => casesAPI.addReferralCase(data),
    {
      onSuccess: (res, context) => {
        const payload = context.data as IAddReferralCaseClientPayload;

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.ADD_NEW_CASE,
          {
            variant: 'success',
          }
        );

        const queryKey = referralCasesKeys.list(
          combineFilters(caseStore.filters, {
            ...getCaseFilteredAdvanceSearch(caseStore.advancedSearch),
            ...(payload.type === AffiliateType.CLIENT &&
              !context.includeDependentCases && {
                onlyMyCases: true,
              }),
            ...(context.includeDependentCases && {
              includeDependentCases: true,
            }),
          })
        );

        const queryData = queryClient.getQueryData(queryKey);

        if (queryData) {
          queryClient.setQueryData(queryKey, (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData?.data,
              rows: [
                res.data,
                ...(oldData?.data?.rows ? oldData.data.rows : []),
              ],
            },
          }));
        }

        const queryKeyWithGenericFilters =
          referralCasesKeys.list(combinedFilters);

        const queryDataWithGenericFilters = queryClient.getQueryData(
          queryKeyWithGenericFilters
        );

        if (queryDataWithGenericFilters) {
          queryClient.setQueryData(
            queryKeyWithGenericFilters,

            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any, index: number) => ({
                ...old_pages,
                count: index === 0 ? old_pages.count + 1 : old_pages.count,
                rows: [res.data, ...old_pages.rows],
              })),
            })
          );
        }
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useCloneCaseMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { combinedFilters } = useCombinedFilter();

  return useMutation(
    ({ caseId, data }: { caseId: string; data: ICloneCaseOptions }) =>
      casesAPI.cloneCase(caseId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.CASE_CLONED,
          {
            variant: 'success',
          }
        );

        const queryKeyWithGenericFilters =
          referralCasesKeys.list(combinedFilters);

        const queryDataWithGenericFilters = queryClient.getQueryData(
          queryKeyWithGenericFilters
        );

        if (queryDataWithGenericFilters) {
          queryClient.setQueryData(
            queryKeyWithGenericFilters,

            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: [res.data, ...old_pages.rows],
              })),
            })
          );
        }
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useAddReferralCaseTimelogMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ caseId, data }: { caseId: string; data: IReferralCaseTimelogSchema }) =>
      casesAPI.addReferralCaseTimelog(caseId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.timelog(context.caseId),
          (oldData: any) => ({
            ...oldData,
            data: [res.data, ...oldData.data],
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.ADD_NEW_TIMELOG,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateReferralCaseTimelogMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      caseId,
      timelogId,
      data,
    }: {
      caseId: string;
      timelogId: string;
      data: IReferralCaseTimelogSchema;
    }) => casesAPI.updateReferralCaseTimelog(caseId, timelogId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.timelog(context.caseId),
          (oldData: any) => ({
            ...oldData,
            data: oldData.data.map((e: IReferralCaseTimelog) => {
              if (e.timesheetId === context.timelogId) return res.data;

              return e;
            }),
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.UPDATE_NEW_TIMELOG,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useDeleteReferralCaseTimelogMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ caseId, timelogId }: { caseId: string; timelogId: string }) =>
      casesAPI.deleteReferralCaseTimelog(caseId, timelogId),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.timelog(context.caseId),
          (oldData: any) => ({
            ...oldData,
            data: oldData.data.filter(
              (e: IReferralCaseTimelog) =>
                e.timesheetId !== res.data.timesheetId
            ),
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.DELETE_NEW_TIMELOG,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateStatusMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const dispatch = useDispatch();
  // const caseList = useAppSelector(selectCaseList);
  const rootState: RootState = store.getState();
  const isClientIndividual =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();

  const isUserEmail = useCheckUserIsEmail();
  const guestAccuntShareEmail = getSession('guestEmail');
  const tenant = getTenantFromToken();

  const isGroupUser = useCheckUserIsClientGroup();
  const caseStore = rootState.case;
  const { combinedFilters: templateAFilter } = useCombinedFilter();
  const updateRelevantCaseList = useUpdateCaseList();

  return useMutation(
    ({ caseId, data }: { caseId: string; data: IReferralCaseStatus }) =>
      casesAPI.updateStatus(caseId, data),
    {
      onSuccess: (res, context) => {
        const isDelete =
          context.data.status === ReferralCaseStatus.DELETED.toUpperCase();

        // const caseListKey = referralCasesKeys.list(
        //   combineFilters(
        //     caseStore.filters,
        //     getCaseFilteredAdvanceSearch(caseStore.advancedSearch)
        //   )
        // );

        // const caseListData = queryClient.getQueryData(caseListKey);

        /**
         * This block of code does not get executed because `caseListData` is always undefined.
         * TODO: Need to find out why `caseListData` is undefined
         */

        // const caseListKey = referralCasesKeys.list(
        //   combineFilters(
        //     caseStore.filters,
        //     getCaseFilteredAdvanceSearch(caseStore.advancedSearch)
        //   )
        // );

        // const caseListData = queryClient.getQueryData(caseListKey);

        // if (isDelete && caseListData) {
        //   queryClient.setQueryData(caseListKey, (oldData: any) => ({
        //     ...oldData,
        //     data: {
        //       ...oldData.data,
        //       count: oldData.data.count - 1,
        //       rows: oldData?.data?.rows?.filter(
        //         (e: IReferralCaseList) =>
        //           e.caseId !== caseStore.selectedReferralCase!.caseId!
        //       ),
        //     },
        //   }));
        //   //-------------------------------------

        //   const updatedCase = caseList.filter(
        //     (e) => e.caseId !== context.caseId
        //   );
        //   dispatch(changeReferralCaseList(updatedCase));

        //   if (updatedCase.length)
        //     navigate(`${uiRoutes.cases}?caseId=${updatedCase[0]?.caseId}`);
        //   return;
        // }

        const caseStatusOptions =
          caseStore?.selectedReferralCase?.caseStatusOptions;
        const updatedCaseStatus = caseStatusOptions?.find(
          (item) => item.code.toUpperCase() === res.data?.status?.toUpperCase()
        ) || { code: '', name: '' };

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              status: updatedCaseStatus,
              notes: [res.data.note, ...oldData.data.notes],
            },
          })
        );
        const queryKey = combineFilters(caseStore.filters, {
          ...getCaseFilteredAdvanceSearch(caseStore.advancedSearch),
          ...(isClientIndividual && { onlyMyCases: true }),
          ...(isGroupUser && {
            isGroupAdmin: true,
          }),
          ...(isUserEmail && {
            referenceId: tenant.tenantId,
            referenceType: ReferenceType.EMAIL,
            email: decodeURIComponent(guestAccuntShareEmail || ''),
          }),
        });
        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantStatus(
            caseStore.selectedReferralCase.caseId,
            updatedCaseStatus
          );
        }
        const queryDataTemplateA = queryClient.getQueryData(
          referralCasesKeys.list(templateAFilter)
        );

        const queryData = queryClient.getQueryData(
          referralCasesKeys.list(queryKey)
        );
        // console.log({ queryDataTemplateA, queryData });
        if (queryDataTemplateA) {
          queryClient.setQueryData(
            referralCasesKeys.list(templateAFilter),
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,

                ...(isDelete
                  ? {
                      count: old_pages.count - 1,
                      rows: old_pages?.rows?.filter(
                        (e: IReferralCaseList) =>
                          e.caseId !== caseStore.selectedReferralCase!.caseId!
                      ),
                    }
                  : {
                      rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                        if (
                          e.caseId === caseStore.selectedReferralCase!.caseId!
                        )
                          return {
                            ...e,
                            status: updatedCaseStatus,
                          };
                        return e;
                      }),
                    }),
              })),
            })
          );
        }
        if (queryData) {
          queryClient.setQueryData(
            referralCasesKeys.list(queryKey),
            (oldData: any) => ({
              ...oldData,
              data: {
                ...oldData?.data,
                ...(isDelete
                  ? {
                      count: oldData.data.count - 1,
                      rows: oldData?.data?.rows?.filter(
                        (e: IReferralCaseList) =>
                          e.caseId !== caseStore.selectedReferralCase!.caseId!
                      ),
                    }
                  : {
                      rows: oldData?.data?.rows?.map((e: IReferralCaseList) => {
                        if (
                          e.caseId === caseStore.selectedReferralCase!.caseId!
                        )
                          return {
                            ...e,
                            status: updatedCaseStatus,
                          };
                        return e;
                      }),
                    }),
              },
            })
          );
        }

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.CHANGE_STATUS,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddNoteMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const { combinedFilters: templateAFilter } = useCombinedFilter();
  const updateRelevantCaseList = useUpdateCaseList();

  return useMutation(
    ({ caseId, data }: { caseId: string; data: IReferralCaseNoteSchema }) =>
      casesAPI.addNote(caseId, data),
    {
      onSuccess: (res) => {
        const caseStatusOptions =
          caseStore?.selectedReferralCase?.caseStatusOptions;
        const updatedCaseStatus = caseStatusOptions?.find(
          (item) =>
            item.code.toUpperCase() === res.data?.caseStatus?.toUpperCase()
        ) || { code: '', name: '' };

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              notes: [res.data, ...oldData.data.notes],
            },
          })
        );

        const queryDataTemplateA = queryClient.getQueryData(
          referralCasesKeys.list(templateAFilter)
        );

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              status: updatedCaseStatus,
            },
          })
        );

        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantStatus(
            caseStore.selectedReferralCase.caseId,
            updatedCaseStatus
          );
        }
        if (queryDataTemplateA) {
          queryClient.setQueryData(
            referralCasesKeys.list(templateAFilter),
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      status: updatedCaseStatus,
                    };
                  return e;
                }),
              })),
            })
          );
        }
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.ADD_NEW_NOTE,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddFileMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({
      caseId,
      data,
      params,
    }: {
      caseId: string;
      data: IFileSchema[];
      params?: { createdBy?: string };
    }) => casesAPI.addFile(caseId, data, params),
    {
      onSuccess: (res) => {
        if (!caseStore.selectedReferralCase) return;

        const query = documentCasesKeys.list({
          associationId: caseStore.selectedReferralCase.caseId! as string,
        });
        const queryData = queryClient.getQueryData(query);

        if (!queryData) {
          return;
        }

        queryClient.setQueryData(query, (oldData: any) => ({
          ...oldData,
          data: [...res.data, ...oldData.data],
        }));
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.FILE_UPLOADED,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useShareCaseWithEmailMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferralCaseShareWithEmailSchema;
    }) => casesAPI.shareCaseWithEmail(caseId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              access: res.data.access,
              ...(res.data.note && {
                notes: [res.data.note, ...oldData.data.notes],
              }),
            },
          })
        );
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.SHARED,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useReferralCaseChangeTenantMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferralCaseRequestedChangeTenantSchema;
    }) => casesAPI.changeTenant(caseId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              access: [res.data.access, ...oldData.data.access],
              to: res.data.to,
            },
          })
        );
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.CHANGE_TENANT,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useReferralCaseAssignUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { combinedFilters: templateAFilter } = useCombinedFilter();
  const location = useLocation();
  const isCaseLayoutNew = location.pathname === uiRoutes.cases.detail;

  const isUserEmail = useCheckUserIsEmail();
  const guestAccuntShareEmail = getSession('guestEmail');
  const tenant = getTenantFromToken();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const isGroupUser = useCheckUserIsClientGroup();
  const isClientIndividual =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();

  const updateRelevantCaseList = useUpdateCaseList();

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferralCaseRequestedAssignedUserSchema;
    }) => casesAPI.assignUser(caseId, data),
    {
      onSuccess: (res, context) => {
        const queryKey = combineFilters(caseStore.filters, {
          ...getCaseFilteredAdvanceSearch(caseStore.advancedSearch),
          ...(isClientIndividual && { onlyMyCases: true }),
          ...(isGroupUser && {
            isGroupAdmin: true,
          }),
          ...(isUserEmail && {
            referenceId: tenant.tenantId,
            referenceType: ReferenceType.EMAIL,
            email: decodeURIComponent(guestAccuntShareEmail || ''),
          }),
        });

        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantAssignedTo(
            caseStore.selectedReferralCase.caseId,
            context.data.type as ReferralCaseRequestedAssignUserType,
            context.data
          );
        }

        if (isCaseLayoutNew) {
          queryClient.setQueryData(
            referralCasesKeys.list(templateAFilter),
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      ...(context.data.type ===
                        ReferralCaseRequestedAssignUserType.FROM && {
                        from: {
                          ...e.from,
                          assignedTo: context.data,
                        },
                      }),
                      ...(context.data.type ===
                        ReferralCaseRequestedAssignUserType.TO && {
                        to: {
                          ...e.to,
                          assignedTo: context.data,
                        },
                      }),
                    };
                  return e;
                }),
              })),
            })
          );
        } else {
          queryClient.setQueryData(
            referralCasesKeys.list(queryKey),
            (oldData: any) => ({
              ...oldData,
              data: {
                ...oldData?.data,
                rows: oldData?.data?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      ...(context.data.type ===
                        ReferralCaseRequestedAssignUserType.FROM && {
                        from: {
                          ...e.from,
                          assignedTo: context.data,
                        },
                      }),
                      ...(context.data.type ===
                        ReferralCaseRequestedAssignUserType.TO && {
                        to: {
                          ...e.to,
                          assignedTo: context.data,
                        },
                      }),
                    };
                  return e;
                }),
              },
            })
          );
        }

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData?.data,
              ...(context.data.type ===
                ReferralCaseRequestedAssignUserType.FROM && {
                from: {
                  ...oldData.data.from,
                  assignedTo: context.data,
                },
              }),
              ...(context.data.type ===
                ReferralCaseRequestedAssignUserType.TO && {
                to: {
                  ...oldData.data.to,
                  assignedTo: context.data,
                },
              }),
            },
          })
        );

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.USER_ASSIGN,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useReferralCaseNotifyUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferralCaseNotifyUserSchema;
    }) => casesAPI.notifyUser(caseId, data),
    {
      onSuccess: () => {
        enqueueSnackbar(toastMessageConstant.CASES.NOTIFY, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteFileMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({ caseId, fileId }: { caseId: string; fileId: string }) =>
      casesAPI.deleteFile(caseId, fileId),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              files: oldData.data.files.filter(
                (e: IReferralFile) => e.id !== context.fileId
              ),
            },
          })
        );
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.FILE_DELETED,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteProcedureMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const updateRelevantCaseList = useUpdateCaseList();

  return useMutation(
    ({ caseId, procedureId }: { caseId: string; procedureId: string }) =>
      casesAPI.deleteProcedure(caseId, procedureId),
    {
      onSuccess: (res, context) => {
        const deletedService = caseStore.selectedReferralCase?.service.find(
          (e) => e.id === context.procedureId
        );
        const remainingServices =
          caseStore.selectedReferralCase?.service
            .filter((e) => e.id !== context.procedureId)
            .map((serv: IReferralCaseService) => ({
              name: serv.name,
              code: serv.code,
            })) || [];

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              service: oldData.data.service.filter(
                (e: IReferralFile) => e.id !== context.procedureId
              ),
            },
          })
        );

        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantServices(
            caseStore.selectedReferralCase.caseId,
            remainingServices
          );
        }
        const isProviderServiceEnabled =
          deletedService?.type === ReferralCaseServiceType.MEDICAL;

        const deletedToastMessage = isProviderServiceEnabled
          ? toastMessageConstant.CASES.PROCEDURE_REMOVED
          : toastMessageConstant.CASES.SERVICE_REMOVED;

        enqueueSnackbar(res.message ? res.message : deletedToastMessage, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditServiceMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const isUserGroup = useCheckUserIsClientGroup();
  const isUserIndividual =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();
  const isUserEmail = useCheckUserIsEmail();
  const guestAccuntShareEmail = getSession('guestEmail');
  const tenant = getTenantFromToken();

  const { combinedFilters: templateAFilter } = useCombinedFilter();

  const { isEditMode } = useServiceMode();
  const updateRelevantCaseList = useUpdateCaseList();

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      type?: ReferralCaseServiceType;
      disableInstantListUpdate?: boolean;
      data:
        | IReferralCasesProcedureInformationSchema[]
        | IReferralCasesServiceInformationSchema[];
    }) => casesAPI.updateService(caseId, data),
    {
      onSuccess: (res, context) => {
        let stateAverage: number | undefined;
        const serviceDetails = res
          .data[0] as IReferralCasesProcedureInformationSchema;

        if (serviceDetails?.stateAverage)
          stateAverage = serviceDetails.stateAverage;

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              service: res.data,
              ...(stateAverage && { stateAverage }),
            },
          })
        );
        const services = res.data.map((serv: IReferralCaseService) => ({
          name: serv.name,
          code: serv.code,
        }));

        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantServices(
            caseStore.selectedReferralCase.caseId,
            services
          );
        }
        const queryKey = combineFilters(caseStore.filters, {
          ...getCaseFilteredAdvanceSearch(caseStore.advancedSearch),
          ...(isUserIndividual && {
            onlyMyCases: true,
          }),
          ...(isUserGroup && {
            isGroupAdmin: true,
          }),
          ...(isUserEmail && {
            referenceId: tenant.tenantId,
            referenceType: ReferenceType.EMAIL,
            email: decodeURIComponent(guestAccuntShareEmail || ''),
          }),
        });

        const queryDataTemplateA = queryClient.getQueryData(
          referralCasesKeys.list(templateAFilter)
        );

        const queryData = queryClient.getQueryData(
          referralCasesKeys.list(queryKey)
        );

        if (queryDataTemplateA) {
          queryClient.setQueryData(
            referralCasesKeys.list(templateAFilter),
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      ...(!context.disableInstantListUpdate && {
                        service: services,
                      }),
                    };
                  return e;
                }),
              })),
            })
          );
        }

        if (queryData) {
          queryClient.setQueryData(
            referralCasesKeys.list(queryKey),
            (oldData: any) => ({
              ...oldData,
              data: {
                ...oldData?.data,
                rows: oldData?.data?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      ...(!context.disableInstantListUpdate && {
                        service: services,
                      }),
                    };
                  return e;
                }),
              },
            })
          );
        }

        const getSuccessToastMessage = () => {
          if (!isEditMode) {
            if (context.type === ReferralCaseServiceType.MEDICAL)
              return toastMessageConstant.CASES.ADD_PROCEDURE;

            return toastMessageConstant.CASES.ADD_SERVICE;
          }

          if (context.type === ReferralCaseServiceType.MEDICAL)
            return toastMessageConstant.CASES.UPDATE_PROCEDURE;

          return toastMessageConstant.CASES.UPDATE_SERVICE;
        };

        enqueueSnackbar(res.message ? res.message : getSuccessToastMessage(), {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdatePatientInformationMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const isClientGroup = useCheckUserIsClientGroup();
  const isUserEmail = useCheckUserIsEmail();
  const guestAccuntShareEmail = getSession('guestEmail');
  const tenant = getTenantFromToken();

  const isClientIndividual =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const authTenantAssocation = rootState.auth.tenantData?.tenantAssociation;
  const { langDict } = rootState;
  const title = getTextFromLangDict(langDict?.dict, {
    groupCode: authTenantAssocation?.groupCode || '',
    key: DictionaryKeys.PATIENT_TERMINOLOGY,
    tenantCode: authTenantAssocation?.code || '',
  });

  const { combinedFilters } = useCombinedFilter();
  const updateRelevantCaseList = useUpdateCaseList();

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferralCasePatientInformationSchema;
      originalNote: string;
    }) => casesAPI.updatePatient(caseId, data),
    {
      onSuccess: (res, context) => {
        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantPatientDetails(
            caseStore.selectedReferralCase.caseId,
            res.data as IReferralCasePatient
          );
        }

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData?.data,
              patient: {
                ...res?.data,
                fullName: getFullName(res.data.firstName, res.data.lastName),
                originalNote: context.originalNote,
                isVip: res?.data?.isVip || false,
              },
            },
          })
        );

        const casesListQuerykey = referralCasesKeys.list(
          combineFilters(caseStore.filters, {
            ...getCaseFilteredAdvanceSearch(caseStore.advancedSearch),
            ...(isClientIndividual && {
              onlyMyCases: true,
            }),
            ...(isClientGroup && {
              isGroupAdmin: true,
            }),
            ...(isUserEmail && {
              referenceId: tenant.tenantId,
              referenceType: ReferenceType.EMAIL,
              email: decodeURIComponent(guestAccuntShareEmail || ''),
            }),
          })
        );

        const caseListQueryData = queryClient.getQueryData(casesListQuerykey);

        if (caseListQueryData) {
          // Old Case Implementation
          queryClient.setQueryData(casesListQuerykey, (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData?.data,
              rows: oldData?.data?.rows?.map((e: IReferralCaseList) => {
                if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                  return {
                    ...e,
                    patient: {
                      firstName: res.data.firstName,
                      lastName: res.data.lastName,
                      isVip: res?.data?.isVip || false,
                    },
                  };
                return e;
              }),
            },
          }));
        }
        // New Case Implementation
        const queryKeyWithGenericFilters =
          referralCasesKeys.list(combinedFilters);

        const queryDataWithGenericFilters = queryClient.getQueryData(
          queryKeyWithGenericFilters
        );

        if (queryDataWithGenericFilters) {
          queryClient.setQueryData(
            queryKeyWithGenericFilters,
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      patient: {
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        isVip: res.data?.isVip || false,
                      },
                    };
                  return e;
                }),
              })),
            })
          );
        }

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.UPDATE_PATIENT.replace(
                '{{patient_terminology}}',
                title
              ),
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useChangePatientMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { combinedFilters } = useCombinedFilter();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const updateRelevantCaseList = useUpdateCaseList();
  const isClientGroup = useCheckUserIsClientGroup();
  const isUserEmail = useCheckUserIsEmail();
  const guestAccuntShareEmail = getSession('guestEmail');
  const tenant = getTenantFromToken();
  const isClientIndividual =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();
  const patientTerminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ])._PATIENT_TERMINOLOGY;

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferralCasePatientInformationSchema;
    }) => casesAPI.changePatient(caseId, data),
    {
      onSuccess: (res, context) => {
        if (caseStore?.selectedReferralCase?.caseId) {
          updateRelevantCaseList.updateRelevantPatientDetails(
            caseStore.selectedReferralCase.caseId,
            res.data as IReferralCasePatient
          );
        }

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData?.data,
              ...(context.data.dictionary && {
                dictionary: context.data.dictionary,
              }),
              patient: {
                ...res?.data,
                fullName: getFullName(res.data.firstName, res.data.lastName),
                isVip: res?.data?.isVip || false,
              },
            },
          })
        );

        const casesListQuerykey = referralCasesKeys.list(
          combineFilters(caseStore.filters, {
            ...getCaseFilteredAdvanceSearch(caseStore.advancedSearch),
            ...(isClientIndividual && {
              onlyMyCases: true,
            }),
            ...(isClientGroup && {
              isGroupAdmin: true,
            }),
            ...(isUserEmail && {
              referenceId: tenant.tenantId,
              referenceType: ReferenceType.EMAIL,
              email: decodeURIComponent(guestAccuntShareEmail || ''),
            }),
          })
        );

        const caseListQueryData = queryClient.getQueryData(casesListQuerykey);

        if (caseListQueryData) {
          queryClient.invalidateQueries(casesListQuerykey);
          // Old Case Implementation
          queryClient.setQueryData(casesListQuerykey, (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData?.data,
              rows: oldData?.data?.rows?.map((e: IReferralCaseList) => {
                if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                  return {
                    ...e,
                    patient: {
                      firstName: res.data.firstName,
                      lastName: res.data.lastName,
                      isVip: res?.data?.isVip || false,
                    },
                  };
                return e;
              }),
            },
          }));
        }
        // New Case Implementation
        const queryKeyWithGenericFilters =
          referralCasesKeys.list(combinedFilters);

        const queryDataWithGenericFilters = queryClient.getQueryData(
          queryKeyWithGenericFilters
        );
        queryClient.invalidateQueries(queryKeyWithGenericFilters);
        if (queryDataWithGenericFilters) {
          queryClient.setQueryData(
            queryKeyWithGenericFilters,
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!)
                    return {
                      ...e,
                      ...(context.data.dictionary && {
                        dictionary: context.data?.dictionary,
                      }),
                      patient: {
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        isVip: res.data?.isVip || false,
                      },
                    };
                  return {
                    ...e,
                  };
                }),
              })),
            })
          );
        }
        enqueueSnackbar(res.message.replace('Patient', patientTerminology), {
          variant: 'success',
        });
      },
      onError(err: IError) {
        enqueueSnackbar(err.message, {
          variant: 'success',
        });
      },
    }
  );
};

export const useUpdateNoteMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const { combinedFilters: templateAFilter } = useCombinedFilter();
  return useMutation(
    ({
      caseId,
      data,
      noteId,
    }: {
      caseId: string;
      data: IReferralCaseNoteSchema;
      noteId: string;
    }) => casesAPI.updateNote(caseId, noteId, data),
    {
      onSuccess: (res) => {
        const caseStatusOptions =
          caseStore?.selectedReferralCase?.caseStatusOptions;
        const updatedCaseStatus = caseStatusOptions?.find(
          (item) =>
            item.code.toUpperCase() === res.data?.caseStatus?.toUpperCase()
        ) || { code: '', name: '' };

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              notes: oldData.data.notes.map((item: IReferralNote) => {
                if (item.id !== res.data.id) return item;
                return { ...res.data, isEdited: true };
              }),
              ...(res.data.caseStatusChanged && {
                status: updatedCaseStatus,
              }),
            },
          })
        );
        const queryDataTemplateA = queryClient.getQueryData(
          referralCasesKeys.list(templateAFilter)
        );

        if (queryDataTemplateA) {
          queryClient.setQueryData(
            referralCasesKeys.list(templateAFilter),
            (oldData: any) => ({
              ...oldData,
              pages: oldData?.pages?.map((old_pages: any) => ({
                ...old_pages,
                rows: old_pages?.rows?.map((e: IReferralCaseList) => {
                  if (e.caseId === caseStore.selectedReferralCase!.caseId!) {
                    return {
                      ...e,
                      ...(res.data.caseStatusChanged && {
                        status: updatedCaseStatus,
                      }),
                    };
                  }

                  return e;
                }),
              })),
            })
          );
        }
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.UPDATE_NOTE,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteNoteMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({ caseId, noteId }: { caseId: string; noteId: string }) =>
      casesAPI.deleteNote(caseId, noteId),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              notes: oldData.data.notes.map((item: IReferralNote) => {
                if (item.id !== res.data.id) return item;
                return {
                  ...res.data,
                  isDeleted: res.data?.isDeleted,
                };
              }),
            },
          })
          // TODO: Commented for Now
          // (oldData: any) => ({
          //   ...oldData,
          //   data: {
          //     ...oldData.data,
          //     notes: oldData.data.notes.map((item: IReferralNote) => {
          //       if (item.id !== res.data.id) return item;
          //       return {
          //         ...res.data,
          //         isDeleted: res.data?.isDeleted,
          //       };
          //     }),
          //   },
          // })
        );
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.DELETED_NOTE,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useNoteHistoryQuery = (
  caseId: string,
  noteId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    noteHistoryKeys.detail(caseId, noteId),
    () => casesAPI.getNoteHistory(caseId, noteId),
    { enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useChangeVisibilityNoteMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  return useMutation(
    ({
      caseId,
      noteId,
      data,
    }: {
      caseId: string;
      noteId: string;
      data: IReferralCaseNoteVisibilitySchema;
    }) => casesAPI.updateVisibilityNote(caseId, noteId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              notes: oldData.data.notes.map((item: IReferralNote) => {
                if (item.id !== res.data.id) return item;
                return { ...res.data };
              }),
            },
          })
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemainingTimeQuery = (
  { clientId, caseId }: { clientId: string; caseId: string },

  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    `timelog-case_${caseId}-client-${clientId}`,
    () => casesAPI.getRemainingTime(clientId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTasksQuery = (caseId: string) => {
  const queryInfo = useQuery(
    referralCasesKeys.tasks(caseId),
    () => casesAPI.getTasks(caseId),
    {
      select: adaptTaskList,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddTaskMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: { data: ITaskSchema; caseId: string }) => casesAPI.addTask(data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.tasks(context.caseId),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: [res.data, ...oldData.data.rows],
            },
          })
        );

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.ADD_TASK,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteTaskMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ taskId }: { taskId: string; caseId: string }) =>
      casesAPI.deleteTask(taskId),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.tasks(context.caseId),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.filter(
                (item: any) => item.taskId !== res.data.taskId
              ),
            },
          })
        );
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.DELETED_TASK,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const usePatchTaskStatusMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      taskId,
    }: {
      data: { status: string };
      taskId: string;
      caseId: string;
    }) => casesAPI.patchTaskStatus(taskId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.tasks(context.caseId),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.map((item: any) => {
                if (item.taskId === res.data.taskId) {
                  return {
                    ...item,
                    status: res.data.status,
                  };
                }
                return item;
              }),
            },
          })
        );

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.ADD_TASK,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useTasksDetailQuery = (
  taskId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.task(taskId),
    () => casesAPI.getTasksDetail(taskId),
    { enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUpdateTaskMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, taskId }: { data: ITaskSchema; taskId: string }) =>
      casesAPI.updateTask(taskId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.tasks(res.data.reference.id),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.map((item: any) => {
                if (item.taskId === res.data.taskId) {
                  return res.data;
                }
                return item;
              }),
            },
          })
        );

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.ADD_TASK,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddBloodDonorsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({ data, caseId }: { data: IDonorPatientInformation[]; caseId: string }) =>
      casesAPI.addBloodDonors(caseId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              options: [
                ...res.data,
                ...(oldData?.data?.options ? oldData.data.options : []),
              ],
            },
          })
        );

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.ADD_DONOR,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSelectedBloodDonorsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({
      donorId,
      caseId,
      type,
    }: {
      donorId: string;
      caseId: string;
      type: SelectionOptions;
    }) => casesAPI.updateSelectedDonor(caseId, donorId, type),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              options: oldData.data.options.map(
                (e: IDonorPatientInformation) => {
                  if (e.uuid === context.donorId) {
                    return {
                      ...e,
                      isSelected: context.type === SelectionOptions.SELECTED,
                    };
                  }
                  return e;
                }
              ),
            },
          })
        );

        enqueueSnackbar(
          res.message ?? context.type === SelectionOptions.SELECTED
            ? toastMessageConstant.CASES.DONOR_SELECTED
            : toastMessageConstant.CASES.DONOR_REMOVED,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteBloodDonorMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({ donorId, caseId }: { donorId: string; caseId: string }) =>
      casesAPI.deleteBloodDonor(caseId, donorId),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              options: oldData.data.options.filter(
                (e: IDonorPatientInformation) => e.uuid !== context.donorId
              ),
            },
          })
        );

        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.DONOR_DELETED,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddOrEditReferringPhysicianMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: IReferringPhysicianFormSchema;
      type:
        | ModalKey.ADD_REFERRING_PHYSICIAN
        | ModalKey.EDIT_REFERRING_PHYSICIAN
        | ModalKey.CHANGE_REFERRING_PHYSICIAN
        | 'remove';
    }) => casesAPI.addOrEditReferringPhysician(caseId, data),
    {
      onSuccess: (res, context) => {
        const successMessageMap = {
          [ModalKey.ADD_REFERRING_PHYSICIAN]:
            toastMessageConstant.CASES.ADD_REFERRING_PHYSICIAN,
          [ModalKey.EDIT_REFERRING_PHYSICIAN]:
            toastMessageConstant.CASES.EDIT_REFERRING_PHYSICIAN,
          [ModalKey.CHANGE_REFERRING_PHYSICIAN]:
            toastMessageConstant.CASES.CHANGE_REFERRING_PHYSICIAN,
          remove: toastMessageConstant.CASES.REMOVE_REFERRING_PHYSICIAN,
        };

        enqueueSnackbar(successMessageMap[context.type], {
          variant: 'success',
        });

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              referral:
                context.type === 'remove'
                  ? {}
                  : {
                      ...res.data,
                    },
            },
          })
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCaseGroupSelectQuery = (
  clientId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.groupSelect(clientId),
    () => casesAPI.getCaseGroupSelect(clientId),
    {
      enabled,
      select: adaptCaseGroupSelect,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useShareFileHistoryQuery = (
  caseId: string,
  fileId: string,
  filter: IFetchHistoryFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.fileHistory({ caseId, fileId }),
    () => casesAPI.getCaseHistory(caseId, fileId, filter),
    {
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useDeleteReferringProviderMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({ providerId, caseId }: { providerId: string; caseId: string }) =>
      casesAPI.deleteReferringProvider(caseId, providerId),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              providerOptions: oldData.data.providerOptions.filter(
                (e: IReferralCaseProviderOption) => e.id !== context.providerId
              ),
            },
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.REFERRING_PROVIDER_DELETED,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSelectedProviderMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({
      providerId,
      caseId,
      params,
    }: {
      providerId: string;
      caseId: string;
      params: { isSelected: boolean };
      type: SelectionOptions;
    }) => casesAPI.updateSelectedProvider(caseId, providerId, params),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              providerOptions: oldData.data.providerOptions.map((e: any) => {
                if (e.id === res.data?.id) {
                  return res.data;
                }
                return e;
              }),
            },
          })
        );
        enqueueSnackbar(
          res.message ?? context.type === SelectionOptions.SELECTED
            ? toastMessageConstant.CASES.PROVIDER_SELECTED
            : toastMessageConstant.CASES.PROVIDER_REMOVED,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddReferringProviderMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data:
        | IReferralCaseProviderOptionPayload
        | IReferralCaseProviderOptionPayload[];
      override?: boolean;
    }) => casesAPI.addReferringProvider(caseId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              ...(context.override
                ? {
                    providerOptions: res.data,
                  }
                : {
                    providerOptions: [
                      ...(Array.isArray(res.data)
                        ? (res.data as IReferralCaseProviderOption[])
                        : [res.data]),
                      ...oldData.data.providerOptions,
                    ],
                  }),
            },
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.PROVIDER_CREATED,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useUpdateReferringProviderMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({
      caseId,
      providerId,
      data,
    }: {
      caseId: string;
      providerId: string;
      data: IReferralCaseProviderOptionPayload;
    }) => casesAPI.updateReferringProvider(caseId, providerId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              providerOptions: oldData.data.providerOptions.map(
                (e: IReferralCaseProviderOption) => {
                  if (e.id === context.providerId) {
                    return res.data;
                  }
                  return e;
                }
              ),
            },
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.PROVIDER_UPDATED,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateStateAverageMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  const queryClient = useQueryClient();
  return useMutation(
    ({ caseId, data }: { caseId: string; data: IStateAverage }) =>
      casesAPI.updateStateAverage(caseId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              stateAverage: res.data.stateAverage,
            },
          })
        );

        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.UPDATE_STATE_AVERAGE,
          {
            variant: 'success',
          }
        );
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useUpdateCaseGeneralInformation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: ICaseGeneralInformationPayload;
    }) => casesAPI.updateGeneralCaseInformation(caseId, data),
    {
      onSuccess() {
        enqueueSnackbar('Updated Succesfully', { variant: 'success' });
      },
      onError(err: IError) {
        enqueueSnackbar(err.message, { variant: 'error' });
      },
    }
  );
};

export const useUpdateCaseConfigsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: INameType }) => casesAPI.updateCaseConfigs(data),
    {
      onSuccess() {
        // enqueueSnackbar('Updated Sucessfully ', { variant: 'success' });
      },
      onError(err: IError) {
        enqueueSnackbar(err.message, { variant: 'error' });
      },
    }
  );
};

export const useUpdateCaseInfosMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      caseId,
      data,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      type,
    }: {
      caseId: string;
      data: { [key: string]: any };
      type: string;
    }) => casesAPI.updateCaseInfos(caseId, data),
    {
      onSuccess: (res, context) => {
        const rootState: RootState = store.getState();
        const caseStore = rootState.case;

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              caseType: res.data.caseType,
            },
          })
        );
        // queryClient.invalidateQueries(
        //   referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!)
        // );
        enqueueSnackbar(
          res.message
            ? res.message
            : toastMessageConstant.CASES.UPDATE_CASE_INFO.replace(
                '{{CASE_INFO}}',
                context.type
              ),
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCaseConfigurationQuery = (
  queryParams: IFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.configs(),
    () => casesAPI.getCaseConfigs(queryParams),
    {
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
export const useCaseNotesQuery = (
  caseId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.notes(caseId),
    () => casesAPI.getCaseNotes(caseId),
    {
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useSetAppointmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      caseId,
      providerId,
      data,
    }: {
      caseId: string;
      providerId: string;
      data: { date: string };
    }) => casesAPI.setAppointment({ caseId, providerId, data }),
    {
      onSuccess(res, context) {
        const rootState: RootState = store.getState();
        const caseStore = rootState.case;

        const updatedProvider = res.data.find(
          (p) => p.id === context.providerId
        );

        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              providerOptions: oldData.data.providerOptions.map(
                (e: IReferralCaseProviderOption) => {
                  if (e.id === updatedProvider?.id) {
                    return {
                      ...e,
                      appointment: updatedProvider.appointment,
                    };
                  }
                  return e;
                }
              ),
            },
          })
        );

        enqueueSnackbar('Appointment set succesfully', { variant: 'success' });
      },
      onError(err: IError) {
        enqueueSnackbar(err.message, { variant: 'error' });
      },
    }
  );
};

export const useLatestPatientInfoQuery = (
  patientId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.latestPatientInfo(patientId),
    () => casesAPI.getLatestPatientInfo(patientId),
    {
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTaggableUsersQuery = (
  caseId: string,
  filters: { keyword?: string; for: 'admin' | 'client' },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.taggableUsers(caseId, filters),
    () => casesAPI.getTaggableUsers(caseId, filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUnshareCaseMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const caseStore = rootState.case;

  return useMutation(
    ({
      caseId,
      data,
    }: {
      caseId: string;
      data: { referenceType: string; referenceId: string; email: string };
    }) => casesAPI.unshareCase(caseId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          referralCasesKeys.detail(caseStore.selectedReferralCase!.caseId!),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              access: res.data.access,
              associates: res.data.associates,
            },
          })
        );
        enqueueSnackbar(
          res.message ? res.message : toastMessageConstant.CASES.UNSHARED,
          {
            variant: 'success',
          }
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAssociatedPatientsQuery = (
  filters: { keyword: string; groupId: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.associatedPatients(filters),
    () => casesAPI.fetchCaseAssociatedPatients(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAssociatedGroupsQuery = (
  filters: { keyword: string; patientId: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.associatedGroups(filters),
    () => casesAPI.fetchCaseAssociatedGroups(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAssociatedServicesQuery = (
  filters: { keyword: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.associatedServices(filters),
    () => casesAPI.fetchCaseAssociatedServices(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAssociatedAssigneesQuery = (
  filters: { keyword: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.associatedAssignees(filters),
    () => casesAPI.fetchCaseAssociatedAssignees(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAssociatedRequesteesQuery = (
  filters: { keyword: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    referralCasesKeys.associatedRequestees(filters),
    () => casesAPI.fetchCaseAssociatedRequestees(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useSetFileVisibilityMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      documentId,
      caseId,
      data,
    }: {
      documentId: string;
      caseId: string;
      data: { associationId: string; visibility: DocumentVisibility };
    }) => casesAPI.setFileVisibility({ documentId, caseId, data }),
    {
      onSuccess(res, context) {
        const queryKey = documentCasesKeys.list({
          associationId: context.caseId,
        });

        queryClient.invalidateQueries(queryKey);

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError(err: IError) {
        enqueueSnackbar(err.message, { variant: 'error' });
      },
    }
  );
};

export const useCaseConfigurationMutation = ({
  showToasterMessageFromHover = true,
}: {
  showToasterMessageFromHover?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ tenantId, data }: { tenantId: string; data: any }) =>
      casesAPI.updateCaseConfiguratonSettings(tenantId, data),
    {
      onSuccess(res) {
        if (showToasterMessageFromHover) {
          enqueueSnackbar(res.message, {
            variant: 'success',
          });
        }
      },
      onError(err: IError) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
